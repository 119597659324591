import React from 'react';
import { TenantListComponent } from 'src/components/tenant/tenant-list-component/tenant-list.component';
import { TenantBreadcrumpsComponent } from 'src/components/tenant/breadcrumps/tenant-breadcrumps-component';
import { useTranslation } from 'react-i18next';

const AppTenantsScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      <TenantBreadcrumpsComponent />
      <TenantListComponent />
    </div>
  );
};

export { AppTenantsScreen };
