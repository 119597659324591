import React, { ReactNode, useEffect, useState } from 'react';
import { useFlags } from 'src/shared/hooks/use-flags';

interface ComponentProps {
  /** The unique flag key */
  flagKey: string;

  children?: ReactNode;
}

// Protect either its children or a whole route
const FeatureFlagComponent = ({ flagKey, children }: ComponentProps) => {
  const { flagsStorage, flagEnabled } = useFlags();

  const [enabled, setEnabled] = useState(false);

  // Run evaluation only when we have flagStorage resolved
  useEffect(() => {
    setEnabled(flagEnabled(flagKey));
  }, [flagsStorage]);

  // Only if enabled should we render the component children
  if (enabled) return <>{children}</>;
  else return null;
};

export { FeatureFlagComponent };
