import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const ActiveChipComponent: FunctionComponent<{ active?: boolean }> = ({
  active,
}) => {
  const { t } = useTranslation();
  return (
    <ChipComponent type={active ? 'success' : 'tertiary'}>
      {active ? t('Active') : t('Disabled')}
    </ChipComponent>
  );
};

export { ActiveChipComponent };
