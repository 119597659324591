import React, { useEffect, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { SegmentOutput, Target } from 'src/gql/graphql';
import { SegmentTargetComponent } from './segment-target.component';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { DividerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/DividerComponent';
import { useTranslation } from 'react-i18next';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';

export interface ComponentProps {
  didClickSave: (args: SaveArgs) => void;
  didClickCancel: () => void;
  editSegment?: SegmentOutput;
  // Is the segment for Custom properties managed via API?
  custom: boolean;
}

export interface SaveArgs {
  key: string;
  description: string;
  targets?: Target[];
}

const SegmentModalComponent = ({
  didClickSave,
  didClickCancel,
  editSegment,
  custom,
}: ComponentProps) => {
  const [key, setKey] = useState('');
  const [description, setDescription] = useState('');
  const [targets, setTargets] = useState<Target[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (editSegment) {
      setKey(editSegment.key);
      setDescription(editSegment.description);

      if (!custom) {
        const nextTargets =
          editSegment.targets && editSegment.targets.length > 0
            ? editSegment.targets
            : [{}];
        setTargets(nextTargets);
      }
    } else {
      if (!custom) {
        // render first target component
        setTargets([{}]);
      }
    }
  }, []);

  const onDidClickSave = () => {
    didClickSave({
      key: key,
      description,
      targets: custom ? undefined : targets,
    });
  };

  const cleanIdInput = (input: string) => {
    return input ? input.toLowerCase().replace(' ', '-') : '';
  };

  const formValid =
    key && !targets.some((t) => JSON.stringify(t) === JSON.stringify({}));

  const addAnotherTarget = () => {
    setTargets([...targets, {}]);
  };

  const removeTarget = (i: number) => {
    const nextTargets = targets.filter((oldTarget, j) => j != i);
    setTargets(nextTargets);
  };

  const didChangeTarget = (target: Target, i: number) => {
    const nextTargets = targets.map((oldTarget, j) => {
      if (j === i) {
        return target;
      } else {
        return oldTarget;
      }
    });
    setTargets(nextTargets);
  };

  return (
    <div className="modal-body">
      <div>
        <form
          onSubmit={() => onDidClickSave()}
          className="space-y-6 max-w-sm w-full"
        >
          <InputComponent
            type="text"
            label={t('Group key*')}
            placeholder={t('E.g. beta-testers')}
            name="id"
            onChange={(event) => setKey(cleanIdInput(event.target.value))}
            value={key}
          />

          <InputComponent
            type="text"
            label={t('Description (optional)')}
            placeholder={t('E.g. Workspaces in the beta testing program')}
            name="description"
            onChange={(event) => setDescription(event.target.value)}
            value={description}
          />

          <div>
            <DividerComponent text={t('Match on target')} />
            {custom && (
              <TextComponent size="sm">
                {t(
                  'These targets contains custom properties and can only be managed via API',
                )}{' '}
                <LinkComponent
                  target="_blank"
                  to="https://nebulr-group.github.io/nblocks-api-docs/#create-a-segment"
                  type="primary"
                  nativeBehavior
                >
                  {t('here')}
                </LinkComponent>
              </TextComponent>
            )}
            {!custom &&
              targets.map((target, i) => (
                <div key={`target-${i}`} className="space-y-6">
                  {i !== 0 && (
                    <div>
                      <div className="flex justify-end">
                        <NblocksButton onClick={() => removeTarget(i)}>
                          <XMarkIcon className="w-6 h-6" />
                        </NblocksButton>
                      </div>
                      <DividerComponent
                        text={`${t('AND on Target #')}${i + 1}`}
                      />
                    </div>
                  )}
                  <SegmentTargetComponent
                    target={target}
                    key={`target-${i}`}
                    onChange={(target) => didChangeTarget(target, i)}
                  />
                </div>
              ))}
          </div>
          <NblocksButton
            size="md"
            className="w-full"
            type="tertiary"
            disabled={custom}
            onClick={() => {
              addAnotherTarget();
            }}
          >
            {t('Add another target')}
          </NblocksButton>
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {'Cancel'}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSave()}
          disabled={!formValid}
        >
          {t('Save changes')}
        </NblocksButton>
      </div>
    </div>
  );
};

export { SegmentModalComponent };
