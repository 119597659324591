import { useCurrentApp } from './use-current-app';
import { ListFederationConnectionsDocument } from 'src/gql/graphql';
import { useQuery } from '@apollo/client';

const useListCurrentFederationConnections = () => {
  const { appId } = useCurrentApp();

  const { data, loading, error } = useQuery(ListFederationConnectionsDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });

  return { data, loading, error };
};

export { useListCurrentFederationConnections };
