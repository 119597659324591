import React, { FunctionComponent, useState } from 'react';
import { useListUsers } from 'src/shared/hooks/use-list-users';
import {
  SafeUserName,
  SafeUserNameComponent,
} from '../safe-user-name/safe-user-name.component';
import {
  CreateUserDocument,
  DeleteUserDocument,
  ListUsersDocument,
  UpdateUserDocument,
  User,
} from 'src/gql/graphql';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import { DateTimeComponent } from 'src/components/shared/date/date-time-component';
import { TableComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TableComponent';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useListCurrentRoles } from 'src/shared/hooks/use-list-roles';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { SaveArgs, UserModalComponent } from './user.modal.component';
import { useMutation } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { useCurrentTenant } from 'src/shared/hooks/use-current-tenant';
import { ActiveChipComponent } from 'src/components/shared/active-chip/active-chip.component';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { ResetUserPasswordModalComponent } from './reset-password.modal.component';
import { DangerVerifyModalComponent } from 'src/components/shared/danger-verify-modal/danger-verify-modal.component';
import { CopyChipComponent } from 'src/components/access-role/copy.button.component';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const UserListComponent = () => {
  const { data, loading } = useListUsers();
  const { t } = useTranslation();
  const { setSuccessMessage } = useFeedback();
  const { appId } = useCurrentApp();
  const { tenantId } = useCurrentTenant();
  const { data: rolesData, loading: rolesLoading } = useListCurrentRoles();
  const [editUser, setEditUser] = useState<User>();
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [createUserMutation, createUserData] = useMutation(CreateUserDocument);
  const [updateUserMutation, updateUserData] = useMutation(UpdateUserDocument);
  const [deleteUserMutation, deleteUserData] = useMutation(DeleteUserDocument);

  const didClickUserModalSubmit = async (user: SaveArgs) => {
    if (editUser) {
      await updateUserMutation({
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          appId: appId!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          tenantId: tenantId!,
          userId: editUser.id,
          user: {
            enabled: user.enabled,
            role: user.role,
          },
        },
      });
      setSuccessMessage(t('The user was updated!'));
    } else {
      await createUserMutation({
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          appId: appId!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          tenantId: tenantId!,
          user: {
            username: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            muteNotifications: user.muteNotifications,
          },
        },
        refetchQueries: [ListUsersDocument],
      });
      setSuccessMessage(t('A new user was created!'));
    }
    setUserModalOpen(false);
  };

  const isLoading = loading;

  const didClickCreateBtn = () => {
    setEditUser(undefined);
    setUserModalOpen(true);
  };

  const onDidClickDelete = async () => {
    if (editUser) {
      await deleteUserMutation({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { appId: appId!, tenantId: tenantId!, userId: editUser.id },
        refetchQueries: [ListUsersDocument],
      });
      setdeleteModalOpen(false);
      setSuccessMessage(t('The user was deleted!'));
      setEditUser(undefined);
    }
  };

  const onDidClickEdit = (row: User) => {
    setEditUser(row);
    setUserModalOpen(true);
  };

  const onDidClickResetPassword = (row: User) => {
    setEditUser(row);
    setResetPasswordModalOpen(true);
  };

  const EditComponent: FunctionComponent<{ row: User }> = ({ row }) => {
    const options: Option[] = [
      {
        label: 'Edit',
        onClick: () => {
          onDidClickEdit(row);
        },
        labelPosition: 'start',
      },
      {
        label: 'Reset password',
        onClick: () => {
          onDidClickResetPassword(row);
        },
        labelPosition: 'start',
      },
      {
        label: 'Delete',
        type: 'danger',
        onClick: () => {
          setEditUser(row);
          setdeleteModalOpen(true);
        },
        labelPosition: 'start',
      },
    ];

    return (
      <div className="flex items-center justify-end">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  const renderRoleName = (roleKey?: string) => {
    const roleMatch = rolesData?.listRoles.find(
      (roles) => roles.key === roleKey,
    );
    return roleMatch ? roleMatch.name : roleKey;
  };

  const cols: ColumnDef<User>[] = [
    {
      header: t('Name'),
      cell: ({ cell, row }) => (
        // <LinkComponent to={`users/${row.original.id}`} type={'primary'}>
        <SafeUserNameComponent name={cell.renderValue() as string} />
        // </LinkComponent>
      ),
      accessorKey: 'fullName',
      size: 200,
    },
    {
      header: t('Email'),
      cell: (cell) => (
        <CopyChipComponent value={cell.renderValue() as string} />
      ),
      accessorKey: 'email',
      size: 200,
    },
    {
      header: t('Role'),
      cell: (cell) => (
        <TextComponent>
          {renderRoleName(cell.renderValue() as string)}
        </TextComponent>
      ),
      accessorKey: 'role',
      size: 100,
    },
    {
      header: t('Status'),
      cell: ({ row }) => (
        <ActiveChipComponent active={!!row.original.enabled} />
      ),
      size: 50,
    },
    {
      header: t('Last activity'),
      cell: (cell) => (
        <DateTimeComponent value={cell.renderValue() as string} />
      ),
      accessorKey: 'lastSeen',
      size: 200,
    },
    {
      header: t('Created'),
      cell: (cell) => (
        <DateTimeComponent value={cell.renderValue() as string} />
      ),
      accessorKey: 'createdAt',
      size: 200,
    },
    {
      id: 'edit',
      header: '',
      cell: ({ row }) => {
        return <EditComponent row={row.original} />;
      },
      size: 100,
    },
  ];

  const users = data?.listUsers;
  const editUserIsOnlyOwner =
    editUser &&
    editUser.role === 'OWNER' &&
    users?.filter((u) => u.id != editUser.id && u.role === 'OWNER').length ===
      0;

  return (
    <div className="space-y-6">
      <div className="relative">
        <HeadingComponent type={'h2'} size={'xl'}>
          {t('Users')}
        </HeadingComponent>
        <div className="absolute top-0 right-0">
          <NblocksButton
            onClick={() => didClickCreateBtn()}
            type="primary"
            size="md"
          >
            {t('Create user')}
          </NblocksButton>
        </div>
      </div>
      <TableComponent
        columns={cols}
        data={data?.listUsers}
        loading={isLoading}
        defaultPageSize={10}
      />
      <ModalComponent
        isOpen={userModalOpen}
        setIsOpen={setUserModalOpen}
        heading={editUser ? t('Edit user') : t('Create new user')}
      >
        <UserModalComponent
          didClickSubmit={(user) => didClickUserModalSubmit(user)}
          didClickCancel={() => setUserModalOpen(false)}
          editUser={editUser}
          singleOwner={editUserIsOnlyOwner}
        />
      </ModalComponent>
      <DangerVerifyModalComponent
        title={t('Are you sure?')}
        message={t('Do you want to delete {{fullName}}?', {
          fullName: SafeUserName(editUser?.fullName),
        })}
        submitBtnText={t('Delete')}
        cancelBtnText={t('Cancel')}
        isOpen={deleteModalOpen}
        setIsOpen={setdeleteModalOpen}
        onSubmit={() => onDidClickDelete()}
        loading={deleteUserData.loading}
      />
      {editUser && tenantId && appId && (
        <ModalComponent
          isOpen={resetPasswordModalOpen}
          setIsOpen={setResetPasswordModalOpen}
          icon={<ExclamationTriangleIcon />}
          iconType="warning"
          heading={t('Confirm user password reset')}
        >
          <ResetUserPasswordModalComponent
            user={editUser}
            tenantId={tenantId}
            appId={appId}
            setResetPasswordModalOpen={setResetPasswordModalOpen}
          />
        </ModalComponent>
      )}
    </div>
  );
};

export { UserListComponent };
