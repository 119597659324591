import React, { FunctionComponent } from 'react';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { Config } from 'src/shared/Config';
import { CopyChipComponent } from '../access-role/copy.button.component';
import { PlanGraphql } from '@nebulr-group/nblocks-react/lib/gql/graphql';
import { useListCurrentPlans } from 'src/shared/hooks/use-list-plans';
import { GetAppAuthProviderStateDocument } from 'src/gql/graphql';
import { useQuery } from '@apollo/client';
import { TenantSelfSignupToggleCard } from './toggle-cards/tenant-self-signup-card.component';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';

const GenericSignupLinkComponent = () => {
  const { appId } = useCurrentApp();
  const { t } = useTranslation();
  const link = `${Config.getOauthApiUrl()}/url/signup/${appId}`;

  return (
    <CopyChipComponent label={t('Signup link without plan')} value={link} />
  );
};

const PlanSignupLinkComponent: FunctionComponent<{
  plan: PlanGraphql;
}> = ({ plan }) => {
  const { appId } = useCurrentApp();
  const { t } = useTranslation();
  const link = `${Config.getOauthApiUrl()}/url/signup/${appId}?signupPlan=${
    plan.key
  }`;

  return (
    <CopyChipComponent
      label={`${t('Signup link for ')} ${plan.name} ${t('plan')}`}
      value={link}
    />
  );
};

const SignupSettingsComponent = () => {
  const { t } = useTranslation();
  const { data, loading } = useListCurrentPlans();
  const { appId } = useCurrentApp();

  const { data: appAuthState, loading: appAuthStateLoading } = useQuery(
    GetAppAuthProviderStateDocument,
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { id: appId! },
      skip: !appId,
    },
  );

  return (
    <div>
      <div>
        <div className="my-6 flex justify-between">
          <div className="flex flex-col">
            <SubHeadingComponent type={'primary'} size={'2xl'}>
              {t('Signup settings')}
            </SubHeadingComponent>

            <TextComponent size={'base'} colorName="text-gray-500">
              {t('Manage how new users can signup')}
            </TextComponent>
          </div>
        </div>
        <div className="space-y-6">
          <div className="rounded-lg border border-gray-300 bg-white shadow p-6 grid grid-cols-2 gap-6">
            {appAuthStateLoading && (
              <>
                <SkeletonLoader className="h-24 w-full rounded-md" />
              </>
            )}
            {appAuthState && (
              <>
                <TenantSelfSignupToggleCard data={appAuthState} />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="my-6 flex justify-between">
        <div className="flex flex-col">
          <SubHeadingComponent type={'primary'} size={'2xl'}>
            {t('Signup links')}
          </SubHeadingComponent>

          <TextComponent size={'base'} colorName="text-gray-500">
            {t('Copy links to allow new users to signup')}
          </TextComponent>
        </div>
      </div>
      <div className="space-y-6">
        <div className="rounded-lg border border-gray-300 bg-white shadow p-6 grid grid-cols-1 gap-6">
          <GenericSignupLinkComponent />
          {loading && (
            <div className="flex">
              <div className="w-64 space-y-6">
                <SkeletonLoader className="h-8 w-full rounded-md" />
                <SkeletonLoader className="h-8 w-full rounded-md" />
                <SkeletonLoader className="h-8 w-full rounded-md" />
              </div>
            </div>
          )}
          {data?.getAppPlans.map((plan) => (
            <PlanSignupLinkComponent key={`signup-${plan.id}`} plan={plan} />
          ))}
        </div>
      </div>
    </div>
  );
};

export {
  SignupSettingsComponent,
  GenericSignupLinkComponent,
  PlanSignupLinkComponent,
};
