import React from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Config } from 'src/shared/Config';

const ViewLinksComponent = ({ appId }: { appId: string }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center w-full mt-4">
      <TextComponent size={'base'} colorName="text-gray-500 mr-4">
        {t('View: ')}
      </TextComponent>
      <div className="flex items-center justify-left">
        <a
          href={`${Config.getOauthApiUrl()}/url/login/${appId}`}
          target="_blank"
          className="hover:cursor-pointer w-fit mr-4"
        >
          <ChipComponent
            type={'primary'}
            icon={
              <div className={'text-purple-700 h-4 w-4 m-1'} aria-hidden="true">
                <ArrowTopRightOnSquareIcon />
              </div>
            }
          >
            {t('Login page')}
          </ChipComponent>
        </a>
        <a
          href={`${Config.getOauthApiUrl()}/url/signup/${appId}`}
          target="_blank"
          className="hover:cursor-pointer w-fit mr-4"
        >
          <ChipComponent
            type={'primary'}
            icon={
              <div className={'text-purple-700 h-4 w-4 m-1'} aria-hidden="true">
                <ArrowTopRightOnSquareIcon />
              </div>
            }
          >
            {t('Signup page')}
          </ChipComponent>
        </a>
      </div>
    </div>
  );
};

export { ViewLinksComponent };
