import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '@heroicons/react/24/solid';

import {
  GetAppSecurityConfigsDocument,
  UpdateAppSecurityConfigsDocument,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const SecurityComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();
  const { setSuccessMessage } = useFeedback();
  const [defaultUri, setDefaultUri] = useState<string>('');
  const [existingUri, setExistingUri] = useState<string[]>([]);
  const [addUri, setAddUri] = useState('');
  const [addUriValid, setAddUriValid] = useState(false);
  const urlRegex = new RegExp(/^(http:\/\/|https:\/\/).+$/);

  const [tokensTTL, setTokensTTL] = useState<{
    accessTokenTTL: {
      value: number | undefined;
      initValue: number | undefined;
    };
    refreshTokenTTL: {
      value: number | undefined;
      initValue: number | undefined;
    };
  }>({
    accessTokenTTL: { value: undefined, initValue: undefined },
    refreshTokenTTL: { value: undefined, initValue: undefined },
  });

  const { data, loading } = useQuery(GetAppSecurityConfigsDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });

  const [updateAppSecurityConfigs] = useMutation(
    UpdateAppSecurityConfigsDocument,
  );

  useEffect(() => {
    if (addUri.match(urlRegex) && !existingUri.includes(addUri)) {
      setAddUriValid(true);
    } else {
      setAddUriValid(false);
    }
  }, [addUri]);

  useEffect(() => {
    if (data) {
      const {
        redirectUris,
        defaultCallbackUri,
        refreshTokenTTL: refreshTokenTTLData,
        accessTokenTTL: accessTokenTTLData,
      } = data.getApp;
      if (redirectUris) setExistingUri(redirectUris);
      if (defaultCallbackUri) setDefaultUri(defaultCallbackUri);
      if (accessTokenTTLData && refreshTokenTTLData)
        setTokensTTL({
          accessTokenTTL: {
            value: accessTokenTTLData,
            initValue: accessTokenTTLData,
          },
          refreshTokenTTL: {
            value: refreshTokenTTLData,
            initValue: refreshTokenTTLData,
          },
        });
    }
  }, [data]);

  const handleDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setDefaultUri(event.target.value);
    updateAppSecurityConfigs({
      variables: {
        id: appId!,
        app: {
          defaultCallbackUri: event.target.value,
        },
      },
    });
  };

  const onAddUri = () => {
    const updatedUris = [...existingUri, addUri];
    updateAppSecurityConfigs({
      variables: {
        id: appId!,
        app: {
          redirectUris: updatedUris,
        },
      },
    });
    setAddUri('');
    setExistingUri(updatedUris);
  };

  const onSaveTokenTTL = async (
    token: 'refreshTokenTTL' | 'accessTokenTTL',
    ttl: number | undefined,
  ) => {
    if (ttl) {
      await updateAppSecurityConfigs({
        variables: {
          id: appId!,
          app: {
            [token]: ttl,
          },
        },
      });
      setTokensTTL({ ...tokensTTL, [token]: { value: ttl, initValue: ttl } });
      setSuccessMessage(t('Token lifetime updated!'));
    }
  };

  const onDeleteUri = (uri: string) => {
    if (uri === defaultUri) return;
    const cleanedUris = existingUri.filter((item) => item !== uri);
    updateAppSecurityConfigs({
      variables: {
        id: appId!,
        app: {
          redirectUris: [...cleanedUris],
        },
      },
    });
    setExistingUri(cleanedUris);
  };

  return (
    <>
      <div>
        <div className="my-6 flex justify-between">
          <div className="flex flex-col">
            <SubHeadingComponent type={'primary'} size={'2xl'}>
              {t('Callback')}
            </SubHeadingComponent>

            <TextComponent size={'base'} colorName="text-gray-500">
              {t('Manage your callback URLs')}
            </TextComponent>
          </div>
        </div>
        <div className="space-y-6">
          <div className="rounded-lg border border-gray-300 bg-white shadow p-6">
            <div className="flex flex-col w-2/5">
              {loading && <SkeletonLoader className="h-32 w-full rounded-md" />}
              {existingUri.map((uri, index) => (
                <div className="mb-5" key={`callback-uri-${index}`}>
                  <div className="flex items-end mb-2">
                    <div className="w-4/5">
                      <InputComponent
                        label={index === 0 ? t('Callback URLs') : ''}
                        type={'text'}
                        value={uri}
                        disabled
                      />
                    </div>
                    <div className="w-1/5 text-left">
                      <TrashIcon
                        className={`h-6 w-6 mb-4 ml-4 ${
                          defaultUri === uri
                            ? 'text-gray-200 hover:cursor-not-allowed'
                            : 'text-gray-500 hover:cursor-pointer'
                        }`}
                        onClick={() => onDeleteUri(uri)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="default-radio-1"
                      checked={defaultUri === uri}
                      type="radio"
                      value={uri}
                      onChange={(event) => handleDefaultChange(event)}
                      name="default-radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor="default-radio-1"
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      Make this callback URL default
                    </label>
                  </div>
                </div>
              ))}
              <div className="flex items-end my-2">
                <div className="w-4/5">
                  <InputComponent
                    label={t('Add callback URL')}
                    type={'text'}
                    value={addUri}
                    onChange={(event) => setAddUri(event.target.value)}
                  />
                </div>
                <div className="w-1/5 text-left">
                  <NblocksButton
                    size="sm"
                    className="h-12 ml-4"
                    type="primary"
                    disabled={!addUriValid}
                    onClick={() => onAddUri()}
                  >
                    {t('Add')}
                  </NblocksButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="my-6 flex justify-between">
          <div className="flex flex-col">
            <SubHeadingComponent type={'primary'} size={'2xl'}>
              {t('Sessions')}
            </SubHeadingComponent>

            <TextComponent size={'base'} colorName="text-gray-500">
              {t('Manage your session settings')}
            </TextComponent>
          </div>
        </div>
        <div className="space-y-6">
          <div className="rounded-lg border border-gray-300 bg-white shadow p-6">
            {loading && (
              <div className="flex flex-col w-2/5">
                <div className="flex items-end my-2">
                  <div className="w-4/5 space-y-6">
                    <SkeletonLoader className="h-16 w-full rounded-md" />
                    <SkeletonLoader className="h-16 w-full rounded-md" />
                  </div>
                </div>
              </div>
            )}
            {!loading && (
              <div className="flex flex-col w-2/5">
                <div className="flex items-end my-2">
                  <div className="w-4/5">
                    <InputComponent
                      label={t('Access token lifetime (seconds)')}
                      type={'number'}
                      value={tokensTTL.accessTokenTTL.value}
                      onChange={(event) =>
                        setTokensTTL({
                          ...tokensTTL,
                          accessTokenTTL: {
                            ...tokensTTL.accessTokenTTL,
                            value: parseInt(event.target.value),
                          },
                        })
                      }
                    />
                  </div>
                  {tokensTTL.accessTokenTTL.value !==
                    tokensTTL.accessTokenTTL.initValue && (
                    <div className="w-1/5 text-left">
                      <NblocksButton
                        size="sm"
                        className="h-12 ml-4"
                        type="primary"
                        onClick={() =>
                          onSaveTokenTTL(
                            'accessTokenTTL',
                            tokensTTL.accessTokenTTL.value,
                          )
                        }
                      >
                        {t('Save')}
                      </NblocksButton>
                    </div>
                  )}
                </div>
                <div className="flex items-end my-2">
                  <div className="w-4/5">
                    <InputComponent
                      label={t('Refresh token lifetime (seconds)')}
                      type={'number'}
                      value={tokensTTL.refreshTokenTTL.value}
                      onChange={(event) =>
                        setTokensTTL({
                          ...tokensTTL,
                          refreshTokenTTL: {
                            ...tokensTTL.refreshTokenTTL,
                            value: parseInt(event.target.value),
                          },
                        })
                      }
                    />
                  </div>
                  {tokensTTL.refreshTokenTTL.value !==
                    tokensTTL.refreshTokenTTL.initValue && (
                    <div className="w-1/5 text-left">
                      <NblocksButton
                        size="sm"
                        className="h-12 ml-4"
                        type="primary"
                        onClick={() =>
                          onSaveTokenTTL(
                            'refreshTokenTTL',
                            tokensTTL.refreshTokenTTL.value,
                          )
                        }
                      >
                        {t('Save')}
                      </NblocksButton>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { SecurityComponent };
