import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';

const OnboardingTestCompleted: FunctionComponent<{}> = () => {
  const { t } = useTranslation();

  return (
    // <div className="w-full h-screen flex flex-col items-center justify-center">
    <div className="w-full h-full flex flex-col items-center justify-center">
      <CheckCircleIcon className="h-32 w-32 text-green-500" />
      <HeadingComponent type={'h1'} size={'xl'}>
        {t('Great work!!')}
      </HeadingComponent>
      <TextComponent className="mt-6">
        {t('You can always revisit these instructions by clicking')}{' '}
        <button className="h-6 w-6 text-gray-400">
          <QuestionMarkCircleIcon />
        </button>
      </TextComponent>
    </div>
  );
};

export { OnboardingTestCompleted };
