import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  ConfigCardsComponentProps,
  ToggleCardComponent,
} from '../../shared/toggle-card/toggle-card.component';
import { MicrosoftLogo } from '@nebulr-group/nblocks-react/lib/components/auth/shared/logos/microsoft.logo';
import { SocialProviderCardProps } from '../social.component';
import {
  UpdateAppEnabledAuthDocument,
  UpdateCredentialsDocument,
} from 'src/gql/graphql';
import { useMutation } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { CopyChipComponent } from 'src/components/access-role/copy.button.component';
import { Config } from 'src/shared/Config';

const MicrosoftADToggleCard = ({ data }: SocialProviderCardProps) => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const emptyState = {
    microsoftAzureADClientId: '',
    microsoftAzureADClientSecret: '',
    microsoftAzureADTenantId: '',
  };
  const [credentials, setCredentials] = useState<{
    microsoftAzureADClientId: string;
    microsoftAzureADClientSecret: string;
    microsoftAzureADTenantId: string;
  }>(emptyState);

  const [updateCredentialsMutation, updateCredentialsData] = useMutation(
    UpdateCredentialsDocument,
  );

  const { data: credentialsUpdateData, loading: credentialsUpdateLoading } =
    updateCredentialsData;

  const [updateEnabledAuthMutation, updateEnabledAuthData] = useMutation(
    UpdateAppEnabledAuthDocument,
  );

  const { data: enableUpdateData, loading: enableUpdateLoading } =
    updateEnabledAuthData;

  const isLoading = enableUpdateLoading || credentialsUpdateLoading;

  const onEnabledChange = async (enabled: boolean) => {
    await updateEnabledAuthMutation({
      variables: {
        id: appId!,
        app: {
          azureAdSsoEnabled: enabled,
        },
      },
    });
  };

  const onDelete = async () => {
    setCredentials(emptyState);
    await updateCredentialsMutation({
      variables: {
        id: appId!,
        credentials: emptyState,
      },
    });
    await updateEnabledAuthMutation({
      variables: {
        id: appId!,
        app: {
          azureAdSsoEnabled: false,
        },
      },
    });
  };

  const didClickModalSave = async () => {
    await updateCredentialsMutation({
      variables: {
        id: appId!,
        credentials: {
          ...credentials,
        },
      },
    });
    setCredentials(emptyState);
  };

  const handleInputChange = (field: string, value: string) => {
    setCredentials((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const cardProps: ConfigCardsComponentProps = {
    logo: <MicrosoftLogo />,
    label: 'Azure AD SSO',
    description: t('Allow authentication using Microsoft Azure AD'),
    isEnabled: enableUpdateData
      ? enableUpdateData.updateApp.azureAdSsoEnabled
      : data.getApp.azureAdSsoEnabled,
    onEnabledChange,
    isLoading,
    modalProps: {
      enableModalHeading: t('Enable Azure AD SSO'),
      editModalHeading: t('Update Azure AD SSO credentials'),
      modalDescription: (
        <TextComponent>
          {t('You can find your Azure AD SSO credentials')}{' '}
          <LinkComponent
            target="_blank"
            to="https://learn.microsoft.com/en-us/entra/identity-platform/quickstart-register-app#add-credentials"
            type="primary"
            nativeBehavior
          >
            {t('here')}
          </LinkComponent>
        </TextComponent>
      ),
      modalFooter: (
        <div>
          <HeadingComponent type={'h3'} size="lg">
            {t('Redirect URL')}
          </HeadingComponent>
          <TextComponent size="sm" colorName="text-gray-500">
            {t(
              'Add this url in Azure Console › Authentication › Redirect URIs',
            )}
            <CopyChipComponent
              value={`${Config.getOauthApiUrl()}/federated/ms-azure-ad/return`}
            />
          </TextComponent>
        </div>
      ),
      deleteModalHeading: t('Do you want to delete your Azure AD credentials?'),
      deleteModalPrompt: t(
        'Deleting your credentials will disable Azure AD SSO from your app until you reactivate it again with new credentials.',
      ),
      credentialsAdded: credentialsUpdateData
        ? credentialsUpdateData.updateCredentials.azureAdSsoCredentialsAdded
        : data.getAppCredentialsState.azureAdSsoCredentialsAdded,
      didClickModalSave: didClickModalSave,
      onModalChange: handleInputChange,
      onDelete,
      modalInputFields: [
        {
          type: 'text',
          label: t('Client ID'),
          placeholder: '',
          caption: t('Enter the client ID from the Azure Console › Overview'),
          field: 'microsoftAzureADClientId',
          value: credentials.microsoftAzureADClientId,
        },
        {
          type: 'password',
          label: t('Client secret'),
          placeholder: '',
          caption: t(
            'Enter the client secret from the Azure Console › Certificates & secrets › Client secrets',
          ),
          field: 'microsoftAzureADClientSecret',
          value: credentials.microsoftAzureADClientSecret,
        },
        {
          type: 'text',
          label: t('Azure Tenant Id'),
          placeholder: '',
          caption: t('Enter the Azure AD id from the Azure Console › Overview'),
          field: 'microsoftAzureADTenantId',
          value: credentials.microsoftAzureADTenantId,
        },
      ],
      modalValid:
        credentials.microsoftAzureADClientId.length > 0 &&
        credentials.microsoftAzureADClientSecret.length > 0 &&
        credentials.microsoftAzureADTenantId.length > 0,
    },
  };

  return <ToggleCardComponent {...cardProps} />;
};

export { MicrosoftADToggleCard };
