import { useContext } from 'react';
import { FeedbackContext } from 'src/components/feedback/feedback.provider';

const useFeedback = () => {
  const context = useContext(FeedbackContext);
  if (!context) {
    throw new Error('useFeedback must be used within a FeedbackProvider');
  }
  return context;
};

export { useFeedback };
