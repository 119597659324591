import { useEffect, useState } from 'react';
import { decodeJwt } from 'jose';

export interface Profile {
  id: string;
  username: string;
  email: string;
  givenName?: string;
  familyName?: string;
  fullName?: string;
}

interface IDToken {
  sub: string;
  preferred_username: string;
  email: string;
  name: string;
  given_name: string;
  family_name: string;
}

const useMe = () => {
  const [profile, setProfile] = useState<Profile | undefined>();

  useEffect(() => {
    const idToken = window.localStorage.getItem('NB_ID_TOKEN');
    if (idToken) {
      const { sub, preferred_username, name, given_name, family_name, email } =
        decodeJwt(idToken) as unknown as IDToken;
      setProfile({
        id: sub,
        username: preferred_username,
        email: email,
        fullName: name,
        familyName: family_name,
        givenName: given_name,
      });
    }
  }, []);

  return {
    profile,
  };
};

export { useMe };
