import React, { ChangeEvent } from 'react';
import { useSecureContext } from '@nebulr-group/nblocks-react';
import { FileUtil } from 'src/shared/file-util';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';

export interface FileDropAreaComponentProps {
  subHeader?: string;
  acceptFileTypes?: string[];
  didUploadFile: (event: UploadedFileEvent) => void;
}

export interface UploadedFileEvent {
  uploadedKey: string;
  file: File;
}

const FileDropAreaComponent = ({
  subHeader,
  didUploadFile,
  acceptFileTypes,
}: FileDropAreaComponentProps) => {
  const { authHttpClient } = useSecureContext();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      FileUtil.upload(file, authHttpClient.httpClient).then((result) =>
        didUploadFile({ uploadedKey: result.key, file }),
      );
    }
  };

  return (
    <div className="flex items-center justify-center w-full">
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <CloudArrowUpIcon className="h-12 w-12" />
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            {subHeader}
          </p>
        </div>
        <input
          id="dropzone-file"
          type="file"
          accept={acceptFileTypes ? acceptFileTypes.join(',') : '*'}
          className="hidden"
          onChange={handleFileChange}
        />
      </label>
    </div>
  );
};

export { FileDropAreaComponent };
