import React, { useState } from 'react';
import { useListTenants } from 'src/shared/hooks/use-list-tenants';
import { SafeTenantNameComponent } from '../safe-tenant-name/safe-tenant-name.component';
import {
  CreateTenantDocument,
  ListTenantsDocument,
  Tenant,
} from 'src/gql/graphql';
import { useTranslation } from 'react-i18next';
import { DateTimeComponent } from '../../shared/date/date-time-component';
import type { ColumnDef } from '@tanstack/react-table';
import { TableComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TableComponent';
import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import { SafePlanNameComponent } from '../safe-plan-name/safe-plan-name.component';
import { useListCurrentPlans } from 'src/shared/hooks/use-list-plans';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { useMutation } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { ImportTenantModalComponent } from '../import/import-tenant.modal.component';
import { TenantModalComponent, SaveArgs } from './tenant.modal.component';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { SearchComponent } from 'src/components/shared/search/search.component';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const TenantListComponent = () => {
  const { data, loading } = useListTenants();
  const { t } = useTranslation();
  const { setSuccessMessage } = useFeedback();
  const { appId } = useCurrentApp();
  const { data: appPlans, loading: appPlansLoading } = useListCurrentPlans();
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [importing, setImporting] = useState(false);
  const [createTenantMutation, createTenantData] =
    useMutation(CreateTenantDocument);

  const [searchQuery, setSearchQuery] = useState('');

  const didClickImporting = () => {
    setImporting(true);
    setImportModalOpen(false);
  };

  const didClickModalCreate = async (args: SaveArgs) => {
    await createTenantMutation({
      variables: {
        appId: appId!,
        tenant: { owner: args.owner!, name: args.name, plan: args.plan },
      },
      refetchQueries: [ListTenantsDocument],
    });
    setSuccessMessage(t('A new workspace was created!'));
    setCreateModalOpen(false);
  };

  const isLoading = loading || appPlansLoading;

  const onDidClickDelete = (row: Tenant) => {
    console.log(`Click edit`);
  };

  const onDidClickEdit = (row: Tenant) => {
    console.log(`Click edit`);
  };

  // const EditComponent: FunctionComponent<{ row: Tenant }> = ({ row }) => {
  //   const options: Option[] = [
  //     {
  //       label: 'Edit',
  //       onClick: () => {
  //         onDidClickEdit(row);
  //       },
  //     },
  //   ];

  //   options.push({
  //     label: 'Delete',
  //     type: 'danger',
  //     onClick: () => {
  //       onDidClickDelete(row);
  //     },
  //   });

  //   return (
  //     <div className="flex items-center justify-end">
  //       <HorizontalEllipsisMenu options={options} position={'left'} />
  //     </div>
  //   );
  // };

  const renderPlanName = (planKey?: string) => {
    const planMatch = appPlans?.getAppPlans.find(
      (plan) => plan.key === planKey,
    );
    return planMatch ? planMatch.name : planKey;
  };

  const getChipStatuses = (row: Tenant) => {
    const statuses: {
      chipColor: 'primary' | 'success' | 'tertiary';
      label: string;
    }[] = [];

    if (row.mfa) {
      statuses.push({ chipColor: 'primary', label: t('MFA') });
    }

    if (row.trial) {
      statuses.push({ chipColor: 'tertiary', label: t('Trialing') });
    } else {
      statuses.push({ chipColor: 'success', label: t('Active') });
    }

    return statuses;
  };

  const cols: ColumnDef<Tenant>[] = [
    {
      header: t('Name'),
      cell: ({ cell, row }) => (
        <LinkComponent to={row.original.id} type={'primary'}>
          <SafeTenantNameComponent name={cell.renderValue() as string} />
        </LinkComponent>
      ),
      accessorKey: 'name',
      size: 300,
    },
    {
      header: t('Plan'),
      cell: (cell) => (
        <SafePlanNameComponent
          name={renderPlanName(cell.renderValue() as string)}
        />
      ),
      accessorKey: 'plan',
      size: 100,
    },
    {
      header: t('Status'),
      cell: ({ row }) => (
        <div className="space-x-2">
          {getChipStatuses(row.original).map((status, i) => (
            <ChipComponent
              key={`status-chip-${row.original.id}-${i}`}
              type={status.chipColor}
              className=""
            >
              {status.label}
            </ChipComponent>
          ))}
        </div>
      ),
      size: 100,
    },
    {
      header: t('Created'),
      cell: (cell) => (
        <DateTimeComponent value={cell.renderValue() as string} />
      ),
      accessorKey: 'createdAt',
      size: 200,
    },
    // {
    //   id: 'edit',
    //   header: '',
    //   cell: ({ row }) => {
    //     return <EditComponent row={row.original} />;
    //   },
    //   size: 100,
    // },
  ];

  const EmptyTenantList = () => {
    return (
      <div className="flex flex-col items-center justify-center space-y-4 p-16">
        <HeadingComponent type={'h2'} size={'3xl'}>
          {t('No workspaces yet')}
        </HeadingComponent>
        <TextComponent>{t('Start by integrating your app')}</TextComponent>
      </div>
    );
  };

  const filteredTenants = searchQuery
    ? data?.listTenants?.filter((t) =>
        t.name?.toLowerCase().includes(searchQuery),
      )
    : data?.listTenants;

  return (
    <div className="space-y-6">
      <div className="relative">
        <HeadingComponent type={'h1'} size={'2xl'}>
          {t('Workspaces')}
        </HeadingComponent>
        <div className="absolute top-0 right-0">
          <div className="flex flex-row-reverse space-x-4 space-x-reverse">
            <NblocksButton
              onClick={() => setCreateModalOpen(true)}
              type="primary"
              size="md"
            >
              {t('Create workspace')}
            </NblocksButton>
            <NblocksButton
              onClick={() => setImportModalOpen(true)}
              isLoading={importing}
              type="tertiary"
              size="md"
            >
              {t('Import workspace data')}
            </NblocksButton>
            <div className={searchQuery ? 'w-60' : ''}>
              <SearchComponent onQueryChange={setSearchQuery} />
            </div>
          </div>
        </div>
      </div>
      <TableComponent
        columns={cols}
        data={filteredTenants}
        loading={isLoading}
        defaultPageSize={10}
        emptyStateContent={<EmptyTenantList />}
      />
      <ModalComponent
        width="2xl"
        isOpen={importModalOpen}
        setIsOpen={setImportModalOpen}
        heading={'Import new workspaces'}
      >
        <ImportTenantModalComponent
          didClickImport={() => didClickImporting()}
          didClickCancel={() => setImportModalOpen(false)}
        />
      </ModalComponent>
      <ModalComponent
        isOpen={createModalOpen}
        setIsOpen={setCreateModalOpen}
        heading={'Create new workspace'}
      >
        <TenantModalComponent
          didClickSubmit={(tenant) => didClickModalCreate(tenant)}
          didClickCancel={() => setCreateModalOpen(false)}
        />
      </ModalComponent>
    </div>
  );
};

export { TenantListComponent };
