import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  BreadcrumpsComponent,
  Crump,
} from 'src/components/shared/breadcrumps/breadcrumps-component';
import { FunctionComponent } from 'react';
import { TabItem } from 'src/components/shared/tabs/tabs';
import { useLocation } from 'react-router-dom';

const AuthenticationBreadcrumpsComponent: FunctionComponent<{
  tabs: TabItem[];
}> = ({ tabs }) => {
  const { appId } = useCurrentApp();

  const location = useLocation();
  const paths = location.pathname.split('/');

  const activeTab = tabs.find((tab) => paths.includes(tab.id));

  const crumps: Crump[] = [
    {
      name: 'Authentication',
      href: `/app/${appId}/authentication`,
      active: false,
    },
  ];

  crumps.push({
    name: activeTab ? activeTab.name : '',
    href: `./${activeTab?.id}`,
    active: true,
  });

  return <BreadcrumpsComponent crumps={crumps} />;
};

export { AuthenticationBreadcrumpsComponent };
