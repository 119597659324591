import React from 'react';
import { useQuery } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';
import {
  GetAppAuthProviderStateDocument,
  GetAppAuthProviderStateQuery,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { PasskeysToggleCard } from './toggle-cards/passkeys-card.component';
import { MfaToggleCard } from './toggle-cards/mfa-card.component';
import { GenericLoginLinkComponent } from './generic-login-link.component';
import { MagicLinkToggleCard } from './toggle-cards/magic-link-card.component';
import { SocialConnectionsComponent } from './social.component';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';
import { FeatureFlagComponent } from '../shared/feature-flag/feature-flag.component';
import { FlagKeys } from 'src/shared/flag-keys';

export interface LoginSettingsCardProps {
  data: GetAppAuthProviderStateQuery;
}

const LoginSettingsComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const { data, loading } = useQuery(GetAppAuthProviderStateDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });

  return (
    <>
      <div>
        <div className="my-6 flex justify-between">
          <div className="flex flex-col">
            <SubHeadingComponent type={'primary'} size={'2xl'}>
              {t('Authentication methods')}
            </SubHeadingComponent>

            <TextComponent size={'base'} colorName="text-gray-500">
              {t(
                'Select the authentication methods available to user when signing in',
              )}
            </TextComponent>
          </div>
        </div>
        <div className="space-y-6">
          <div className="rounded-lg border border-gray-300 bg-white shadow p-6 grid grid-cols-2 gap-6">
            {loading && (
              <>
                <SkeletonLoader className="h-24 w-full rounded-md" />
                <SkeletonLoader className="h-24 w-full rounded-md" />
              </>
            )}
            {data && (
              <>
                <PasskeysToggleCard data={data} />
                <MagicLinkToggleCard data={data} />
              </>
            )}
          </div>
        </div>
      </div>
      <SocialConnectionsComponent />
      <FeatureFlagComponent flagKey={FlagKeys.MFA_BTN}>
        <div className="my-6 flex justify-between">
          <div className="flex flex-col">
            <SubHeadingComponent type={'primary'} size={'2xl'}>
              {t('Multi-factor authentication')}
            </SubHeadingComponent>

            <TextComponent size={'base'} colorName="text-gray-500">
              {t('Control and manage multi-factor authentication.')}
            </TextComponent>
          </div>
        </div>
        <div className="space-y-6">
          <div className="rounded-lg border border-gray-300 bg-white shadow p-6 grid grid-cols-2 gap-6">
            {loading && (
              <>
                <SkeletonLoader className="h-24 w-full rounded-md" />
              </>
            )}
            {data && (
              <>
                <MfaToggleCard data={data} />
              </>
            )}
          </div>
        </div>
      </FeatureFlagComponent>
      <div>
        <div className="my-6 flex justify-between">
          <div className="flex flex-col">
            <SubHeadingComponent type={'primary'} size={'2xl'}>
              {t('Login links')}
            </SubHeadingComponent>

            <TextComponent size={'base'} colorName="text-gray-500">
              {t('Copy links to allow users to login')}
            </TextComponent>
          </div>
        </div>
        <div className="space-y-6">
          <div className="rounded-lg border border-gray-300 bg-white shadow p-6 grid grid-cols-1 gap-6">
            <GenericLoginLinkComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export { LoginSettingsComponent };
