import React, { useEffect } from 'react';
import { LogoutScreen } from '@nebulr-group/nblocks-react/lib/screens/auth/login/Logout.screen';
import { CallbackScreen } from '@nebulr-group/nblocks-react/lib/screens/auth/oauth/Callback.screen';

import { Routes, Route, Navigate } from 'react-router-dom';
import { useSecureContext } from '@nebulr-group/nblocks-react';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginScreen />}></Route>
      <Route path="logout" element={<LogoutScreen />}></Route>
      <Route path="oauth-callback" element={<CallbackScreen />}></Route>
      <Route path="*" element={<Navigate to={'login'} replace={true} />} />
    </Routes>
  );
};

const LoginScreen = () => {
  const { authService } = useSecureContext();
  const loginUrl = authService.getLoginUrl({ state: '/', useShortHand: true });

  useEffect(() => {
    console.log(
      `Since the plugin couldn't redirect to Nblocks Auth, we're doing it manually: ${loginUrl}`,
    );
    window.location.replace(loginUrl);
  }, [authService]);

  return <h1>Logging in...</h1>;
};

export { AuthRoutes };
