import { useConfig } from '@nebulr-group/nblocks-react/lib/hooks/config-context';
import React, { useEffect } from 'react';
import { Config } from 'src/shared/Config';

function SubscriptionComponent() {
  const { oAuthBaseURI, appId } = useConfig();
  const backendlessUrl = Config.getBackendlessUrl();

  useEffect(() => {
    // Retrieve the access token JWT from localstorage
    const accessToken = window.localStorage.getItem('NB_OAUTH_ACCESS_TOKEN');
    if (accessToken) redirectToSelectPlan(accessToken);
  }, []);

  const redirectToSelectPlan = async (accessToken: string) => {
    // Make the API call to Nblocks
    const handoverCodeResult = await fetch(
      `${oAuthBaseURI}/handover/code/${appId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessToken,
        }),
      },
    ).then((res) => res.json());

    if (handoverCodeResult.code) {
      // Redirect to Nblocks Payments
      window.location.replace(
        `${backendlessUrl}/subscription-portal/selectPlan?code=${handoverCodeResult.code}`,
      );
    }
  };

  return null;
}

export { SubscriptionComponent };
