import React, { FunctionComponent, useState } from 'react';
import { TabIconType, TabItem, Tabs } from '../shared/tabs/tabs';
import { OnboardingStepsComponent } from './onboarding-steps.component';
import { OnboardingInput } from 'src/gql/graphql';
import { Step } from '../shared/steps/steps.component';

export type OnboardingStepType = {
  component: FunctionComponent;
  label: string;
  nextButtonLabel: string;
  onboardingMutationVariables?: OnboardingInput;
};

export type OnboardingTabs = Record<
  string,
  { title: string; steps: OnboardingStepType[]; icon?: TabIconType }
>;

interface ComponentProps {
  didFinishOnboarding: () => void;
  tabs: OnboardingTabs;
  pendingProps?: {
    isTestPending: boolean;
    pendingLabel: string;
  };
}

const OnboardingModalComponent = ({
  didFinishOnboarding,
  tabs,
  pendingProps = { isTestPending: false, pendingLabel: '' },
}: ComponentProps) => {
  const tabItems: TabItem[] = Object.keys(tabs).map((key) => ({
    id: key,
    name: tabs[key].title,
    icon: tabs[key].icon,
  }));

  const [selectedTab, setSelectedTab] = useState<TabItem>(tabItems[0]);
  const [stepIndex, setStepIndex] = useState(0);

  const initComponentSteps = (steps: OnboardingStepType[]): Step[] => {
    return steps.map((step, index) => {
      return {
        label: step.label,
        completed: false,
        current: index === 0,
      };
    });
  };

  const [componentSteps, setComponentSteps] = React.useState<Step[]>(
    initComponentSteps(tabs[selectedTab.id].steps),
  );

  const onDidClickTab = (tab: TabItem) => {
    setSelectedTab(tab);
    setStepIndex(0);
    setComponentSteps(initComponentSteps(tabs[tab.id].steps));
  };

  return (
    <div className="mt-3">
      {tabItems.length > 1 && (
        <Tabs tabs={tabItems} didClickTab={(tab) => onDidClickTab(tab)} />
      )}
      <OnboardingStepsComponent
        steps={tabs[selectedTab.id].steps}
        currentIndex={stepIndex}
        setCurrentIndex={setStepIndex}
        componentSteps={componentSteps}
        setComponentSteps={setComponentSteps}
        didFinishOnboarding={didFinishOnboarding}
        isTestPending={pendingProps.isTestPending}
        pendingLabel={pendingProps.pendingLabel}
      />
    </div>
  );
};

export { OnboardingModalComponent };
