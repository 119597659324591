import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';

import {
  AppConfig,
  AppConfigGraphqlInput,
  GetAppPropertiesDocument,
  ListAppsDocument,
  UpdateAppPropertiesDocument,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ImageComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ImageComponent';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { ImportLogoModalComponent } from './properties/import-logo.modal.component';
import { useButtonSuccess } from 'src/shared/hooks/use-button-success';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';

const PropertiesCustomizationComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const [importModalOpen, setImportModalOpen] = useState(false);

  const { buttonSuccess, triggerButtonSuccess } = useButtonSuccess();

  const [currentAppProperties, setCurrentAppProperties] = useState<
    AppConfig | undefined
  >();
  const [appProperties, setAppProperties] = useState<
    AppConfigGraphqlInput | undefined
  >();

  const { data, loading } = useQuery(GetAppPropertiesDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });

  const [updateAppProperties, updateAppPropertiesData] = useMutation(
    UpdateAppPropertiesDocument,
    {
      refetchQueries: [ListAppsDocument],
    },
  );

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line
      const { __typename, ...appPropertiesData } = data.getApp;
      setCurrentAppProperties({ ...appPropertiesData });
    }
  }, [data]);

  const didClickImporting = (logoUrl: string) => {
    setAppProperties({
      ...appProperties,
      logo: logoUrl,
    });
    setImportModalOpen(false);
  };

  const didClickSave = async () => {
    if (appId) {
      await updateAppProperties({
        variables: { id: appId, app: { ...appProperties } },
      });
      triggerButtonSuccess();
    }
  };

  return (
    <>
      <div>
        <div className="my-6 flex justify-between">
          <div className="flex flex-col">
            <SubHeadingComponent type={'primary'} size={'2xl'}>
              {t('App properties')}
            </SubHeadingComponent>

            <TextComponent size={'base'} colorName="text-gray-500">
              {t('Set the general properties of your app')}
            </TextComponent>
          </div>
        </div>
        {loading && (
          <div className="space-y-6">
            <div className="rounded-lg border border-gray-300 bg-white shadow p-6">
              <div className="flex flex-col w-2/5">
                <div className="w-4/5 space-y-6">
                  <SkeletonLoader className="h-16 w-full rounded-md" />
                  <SkeletonLoader className="h-16 w-full rounded-md" />
                  <SkeletonLoader className="h-16 w-full rounded-md" />
                  <SkeletonLoader className="h-16 w-full rounded-md" />
                </div>
              </div>
            </div>
          </div>
        )}
        {currentAppProperties && appId && (
          <div className="space-y-6">
            <div className="rounded-lg border border-gray-300 bg-white shadow p-6">
              <div className="flex flex-col w-2/5">
                <div className="w-4/5 my-2">
                  <InputComponent
                    label={t('App name')}
                    type={'text'}
                    value={
                      appProperties?.name ?? currentAppProperties.name ?? ''
                    }
                    onChange={(event) =>
                      setAppProperties({
                        ...appProperties,
                        name: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-4/5 my-2 flex flex-row justify-between items-center">
                  <NblocksButton
                    size="sm"
                    className="h-12"
                    type="tertiary"
                    onClick={() => setImportModalOpen(true)}
                  >
                    {t('Update logo')}
                  </NblocksButton>
                  <ImageComponent
                    src={appProperties?.logo ?? currentAppProperties.logo ?? ''}
                    alt="App logo"
                    className="w-1/3"
                  />
                </div>
                <div className="w-4/5 my-2">
                  <InputComponent
                    label={t('Privacy policy URL')}
                    type={'text'}
                    value={
                      appProperties?.privacyPolicyUrl ??
                      currentAppProperties.privacyPolicyUrl ??
                      ''
                    }
                    onChange={(event) =>
                      setAppProperties({
                        ...appProperties,
                        privacyPolicyUrl: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-4/5 my-2">
                  <InputComponent
                    label={t('Terms of service URL')}
                    type={'text'}
                    value={
                      appProperties?.termsOfServiceUrl ??
                      currentAppProperties.termsOfServiceUrl ??
                      ''
                    }
                    onChange={(event) =>
                      setAppProperties({
                        ...appProperties,
                        termsOfServiceUrl: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-1/5 my-2">
                  <NblocksButton
                    size="sm"
                    className="h-12"
                    type={buttonSuccess ? 'success' : 'primary'}
                    isLoading={updateAppPropertiesData.loading}
                    onClick={() => didClickSave()}
                  >
                    {buttonSuccess ? t('Saved!') : t('Save')}
                  </NblocksButton>
                </div>
              </div>
            </div>
            <ModalComponent
              width="2xl"
              isOpen={importModalOpen}
              setIsOpen={setImportModalOpen}
              heading={'Update logo'}
            >
              <ImportLogoModalComponent
                appId={appId}
                didClickImport={didClickImporting}
                didClickCancel={() => setImportModalOpen(false)}
              />
            </ModalComponent>
          </div>
        )}
      </div>
    </>
  );
};

export { PropertiesCustomizationComponent };
