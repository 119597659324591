import { FlagsComponent } from 'src/components/feature-flags/flags.component';
import { SegmentsComponent } from 'src/components/feature-flags/segments.component';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import React, { useState } from 'react';
import { TabItem, Tabs } from '../../components/shared/tabs/tabs';
import { FeatureFlagsPromptComponent } from 'src/components/onboarding/feature-flags/feature-flags-prompt.component';

const AppFeatureFlagsScreen = () => {
  const tabs = [
    { name: 'Flags', id: 'flags' },
    { name: 'Groups', id: 'groups' },
  ];

  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs[0]);

  const onDidClickTab = (tab: TabItem) => {
    setSelectedTab(tab);
  };

  const renderSelectedTab = () => {
    switch (selectedTab.id) {
      case 'groups':
        return <SegmentsComponent />;
      case 'flags':
      default:
        return <FlagsComponent />;
    }
  };

  return (
    <div className="space-y-6">
      <div className="relative w-full">
        <FeatureFlagsPromptComponent />
      </div>
      <HeadingComponent type={'h1'} size={'2xl'} className={'mt-5'}>
        Feature control
      </HeadingComponent>
      <Tabs tabs={tabs} didClickTab={(tab) => onDidClickTab(tab)} />
      {renderSelectedTab()}
    </div>
  );
};

export { AppFeatureFlagsScreen };
