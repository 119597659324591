import { useConfig } from '@nebulr-group/nblocks-react/lib/hooks/config-context';
import React, { useEffect, useState } from 'react';
import { Config } from 'src/shared/Config';
import { AppTopbarComponent } from '../app-topbar.component';

const TeamComponent = () => {
  const [url, setUrl] = useState('');

  const { oAuthBaseURI, appId } = useConfig();
  const backendlessUrl = Config.getBackendlessUrl();

  useEffect(() => {
    // Retrieve the access token JWT from localstorage
    const accessToken = window.localStorage.getItem('NB_OAUTH_ACCESS_TOKEN');
    if (accessToken) createUrl(accessToken);
  }, []);

  const createUrl = async (accessToken: string) => {
    // Get the handover code from Nblocks
    const result = await fetch(`${oAuthBaseURI}/handover/code/${appId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accessToken,
      }),
    }).then((res) => res.json());

    // Create the user management portal url
    setUrl(
      `${backendlessUrl}/user-management-portal/users?code=${result.code}`,
    );
  };

  // Render the url as an iframe
  return (
    <div>
      <AppTopbarComponent menuItems={[]} outsideAppContext={true} />
      <div className="h-screen">
        <iframe width="100%" height="90%" src={url}></iframe>;
      </div>
    </div>
  );
};

export { TeamComponent };
