export class FlagKeys {
  static CREATE_APP_BTN = 'create-new-app-btn';
  static SUBSCRIPTION_BTN = 'subscription-btn';
  static TEAM_BTN = 'team-btn';
  static ENTERPRISE_CONNECTIONS = 'enterprise_connections-btn';
  static BRANDING_BTN = 'branding-btn';
  static EMAIL_SENDER_BTN = 'email-sender-btn';
  static ADVANCED_BTN = 'advanced-btn';
  static MFA_BTN = 'mfa-btn';
}
