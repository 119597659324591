import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import {
  FileDropAreaComponent,
  UploadedFileEvent,
} from 'src/components/shared/file-drop-area/file-drop-area.component';
import { PublishAppLogoDocument } from 'src/gql/graphql';
import { useMutation } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';

export interface ComponentProps {
  appId: string;
  didClickImport: (logoUrl: string) => void;
  didClickCancel: () => void;
}

const ImportLogoModalComponent = ({
  appId,
  didClickImport,
  didClickCancel,
}: ComponentProps) => {
  const { t } = useTranslation();
  const [uploadedLogoKey, setUploadedLogoKey] = useState<string>();
  const [uploadedLogoPreviewURL, setUploadedLogoPreviewURL] =
    useState<string>();

  const [publishAppLogo, { loading }] = useMutation(PublishAppLogoDocument, {
    refetchQueries: ['GetAppProperties'],
    onCompleted: (data) => {
      didClickImport(data.persistPublicAppLogo);
    },
  });

  const onDidUploadLogo = (event: UploadedFileEvent) => {
    setUploadedLogoPreviewURL(URL.createObjectURL(event.file));
    setUploadedLogoKey(event.uploadedKey);
  };

  const onDidClickImport = () => {
    if (appId && uploadedLogoKey) {
      publishAppLogo({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { appId: appId!, uploadedKey: uploadedLogoKey },
      });
    }
  };

  const formValid = uploadedLogoKey;

  return (
    <div className="modal-body">
      <div>
        <form onSubmit={() => onDidClickImport()} className="space-y-6 w-full">
          <div>
            <TextComponent>
              {t('Upload your logo to customize the app')}
            </TextComponent>
            {uploadedLogoPreviewURL ? (
              <div className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg bg-gray-100">
                <img
                  src={uploadedLogoPreviewURL}
                  alt="uploaded logo"
                  className="h-64 w-full object-contain"
                />
              </div>
            ) : (
              <FileDropAreaComponent
                didUploadFile={onDidUploadLogo}
                subHeader="PNG, JPG or SVG"
                acceptFileTypes={['.png', '.jpeg', '.jpg', '.svg']}
              />
            )}
          </div>
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {t('Cancel')}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          isLoading={loading}
          onClick={() => onDidClickImport()}
          disabled={!formValid}
        >
          {t('Upload')}
        </NblocksButton>
      </div>
    </div>
  );
};

export { ImportLogoModalComponent };
