import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import { FunctionComponent, useState } from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

const CopyChipComponent: FunctionComponent<{
  value: string;
  label?: string;
}> = ({ label, value }) => {
  const { t } = useTranslation();
  const [copyNotice, setCopyNotice] = useState(false);

  if (!label) label = value;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(value);
    setCopyNotice(true);
    setTimeout(() => {
      setCopyNotice(false);
    }, 500);
  };

  return (
    <div onClick={handleCopyClick} className="hover:cursor-pointer w-fit">
      <ChipComponent
        type={'tertiary'}
        icon={
          <div className={'text-gray-400 h-4 w-4 m-1'} aria-hidden="true">
            <DocumentDuplicateIcon />
          </div>
        }
      >
        {copyNotice ? t('Copied!') : label}
      </ChipComponent>
    </div>
  );
};

export { CopyChipComponent };
