import React from 'react';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { useNavigate, Outlet } from 'react-router-dom'; // Updated import to include 'Routes'
import { TabItem, Tabs } from 'src/components/shared/tabs/tabs';
import { useTranslation } from 'react-i18next';
import { CustomizationBreadcrumpsComponent } from 'src/components/customization/customization-breadcrumps-component';
import { useFlags } from 'src/shared/hooks/use-flags';
import { FlagKeys } from 'src/shared/flag-keys';

const AppCustomizationScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { flagEnabled } = useFlags();

  const tabs: TabItem[] = [{ name: t('App properties'), id: 'properties' }];

  if (flagEnabled(FlagKeys.BRANDING_BTN)) {
    tabs.push({ name: t('Branding'), id: 'branding' });
  }

  if (flagEnabled(FlagKeys.EMAIL_SENDER_BTN)) {
    tabs.push({ name: t('Email'), id: 'email' });
  }

  const tabFromUrl = tabs.find((tab) => location.pathname.includes(tab.id));

  const handleTabClick = (tab: TabItem) => {
    navigate(`${tab.id}`);
  };

  return (
    <div>
      <CustomizationBreadcrumpsComponent tabs={tabs} />
      <HeadingComponent type={'h1'} size={'2xl'} className={'mt-5'}>
        {t('Customization')}
      </HeadingComponent>
      <Tabs
        tabs={tabs}
        initTab={tabFromUrl ? tabFromUrl : undefined}
        didClickTab={(tab) => handleTabClick(tab)}
      />
      <Outlet />
    </div>
  );
};

export { AppCustomizationScreen };
