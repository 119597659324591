import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { ResetAppSecretDocument } from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const ApiKeysComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { setSuccessMessage } = useFeedback();
  const [apiKey, setApiKey] = useState<string | undefined>();
  const { app } = useCurrentApp();

  const [resetAppSecretMutation] = useMutation(ResetAppSecretDocument);

  const resetApiKey = async () => {
    if (app) {
      const result = await resetAppSecretMutation({
        variables: { id: app.id },
      });
      setApiKey(result.data?.resetAppSecret);
      setIsOpen(false);
      setSuccessMessage('The api key was renewed! Store it somewhere safe.');
    }
  };

  return (
    <div className="space-y-6 max-w-md">
      <div>
        <InputComponent
          disabled={apiKey ? true : true}
          value={apiKey ? apiKey : 'nonsensenonsensenonsensenonsensenonsense'}
          type={apiKey ? 'text' : 'password'}
          label=""
        />
        <NblocksButton
          onClick={() => setIsOpen(true)}
          type="tertiary"
          size="md"
          className="mt-1"
        >
          Reset
        </NblocksButton>
      </div>
      <ModalComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading={'Are you sure?'}
        description={
          'Do you want to generate a new api key? This will disable the current one'
        }
        icon={<ExclamationTriangleIcon />}
      >
        <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
          <NblocksButton
            size="md"
            className="w-full"
            type="tertiary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </NblocksButton>
          <NblocksButton
            size="md"
            className="w-full"
            type="primary"
            onClick={() => resetApiKey()}
          >
            Reset
          </NblocksButton>
        </div>
      </ModalComponent>
    </div>
  );
};

export { ApiKeysComponent };
