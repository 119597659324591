export type Environment = 'dev' | 'stage' | 'prod';

export class Config {
  static getAppId(): string {
    return process.env.REACT_APP_NBLOCKS_APP_ID as string;
  }

  static getUrl(): string {
    return process.env.REACT_APP_NBLOCKS_ADMIN_UI_URL as string;
  }

  static getAccountApiUrl(): string {
    return process.env.REACT_APP_NBLOCKS_ACCOUNT_API_URL as string;
  }

  static getBackendUrl(): string {
    return process.env.REACT_APP_NBLOCKS_ADMIN_API_URL_UI as string;
  }

  static getBackendlessUrl(): string {
    return process.env.REACT_APP_NBLOCKS_BACKENDLESS_API_URL_UI as string;
  }

  static getOauthApiUrl(): string {
    return process.env.REACT_APP_NBLOCKS_AUTH_API_URL_UI as string;
  }

  /**
   * Loglevels
   * NBLOCKS_DEBUG=*
   * NBLOCKS_DEBUG=error
   * NBLOCKS_DEBUG=debug
   * @returns
   */
  static isDebugLogLevel(): boolean {
    return (
      process.env.NBLOCKS_DEBUG === '*' || process.env.NBLOCKS_DEBUG === 'debug'
    );
  }

  static isErrorLogLevel(): boolean {
    return this.isDebugLogLevel() || process.env.NBLOCKS_DEBUG === 'error';
  }

  /**
   * Env must come from TSX file to be resolved by react-scripts
   * @param env
   * @returns
   */
  static getEnvironment(env?: string): Environment {
    return env ? (env.toLowerCase() as Environment) : 'dev';
  }
}
