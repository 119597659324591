import React from 'react';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import Select, { MultiValue } from 'react-select';

export interface ComponentProps {
  label?: string;
  name: string;
  data: Selectable[];
  init?: Selectable[];
  didSelect: (selectables: Selectable[]) => void;
}

export interface Selectable {
  value: string;
  label: string;
}

//FIXME this component cannot rerender should the init value change
// Fix is to not render the component until the init value has been resolved
const MultiOptions = ({
  label,
  name,
  data,
  init,
  didSelect,
}: ComponentProps) => {
  const didChange = (values: MultiValue<Selectable>) => {
    const selectables = values.map((v) => v);
    didSelect(selectables);
  };

  // console.log({ init, data });

  return (
    <div className="container mx-auto mt-20">
      <TextComponent size="base">{label}</TextComponent>
      <Select
        defaultValue={init}
        onChange={(values) => didChange(values)}
        isMulti
        name={name}
        options={data}
        className="w-full"
        classNamePrefix="select"
      />
    </div>
  );
};

export { MultiOptions };
