import { useQuery } from '@apollo/client';
import { ListAppsDocument } from '../gql/graphql';
import { Navigate } from 'react-router-dom';
import { SplashComponent } from 'src/components/shared/splash/splash.component';

const HomeScreen = () => {
  //return <SplashComponent />;

  const { data, loading } = useQuery(ListAppsDocument);

  if (loading) return <SplashComponent />;

  // if (error) return <h1>Something went wrong. Try again...</h1>;

  if (data) {
    if (data.listApps.length === 0) {
      return <Navigate to={`/create-app`}></Navigate>;
    } else {
      const appId = data.listApps[0].id;
      return <Navigate to={`/app/${appId}`}></Navigate>;
    }
  }

  return null;
};

export { HomeScreen };
