import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { DocumentDuplicateIcon } from '@heroicons/react/24/solid';
import { useMutation, useLazyQuery } from '@apollo/client';
import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import {
  GetResetUserPasswordLinkDocument,
  ResetUserPasswordDocument,
  User,
} from 'src/gql/graphql';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const ResetUserPasswordModalComponent = ({
  user,
  tenantId,
  appId,
  setResetPasswordModalOpen,
}: {
  user: User;
  tenantId: string;
  appId: string;
  setResetPasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const [copyNotice, setCopyNotice] = useState(false);
  const { setSuccessMessage } = useFeedback();
  const [resetUserPasswordMutation] = useMutation(ResetUserPasswordDocument);
  const [getResetUserPasswordLink, { data: resetUserPasswordLink }] =
    useLazyQuery(GetResetUserPasswordLinkDocument);

  const handleCopyClick = () => {
    getResetUserPasswordLink({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { appId: appId!, tenantId: tenantId!, userId: user.id },
    }).then(({ data }) => {
      if (data) {
        navigator.clipboard.writeText(data?.getResetUserPasswordLink);
        setCopyNotice(true);
        setTimeout(() => {
          setCopyNotice(false);
        }, 500);
      }
    });
  };

  const onDidClickResetPassword = async () => {
    const result = await resetUserPasswordMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { appId: appId!, tenantId: tenantId!, userId: user.id },
    });

    if (result.data?.resetUserPassword) setResetPasswordModalOpen(false);

    setSuccessMessage(t('The email was sent!'));
  };

  return (
    <div>
      <TextComponent>
        {t(
          'Are you sure you want to reset the password for the following user?',
        )}
      </TextComponent>
      <TextComponent className="text-center my-4">
        <span className="font-semibold">{user.email}</span>
      </TextComponent>
      <TextComponent>
        {t(
          'You can copy the link below and send it to the user yourself or send it directly via email.',
        )}
      </TextComponent>
      <div className="w-full flex justify-center my-4">
        <div onClick={handleCopyClick} className="hover:cursor-pointer w-fit">
          <ChipComponent
            type={'tertiary'}
            icon={
              <div className={'text-gray-400 h-4 w-4 m-1'} aria-hidden="true">
                <DocumentDuplicateIcon />
              </div>
            }
          >
            {copyNotice ? t('Copied!') : t('Copy reset link')}
          </ChipComponent>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => setResetPasswordModalOpen(false)}
        >
          {t('Cancel')}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickResetPassword()}
        >
          {t('Send reset email')}
        </NblocksButton>
      </div>
    </div>
  );
};

export { ResetUserPasswordModalComponent };
