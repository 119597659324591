import React, { useEffect, useState } from 'react';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import {
  OptionsComponent,
  Selectable,
} from '../shared/options/options.component';
import { useTranslation } from 'react-i18next';
import {
  KeyTarget,
  Target,
  TenantTarget,
  TargetValue,
  UserTarget,
} from 'src/gql/graphql';
import { ApolloUtils } from 'src/shared/apollo-utils';
import { SafeTenantName } from '../tenant/safe-tenant-name/safe-tenant-name.component';
import { useListTenants } from 'src/shared/hooks/use-list-tenants';
import { useListCurrentPlans } from 'src/shared/hooks/use-list-plans';
import { useListCurrentRoles } from 'src/shared/hooks/use-list-roles';
import { TogglerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TogglerComponent';
import { Switch } from '@headlessui/react';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';

export interface ComponentProps {
  target: Target;
  onChange: (target: Target) => void;
}

const fields: Selectable[] = [
  { value: 'tenant-plan', label: 'Workspace plan' },
  { value: 'tenant-key', label: 'Workspace key' },
  { value: 'tenant-id', label: 'Workspace ID' },
  { value: 'tenant-name', label: 'Workspace name' },
  { value: 'user-key', label: 'User key' },
  { value: 'user-id', label: 'User ID' },
  { value: 'user-name', label: 'User name' },
  { value: 'user-email', label: 'User email' },
  { value: 'user-role', label: 'User role' },
  { value: 'device-key', label: 'Device key' },
];

const operators: Selectable[] = [
  { value: 'eq', label: 'Equals' },
  { value: 'beginsWith', label: 'Begins with' },
  { value: 'endsWith', label: 'Ends with' },
  { value: 'contains', label: 'Contains' },
  { value: 'lessThan', label: 'Lower than' },
  { value: 'greaterThan', label: 'Greater than' },
];

type OperatorType =
  | 'eq'
  | 'beginsWith'
  | 'endsWith'
  | 'contains'
  | 'lessThan'
  | 'greaterThan';

type ReversedTarget = { field: string; operator: string; value: string };

const reverseTarget = (target: Target): ReversedTarget | undefined => {
  const cleanTarget = ApolloUtils.removeTypeName<any>(target);
  const contextType = Object.keys(cleanTarget).filter(
    (key) => !!(target as any)[key],
  )[0];
  switch (contextType) {
    case 'user':
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return reverseTargetType(cleanTarget.user!, 'user');
    case 'org':
      // Legacy support
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return reverseTargetType(cleanTarget.org!, 'tenant');
    case 'tenant':
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return reverseTargetType(cleanTarget.tenant!, 'tenant');
    case 'device':
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return reverseTargetType(cleanTarget.device!, 'device');
    default:
      break;
  }
};

const reverseTargetType = (
  target: UserTarget | TenantTarget | KeyTarget,
  prefix: string,
): { field: string; operator: string; value: string } => {
  const field = Object.keys(target).filter((key) => !!(target as any)[key])[0];

  if (field === 'id') {
    return {
      field: `${prefix}-${field}`,
      operator: 'eq',
      value: (target as any).id,
    };
  } else {
    const targetValue: TargetValue = (target as any)[field];
    return {
      field: `${prefix}-${field}`,
      operator: targetValue.operator,
      value: targetValue.value,
    };
  }
};

const SegmentTargetComponent = ({ target, onChange }: ComponentProps) => {
  const [field, setField] = useState<Selectable | undefined>(fields[0]);
  const [operator, setOperator] = useState<OperatorType>('eq');
  const [autoInput, setAutoInput] = useState(false);
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const { data: tenantsQuery, loading: tenantsLoading } = useListTenants();
  const { data: plansQuery, loading: plansLoading } = useListCurrentPlans();
  const { data: rolesQuery, loading: rolesLoading } = useListCurrentRoles();

  const nblocksTenants: Selectable[] = tenantsQuery
    ? tenantsQuery.listTenants.map((t) => {
        return { value: t.id, label: `${t.id} (${SafeTenantName(t.name)})` };
      })
    : [];

  const nblocksPlans: Selectable[] = plansQuery
    ? plansQuery.getAppPlans.map((p) => {
        return { value: p.key, label: `${p.key} (${p.name})` };
      })
    : [];

  const nblocksRoles: Selectable[] = rolesQuery
    ? rolesQuery.listRoles.map((r) => {
        return { value: r.key, label: `${r.key} (${r.name})` };
      })
    : [];

  const hasTenants = nblocksTenants.length > 0;
  const hasPlans = nblocksPlans.length > 0;
  const hasRoles = nblocksRoles.length > 0;
  const magicField =
    !!field &&
    ['org-id', 'org-plan', 'tenant-id', 'tenant-plan', 'user-role'].includes(
      field.value,
    );
  const formValid = field && operator && value;
  let showAutoInputToggle = false;
  let autoInputToggleLabel = '';

  const inputShouldBeFloat = (
    ['greaterThan', 'lessThan'] as OperatorType[]
  ).includes(operator);

  // Go through magic fields
  switch (field?.value) {
    case 'tenant-id':
    case 'org-id':
      showAutoInputToggle = hasTenants;
      autoInputToggleLabel = t('Autofill app workspaces');
      break;

    case 'tenant-plan':
    case 'org-plan':
      showAutoInputToggle = hasPlans;
      autoInputToggleLabel = t('Autofill app plans');
      break;

    case 'user-role':
      showAutoInputToggle = hasRoles;
      autoInputToggleLabel = t('Autofill app roles');
      break;
  }

  useEffect(() => {
    const tReverse = reverseTarget(target);
    if (tReverse) {
      // Edit target
      const _field = fields.find((f) => f.value === tReverse.field);
      setField(_field);
      setOperator(tReverse.operator as OperatorType);
      setValue(tReverse.value);
      setAutoInput(magicField);
    } else {
      // New target
      if (field) handleValueEffectOfField(field?.value);
    }
  }, [tenantsQuery, plansQuery, rolesQuery]);

  const generateTarget = (): Target => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    switch (field!.value) {
      case 'user-id':
        return { user: { id: value } };
      case 'user-key':
        return { user: { key: { operator, value } } };
      case 'user-name':
        return { user: { name: { operator, value } } };
      case 'user-email':
        return { user: { email: { operator, value } } };
      case 'user-role':
        return { user: { role: { operator, value } } };
      // Legacy support
      case 'org-key':
        return { org: { key: { operator, value } } };
      case 'org-id':
        return { org: { id: value } };
      case 'org-plan':
        return { org: { plan: { operator, value } } };
      case 'org-name':
        return { org: { name: { operator, value } } };
      // End of legacy support
      case 'tenant-key':
        return { tenant: { key: { operator, value } } };
      case 'tenant-id':
        return { tenant: { id: value } };
      case 'tenant-plan':
        return { tenant: { plan: { operator, value } } };
      case 'tenant-name':
        return { tenant: { name: { operator, value } } };
      case 'device-key':
        return { device: { key: { operator, value } } };
      default:
        return {};
    }
  };

  useEffect(() => {
    if (formValid) {
      onChange(generateTarget());
    }
  }, [field, operator, value]);

  const onDidSelectField = (selectable: Selectable) => {
    setField(selectable);
    handleValueEffectOfField(selectable.value);
  };

  const handleValueEffectOfField = (fieldValue: string) => {
    // Reset value if we're moving to a "magic" field

    switch (fieldValue) {
      case 'org-id':
      case 'tenant-id':
        setOperator('eq');
        setAutoInput(hasTenants);
        setValue(hasTenants ? nblocksTenants[0].value : '');
        break;
      case 'tenant-plan':
      case 'org-plan':
        setAutoInput(hasPlans);
        setValue(hasPlans ? nblocksPlans[0].value : '');
        break;
      case 'user-role':
        setAutoInput(hasRoles);
        setValue(hasRoles ? nblocksRoles[0].value : '');
        break;
      case 'user-id':
        setOperator('eq');
        // TODO how can we give a raw list of users?
        //setAutoInput(hasUsers);
        //setValue(hasTenants ? nblocksTenants[0].value : '');
        setAutoInput(false);
        setValue('');
        break;
      default:
        setAutoInput(false);
        setValue('');
        break;
    }
  };

  const onDidSelectOperator = (selectable: Selectable) => {
    setOperator(
      selectable.value as 'eq' | 'beginsWith' | 'endsWith' | 'contains',
    );
  };

  const renderOperatorInput = () => {
    switch (field?.value) {
      case 'org-id':
      case 'tenant-id':
      case 'user-id':
        // eslint-disable-next-line no-case-declarations
        const eqOperator = operators.find((o) => o.value === 'eq');
        if (eqOperator) {
          return (
            <OptionsComponent
              label="Operator"
              data={[eqOperator]}
              init={operators.find((o) => o.value === operator)}
              didSelect={(selectable) => onDidSelectOperator(selectable)}
            />
          );
        }
        break;

      default:
        return (
          <OptionsComponent
            label="Operator"
            data={operators}
            init={operators.find((o) => o.value === operator)}
            didSelect={(selectable) => onDidSelectOperator(selectable)}
          />
        );
    }
  };

  const renderValueInput = () => {
    // Always render the text input component when running manual input
    if (!autoInput) {
      return (
        <InputComponent
          type={inputShouldBeFloat ? 'number' : 'text'}
          label="Value*"
          placeholder={
            inputShouldBeFloat ? 'E.g. 20.5' : 'E.g. iphone or john@doe.com'
          }
          step={inputShouldBeFloat ? '0.1' : undefined}
          name="value"
          onChange={(event) => setValue(event.target.value)}
          value={value}
        />
      );
    }

    if (tenantsLoading || rolesLoading || plansLoading) {
      return <SkeletonLoader className="h-12 w-full rounded-md" />;
    }

    switch (field?.value) {
      case 'tenant-id':
      case 'org-id':
        return (
          <OptionsComponent
            label="Nblocks workspaces"
            search={nblocksTenants.length > 5}
            data={nblocksTenants}
            init={nblocksTenants.find((t) => t.value === value)}
            didSelect={(selectable) => setValue(selectable.value)}
          />
        );

      // TODO How can we render all users in app?
      // case 'user-id':
      //   return (
      //     <OptionsComponent
      //       label="Nblocks workspaces"
      //       search={nblocksTenants.length > 5}
      //       data={nblocksTenants}
      //       init={nblocksTenants.find((t) => t.value === value)}
      //       didSelect={(selectable) => setValue(selectable.value)}
      //     />
      //   );

      case 'tenant-plan':
      case 'org-plan':
        return (
          <OptionsComponent
            label="Nblocks plans"
            data={nblocksPlans}
            init={nblocksPlans.find((t) => t.value === value)}
            didSelect={(selectable) => setValue(selectable.value)}
          />
        );

      case 'user-role':
        return (
          <OptionsComponent
            label="Nblocks roles"
            data={nblocksRoles}
            init={nblocksRoles.find((t) => t.value === value)}
            didSelect={(selectable) => setValue(selectable.value)}
          />
        );

      default:
        return (
          <InputComponent
            type="text"
            label="Value*"
            placeholder="E.g. iphone or john@doe.com"
            name="value"
            onChange={(event) => setValue(event.target.value)}
            value={value}
          />
        );
    }
  };

  return (
    <>
      <div>
        <OptionsComponent
          label="Field"
          data={fields}
          init={field}
          didSelect={(selectable) => onDidSelectField(selectable)}
        />
      </div>
      <div>{renderOperatorInput()}</div>
      <div>{renderValueInput()}</div>
      {showAutoInputToggle && (
        <Switch.Group>
          <div className="flex items-center">
            <Switch.Label className="mr-4">
              <TextComponent size="sm">{autoInputToggleLabel}</TextComponent>
            </Switch.Label>
            <TogglerComponent
              enabled={autoInput}
              setEnabled={setAutoInput}
            ></TogglerComponent>
          </div>
        </Switch.Group>
      )}
    </>
  );
};

export type { ReversedTarget };
export { SegmentTargetComponent, reverseTarget, operators, fields };
