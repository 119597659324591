import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { DeleteAppComponent } from 'src/components/app/delete-app-component/delete-app.component';

const AppAdvancedScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="space-y-6">
      <HeadingComponent type={'h1'} size={'2xl'} className={'mt-5'}>
        {t('Advanced settings')}
      </HeadingComponent>
      <SubHeadingComponent type={'primary'} size={'xl'}>
        {t('Delete App')}
      </SubHeadingComponent>
      <TextComponent size={'base'} colorName="text-gray-500">
        {t('Delete this app and all its data.')}
      </TextComponent>
      <DeleteAppComponent />
    </div>
  );
};

export { AppAdvancedScreen };
