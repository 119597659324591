import { useQuery } from '@apollo/client';
import { GetSeatsDocument } from '../../gql/graphql';

const useSeats = () => {
  const { data, loading, startPolling } = useQuery(GetSeatsDocument, {
    fetchPolicy: 'no-cache',
  });

  return {
    loading,
    startPolling,
    seats: data?.getSeats,
  };
};

export { useSeats };
