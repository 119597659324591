import React, { FunctionComponent, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { useQuery, useMutation } from '@apollo/client';
import {
  ListPrivilegesDocument,
  UpdatePrivilegeDocument,
  CreatePrivilegeDocument,
  DeletePrivilegeDocument,
  PrivilegeGraphql,
  PrivilegeGraphqlInput,
  CreatePrivilegeGraphqlInput,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import type { ColumnDef } from '@tanstack/react-table';
import { TableComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TableComponent';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import { PrivilegeModalComponent } from './privilege.modal.component';
import { CopyChipComponent } from './copy.button.component';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const PrivilegesComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editPrivilege, setEditPrivilege] = useState<PrivilegeGraphql>();
  const { setSuccessMessage } = useFeedback();
  const { appId } = useCurrentApp();
  const { data, loading } = useQuery(ListPrivilegesDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });

  const [updatePrivilegeMutation, updatePrivilegeData] = useMutation(
    UpdatePrivilegeDocument,
  );
  const [createPrivilegeMutation, createPrivilegeData] = useMutation(
    CreatePrivilegeDocument,
  );
  const [deletePrivilegeMutation, deletePrivilegeData] = useMutation(
    DeletePrivilegeDocument,
  );

  const isLoading =
    loading ||
    updatePrivilegeData.loading ||
    createPrivilegeData.loading ||
    deletePrivilegeData.loading;

  const didClickDelete = async (row: PrivilegeGraphql) => {
    if (appId) {
      await deletePrivilegeMutation({
        variables: { id: row.id, appId: appId },
        refetchQueries: [
          { query: ListPrivilegesDocument, variables: { appId: appId } },
        ],
      });
      setSuccessMessage('The privilege was deleted!');
    }
  };

  const didClickAddBtn = () => {
    setEditPrivilege(undefined);
    setModalOpen(true);
  };

  const didClickEdit = (row: PrivilegeGraphql) => {
    setEditPrivilege(row);
    setModalOpen(true);
  };

  const didClickEditModalSave = async (args: PrivilegeGraphqlInput) => {
    if (appId) {
      if (editPrivilege) {
        await updatePrivilegeMutation({
          variables: {
            id: editPrivilege.id,
            appId: appId,
            privilege: { ...args },
          },
        });
      }
      setModalOpen(false);
      setSuccessMessage('The privilege was updated!');
    }
  };

  const didClickCreateModalSave = async (args: CreatePrivilegeGraphqlInput) => {
    if (appId) {
      await createPrivilegeMutation({
        variables: {
          appId: appId,
          privilege: { ...args },
        },
        refetchQueries: [
          { query: ListPrivilegesDocument, variables: { appId: appId } },
        ],
      });
      setModalOpen(false);
      setSuccessMessage('A new privilege was created!');
    }
  };

  const EditComponent: FunctionComponent<{ row: PrivilegeGraphql }> = ({
    row,
  }) => {
    const options: Option[] = [
      {
        label: 'Edit',
        onClick: () => {
          didClickEdit(row);
        },
        labelPosition: 'start',
      },
    ];

    if (row.key != 'AUTHENTICATED')
      options.push({
        label: 'Delete',
        type: 'danger',
        onClick: () => {
          didClickDelete(row);
        },
        labelPosition: 'start',
      });

    return (
      <div className="flex items-center justify-end">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  const cols: ColumnDef<PrivilegeGraphql>[] = [
    {
      header: 'Key',
      cell: (row) => <CopyChipComponent value={row.renderValue() as string} />,
      accessorKey: 'key',
      size: 250,
    },
    {
      header: 'Description',
      cell: (row) => row.renderValue(),
      accessorKey: 'description',
      size: 600,
    },
    {
      id: 'edit',
      header: '',
      accessorFn: (row) => row,
      cell: ({ cell }) => {
        const row = cell.renderValue();
        return <EditComponent row={row as PrivilegeGraphql} />;
      },
      size: 50,
    },
  ];

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="flex flex-col">
          <SubHeadingComponent type={'primary'} size={'2xl'}>
            Privileges
          </SubHeadingComponent>

          <TextComponent size={'base'}>
            <span className="text-gray-500">Create and manage privileges.</span>
          </TextComponent>
        </div>
        <div className="flex flex-col justify-center">
          <NblocksButton
            size="md"
            className="justify-self-center"
            type="primary"
            onClick={() => didClickAddBtn()}
          >
            Create privilege
          </NblocksButton>
        </div>
      </div>
      <TableComponent
        columns={cols}
        data={data?.listPrivileges as PrivilegeGraphql[]}
        loading={isLoading}
      />
      <ModalComponent
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        heading={editPrivilege ? 'Edit privilege' : 'New privilege'}
      >
        {data && (
          <PrivilegeModalComponent
            didClickSaveEdit={(args) => didClickEditModalSave(args)}
            didClickSaveCreate={(args) => didClickCreateModalSave(args)}
            didClickCancel={() => setModalOpen(false)}
            editPrivilege={editPrivilege}
            allPrivileges={data.listPrivileges}
          />
        )}
      </ModalComponent>
    </div>
  );
};

export { PrivilegesComponent };
