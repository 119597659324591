import React, { FunctionComponent } from 'react';
import { CodeBlock } from 'src/components/shared/code/code-block';
import { OnboardingStepType } from '../onboarding.modal.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Trans } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { InlineCode } from 'src/components/shared/code/inline-code';
import { Config } from 'src/shared/Config';
import { TestStep } from './user-readiness-test.component';

const LoginRedirectStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const code = `package com.mycompany.app;

import java.io.IOException;

import javax.servlet.ServletException;
import javax.servlet.http.HttpServlet;
import javax.servlet.http.HttpServletRequest;
import javax.servlet.http.HttpServletResponse;

class LoginServlet extends HttpServlet {
    private static final String APP_ID = "${appId}";
    private static final String LOGIN_URL = "${Config.getOauthApiUrl()}/url/login/";

    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response)
            throws ServletException, IOException {
        response.sendRedirect(LOGIN_URL + APP_ID);
    }
}
`;

  return appId ? (
    <>
      <TextComponent>
        <Trans
          i18nKey="userReadinessJavaOnboardingLogin"
          defaults="Create a new class that we call <0>LoginServlet</0> and add this to the <1>/login</1> route. In Java, routes can be created in a Jetty web server."
          components={[
            <InlineCode>LoginServlet</InlineCode>,
            <InlineCode>/login</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={code} language="java" />
    </>
  ) : (
    <></>
  );
};

const CallbackStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const code = `package com.mycompany.app;

import java.io.IOException;
import java.net.URI;
import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;

import org.jose4j.jwt.consumer.InvalidJwtException;
import org.jose4j.jwt.consumer.JwtConsumer;
import org.jose4j.jwt.consumer.JwtConsumerBuilder;
import org.jose4j.keys.resolvers.HttpsJwksVerificationKeyResolver;
import org.json.JSONObject;
import org.jose4j.jwa.AlgorithmConstraints;
import org.jose4j.jwa.AlgorithmConstraints.ConstraintType;
import org.jose4j.jwk.HttpsJwks;
import org.jose4j.jws.AlgorithmIdentifiers;
import org.jose4j.jwt.JwtClaims;

import javax.servlet.ServletException;
import javax.servlet.http.HttpServlet;
import javax.servlet.http.HttpServletRequest;
import javax.servlet.http.HttpServletResponse;

public class CallbackServlet extends HttpServlet {

    // Replace this with your own APP ID
    private static final String APP_ID = "${appId}";
    private static final String JWKS_URL = "${Config.getOauthApiUrl()}/.well-known/jwks.json";
    private static final String TOKEN_URL = "${Config.getOauthApiUrl()}/token/code/" + APP_ID;

    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response)
            throws ServletException, IOException {
        String code = request.getParameter("code");

        JSONObject requestBody = new JSONObject().put("code", code);

        HttpRequest tokenRequest = HttpRequest.newBuilder()
                .uri(URI.create(TOKEN_URL))
                .header("Content-Type", "application/json")
                .POST(HttpRequest.BodyPublishers.ofString(requestBody.toString()))
                .build();

        HttpClient client = HttpClient.newHttpClient();

        try {
            // Get tokens
            HttpResponse<String> tokens = client.send(tokenRequest, HttpResponse.BodyHandlers.ofString());

            String responseBody = tokens.body();
            JSONObject responseJson = new JSONObject(responseBody);
            String accessToken = responseJson.getString("access_token");

            // Verify the tokens result using public keys from Nblocks JWKS
            HttpsJwks httpsJkws = new HttpsJwks(JWKS_URL);
            HttpsJwksVerificationKeyResolver httpsJwksKeyResolver = new HttpsJwksVerificationKeyResolver(httpsJkws);

            JwtConsumer jwtConsumer = new JwtConsumerBuilder()
                    .setVerificationKeyResolver(httpsJwksKeyResolver)
                    .setExpectedIssuer("${Config.getOauthApiUrl()}")
                    .setExpectedAudience(APP_ID)
                    .setJwsAlgorithmConstraints(new AlgorithmConstraints(ConstraintType.PERMIT,
                            AlgorithmIdentifiers.RSA_PSS_USING_SHA256))
                    .build();

            JwtClaims jwtClaims = jwtConsumer.processToClaims(accessToken);

            System.out.println("Token verified and processed: " + jwtClaims);

            // Return token to client
            response.setContentType("application/json");
            response.setCharacterEncoding("UTF-8");
            response.getWriter().write(accessToken);

        } catch (InterruptedException | InvalidJwtException e) {
            e.printStackTrace();
        }
    }
}
`;
  return appId ? (
    <>
      <TextComponent>
        <Trans
          i18nKey="userReadinessJavaOnboardingCallback"
          defaults="Create another class that we call <0>CallbackServlet</0> and add this to the <1>/auth/oauth-callback</1> route. In Java, routes can be created in a Jetty web server."
          components={[
            <InlineCode>CallbackServlet</InlineCode>,
            <InlineCode>/auth/oauth-callback</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={code} language="java" />
    </>
  ) : (
    <></>
  );
};

const UserReadinessJavaSteps: OnboardingStepType[] = [
  {
    label: 'Login redirect',
    component: LoginRedirectStep,
    nextButtonLabel: 'Next',
  },
  { label: 'Callback', component: CallbackStep, nextButtonLabel: 'Test' },
  {
    label: 'Test',
    component: TestStep,
    nextButtonLabel: 'See your new user!',
    onboardingMutationVariables: { userReadiness: { test: true } },
  },
];

export { UserReadinessJavaSteps };
