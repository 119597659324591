import React from 'react';
import { useQuery } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';

import {
  GetAppBillingProvidersDocument,
  GetAppBillingProvidersQuery,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { StripeToggleCard } from './toggle-cards/stripe-card.component';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';

export interface PaymentProviderCardProps {
  data: GetAppBillingProvidersQuery;
}

const ProvidersComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const { data, loading } = useQuery(GetAppBillingProvidersDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="flex flex-col">
          <SubHeadingComponent type={'primary'} size={'2xl'}>
            Payment providers
          </SubHeadingComponent>

          <TextComponent size={'base'} colorName="text-gray-500">
            {t('Connect and manage payment providers.')}
          </TextComponent>
        </div>
      </div>
      <div className="space-y-6">
        <div className="rounded-lg border border-gray-300 bg-white shadow p-6 grid grid-cols-2 gap-6">
          {loading && (
            <>
              <SkeletonLoader className="h-24 w-full rounded-md" />
            </>
          )}
          {data && (
            <>
              <StripeToggleCard data={data} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { ProvidersComponent };
