import { Disclosure } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  BookOpenIcon,
  LifebuoyIcon,
  UserCircleIcon,
  UsersIcon,
  ArrowUturnLeftIcon,
  BanknotesIcon,
} from '@heroicons/react/24/outline';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { useMe } from 'src/shared/hooks/use-me';
import {
  OptionsComponent,
  Selectable,
} from './shared/options/options.component';
import { AppLightGraphql } from 'src/gql/graphql';
import { useApp } from '@nebulr-group/nblocks-react';
import { MenuOptionsComponent } from './shared/options/menu-options.component';
import { Item } from 'src/screens/app/app.screen';
import { useTranslation } from 'react-i18next';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';
import { FeatureFlagComponent } from 'src/components/shared/feature-flag/feature-flag.component';
import { useFlags } from 'src/shared/hooks/use-flags';
import { useEffect } from 'react';
import { useSeats } from 'src/shared/hooks/use-seats';
import { FlagKeys } from 'src/shared/flag-keys';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const AppTopbarComponent = ({
  menuItems,
  outsideAppContext,
}: {
  menuItems: Item[];
  outsideAppContext?: boolean;
}) => {
  const { t } = useTranslation();
  const { app, apps } = useCurrentApp();
  const { loading, seats, startPolling } = useSeats();
  const { logo } = useApp();
  const { profile } = useMe();
  const navigate = useNavigate();
  const { flagEnabled, setContext } = useFlags();
  const location = useLocation();
  const paths = location.pathname.split('/');
  const isActiveItem = (item: Item) => {
    return item.active ? paths.includes(item.active) : false;
  };

  // Set custom context for feature flags
  useEffect(() => {
    if (!loading && seats) {
      setContext({
        custom: {
          numApps: seats.numApps.toString(),
          numUsers: seats.numUsers.toString(),
        },
      });
      startPolling(5 * 60 * 1000);
    }
  }, [seats]);

  const makeSelectable = (app: AppLightGraphql): Selectable => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { value: app.id, label: app.name! };
  };

  const onDidSwitchApp = (option: Selectable) => {
    navigate(`/app/${option.value}`);
  };

  const renderAppDropdown = () => {
    if (outsideAppContext) {
      return (
        <LinkComponent to="/" type="primary">
          <div className="flex gap-x-3">
            <div
              className={'text-gray-400 h-6 w-6 shrink-0'}
              aria-hidden="true"
            >
              <ArrowUturnLeftIcon />
            </div>
            {t('Back to apps')}
          </div>
        </LinkComponent>
      );
    }

    const addOptions = flagEnabled(FlagKeys.CREATE_APP_BTN)
      ? {
          label: t('+ Create new app'),
          onAdd: () => navigate(`/create-app`),
        }
      : undefined;

    if (apps.length > 0) {
      const selectables: Selectable[] = [...apps.map((a) => makeSelectable(a))];
      const selected = selectables.find((a) => a.value === app?.id);
      return (
        <div className="w-full">
          <OptionsComponent
            data={selectables}
            init={selected}
            didSelect={onDidSwitchApp}
            addOptions={addOptions}
            noBorder
          />
        </div>
      );
    } else
      return (
        <HeadingComponent type={'h2'} size={'xl'} className={'mt-5'}>
          {app?.name}
        </HeadingComponent>
      );
  };

  const renderTeamsButton = () => {
    return (
      <li key={'team'} className="flex">
        <a
          href={'/team'}
          className={
            'text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
          }
        >
          <div className={'text-gray-400 h-6 w-6 shrink-0'} aria-hidden="true">
            <UsersIcon />
          </div>
          {t('Team')}
        </a>
      </li>
    );
  };

  const userLinks: Item[] = [];
  if (flagEnabled(FlagKeys.SUBSCRIPTION_BTN)) {
    userLinks.push({
      name: t('Subscription'),
      href: '/subscription',
      icon: <BanknotesIcon />,
    });
  }
  userLinks.push({
    name: `Logout ${profile ? profile.givenName : ''}`,
    href: '/auth/logout',
    icon: <ArrowRightOnRectangleIcon />,
  });

  const supportLinks: Item[] = [
    {
      name: 'Docs',
      href: 'https://nebulr-group.github.io/nblocks-docs',
      icon: <BookOpenIcon />,
    },
    {
      name: 'Support',
      href: 'mailto:support@nblocks.dev',
      icon: <LifebuoyIcon />,
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b shadow-sm bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex gap-x-3">
                    <div className="flex flex-shrink-0 items-center pl-2">
                      {logo && (
                        <LinkComponent to="/" type="primary">
                          <img
                            className="h-8 w-auto"
                            src={logo}
                            alt="Nblocks"
                          />
                        </LinkComponent>
                      )}
                    </div>
                    <div className="flex items-center">
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                    </div>
                    <div className="w-60">
                      <div className="flex grow flex-col">
                        <div className="flex h-16 shrink-0 items-center">
                          {renderAppDropdown()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-sm:hidden sm:ml-6 sm:flex sm:visible sm:items-center gap-x-3">
                    <ul role="list" className="space-y-1 flex gap-x-3">
                      {supportLinks.map((item) => (
                        <li key={item.name} className="flex">
                          <a
                            href={item.href}
                            className={
                              'text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            }
                            target="_blank"
                          >
                            <div
                              className={'text-gray-400 h-6 w-6 shrink-0'}
                              aria-hidden="true"
                            >
                              {item.icon}
                            </div>
                            {item.name}
                          </a>
                        </li>
                      ))}
                      <FeatureFlagComponent flagKey={FlagKeys.TEAM_BTN}>
                        <>{renderTeamsButton()}</>
                      </FeatureFlagComponent>
                    </ul>
                    {/* Profile dropdown */}
                    <MenuOptionsComponent
                      menuOptionsButton={<UserCircleIcon />}
                      menuOptionsButtonClassName={
                        'text-gray-600 group-hover:text-gray-900 h-8 w-8 rounded-full'
                      }
                      menuOptionsItems={userLinks}
                    />
                  </div>
                  <div className="-mr-2 max-sm:flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
              {/* Mobile menu */}
              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pb-3 pt-2">
                  <ul role="list" className="space-y-1">
                    {menuItems.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            isActiveItem(item)
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          )}
                        >
                          <div
                            className={classNames(
                              isActiveItem(item)
                                ? 'text-indigo-600'
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          >
                            {item.icon}
                          </div>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center max-sm:px-2 sm:px-4">
                    <div className="flex-shrink-0">
                      <UserCircleIcon className="text-gray-400 group-hover:text-indigo-600 h-8 w-8 rounded-full" />
                    </div>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userLinks.map((item) => (
                      <li key={item.name} className="flex">
                        <a
                          href={item.href}
                          className={classNames(
                            isActiveItem(item)
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'w-full group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          )}
                        >
                          <div
                            className={classNames(
                              isActiveItem(item)
                                ? 'text-indigo-600'
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          >
                            {item.icon}
                          </div>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};

export { AppTopbarComponent };
