import { useCurrentApp } from './use-current-app';
import { GetAppPlansDocument } from 'src/gql/graphql';
import { useQuery } from '@apollo/client';

const useListCurrentPlans = () => {
  const { appId } = useCurrentApp();

  const { data, loading, error } = useQuery(GetAppPlansDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });

  return { data, loading, error };
};

export { useListCurrentPlans };
