import React, { FunctionComponent, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { useTranslation } from 'react-i18next';
import { OnboardingStepType } from './onboarding.modal.component';
import { useMutation } from '@apollo/client';
import { UpdateAppOnboardingDocument } from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { StepsComponent, Step } from '../shared/steps/steps.component';
import { OnboardingTestCompleted } from './onboarding-test-completed.component';

const OnboardingStepsComponent: FunctionComponent<{
  steps: OnboardingStepType[];
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  componentSteps: Step[];
  setComponentSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  didFinishOnboarding: () => void;
  isTestPending: boolean;
  pendingLabel: string;
}> = ({
  steps,
  currentIndex,
  setCurrentIndex,
  componentSteps,
  setComponentSteps,
  didFinishOnboarding,
  isTestPending,
  pendingLabel,
}) => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const [updateOnboardingMutation] = useMutation(UpdateAppOnboardingDocument);

  const goToStep = async (index: number) => {
    const onboardingVariables = steps[index].onboardingMutationVariables;
    if (appId && onboardingVariables)
      await updateOnboardingMutation({
        variables: {
          appId: appId,
          onboarding: onboardingVariables,
        },
        refetchQueries: ['GetAppOnboarding'],
      });
    const newSteps = componentSteps.map((step, stepIndex) => {
      return {
        ...step,
        current: stepIndex === index,
      };
    });
    setComponentSteps(newSteps);
    setCurrentIndex(index);
  };

  // Only apply the pending state to the button on the test page,
  // as evaluated by looking at the mutation variables
  const isTestStep: boolean = Object.values(
    steps[currentIndex]?.onboardingMutationVariables || {},
  ).some((variable) => variable?.test);

  const isTestStepAndPending: boolean = isTestStep && isTestPending;
  const isTestStepNotPending = isTestStep && !isTestPending;

  const nextStepLabel = (): string => {
    if (isTestStepAndPending) {
      return pendingLabel;
    } else {
      return t(steps[currentIndex].nextButtonLabel);
    }
  };

  // Function to go to the next component
  const nextStep = async () => {
    if (appId && currentIndex === steps.length - 1) {
      didFinishOnboarding();
      return;
    } else {
      const onboardingVariables =
        steps[currentIndex + 1].onboardingMutationVariables;
      if (appId && onboardingVariables)
        await updateOnboardingMutation({
          variables: {
            appId: appId,
            onboarding: onboardingVariables,
          },
          refetchQueries: ['GetAppOnboarding'],
        });
      setCurrentIndex((prevIndex) => {
        if (prevIndex + 1 < steps.length) {
          const newIndex = prevIndex + 1;
          const newSteps = componentSteps.map((step, stepIndex) => {
            return stepIndex === prevIndex
              ? {
                  ...step,
                  current: false,
                  completed: stepIndex === prevIndex,
                }
              : {
                  ...step,
                  current: stepIndex === newIndex,
                };
          });
          setComponentSteps(newSteps);
          return newIndex;
        }
        return prevIndex;
      });
    }
  };

  // Function to go to the previous component
  const previousStep = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex - 1 >= 0) {
        const newIndex = prevIndex - 1;
        const newSteps = componentSteps.map((step, stepIndex) => {
          return {
            ...step,
            current: stepIndex === newIndex,
          };
        });
        setComponentSteps(newSteps);
        return newIndex;
      }
      return prevIndex;
    });
  };

  // Get the current component based on the currentIndex
  const CurrentComponent = isTestStepNotPending
    ? OnboardingTestCompleted
    : steps[currentIndex].component;
  const isManySteps = steps.length > 1;

  return (
    <div>
      {isManySteps && (
        <div className="flex items-center justify-center border-b border-gray-200 py-2">
          <StepsComponent steps={componentSteps} onStepClick={goToStep} />
        </div>
      )}
      <div
        style={{ height: '50vh' }}
        className="overflow-y-scroll space-y-6 pt-4"
      >
        <CurrentComponent />
      </div>
      <div
        className={`flex flex-col-reverse md:flex-row ${
          isManySteps ? 'md:justify-between' : 'md:justify-end'
        }  mt-5 gap-3`}
      >
        {isManySteps && (
          <NblocksButton
            size="md"
            type="tertiary"
            disabled={currentIndex === 0}
            onClick={previousStep}
          >
            {t('Back')}
          </NblocksButton>
        )}
        <NblocksButton
          size="md"
          type={isManySteps ? 'primary' : 'tertiary'}
          onClick={nextStep}
          isLoading={isTestStepAndPending}
          disabled={isTestStepAndPending}
        >
          {nextStepLabel()}
        </NblocksButton>
      </div>
    </div>
  );
};

export { OnboardingStepsComponent };
