import React from 'react';
import { useQuery } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';

import {
  GetAppAuthProviderStateDocument,
  GetAppAuthProviderStateQuery,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { MicrosoftADToggleCard } from './toggle-cards/ms-card.component';
import { GoogleToggleCard } from './toggle-cards/google-card.component';
import { LinkedinToggleCard } from './toggle-cards/linkedin-card.component';
import { GithubToggleCard } from './toggle-cards/github-card.component';
import { FacebookToggleCard } from './toggle-cards/facebook-card.component';
import { AppleToggleCard } from './toggle-cards/apple-card.component';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';

export interface SocialProviderCardProps {
  data: GetAppAuthProviderStateQuery;
}

const SocialConnectionsComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const { data, loading } = useQuery(GetAppAuthProviderStateDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="flex flex-col">
          <SubHeadingComponent type={'primary'} size={'2xl'}>
            {t('Social connection providers')}
          </SubHeadingComponent>

          <TextComponent size={'base'} colorName="text-gray-500">
            {t(
              'Enable connections between your application and third-party providers',
            )}
          </TextComponent>
        </div>
      </div>
      <div className="space-y-6">
        <div className="rounded-lg border border-gray-300 bg-white shadow p-6 grid grid-cols-2 gap-6">
          {loading && (
            <>
              <SkeletonLoader className="h-24 w-full rounded-md" />
              <SkeletonLoader className="h-24 w-full rounded-md" />
              <SkeletonLoader className="h-24 w-full rounded-md" />
              <SkeletonLoader className="h-24 w-full rounded-md" />
              <SkeletonLoader className="h-24 w-full rounded-md" />
              <SkeletonLoader className="h-24 w-full rounded-md" />
            </>
          )}
          {data && (
            <>
              <GoogleToggleCard data={data} />
              <MicrosoftADToggleCard data={data} />
              <LinkedinToggleCard data={data} />
              {/* <AppleToggleCard data={data} /> */}
              <GithubToggleCard data={data} />
              <FacebookToggleCard data={data} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { SocialConnectionsComponent };
