import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { useQuery } from '@apollo/client';
import { GetAppSecurityConfigsDocument } from 'src/gql/graphql';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';

const TestStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const { data } = useQuery(GetAppSecurityConfigsDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });

  const callbackUri = data?.getApp.defaultCallbackUri || '';
  const appUrl = callbackUri.split('/auth/oauth-callback')[0]; //FIXME a better solution is to use uiUrl & apiUrl from app profile
  const loginUrl = `${appUrl}/login`;

  return (
    <div className="w-full h-full flex flex-col items-left space-y-6">
      <div className="space-y-4">
        <HeadingComponent type={'h2'} size={'xl'}>
          1. {t('Start your app')}
        </HeadingComponent>
        <TextComponent>
          <Trans
            i18nKey="userReadinessTestOnboardingStartApp"
            defaults={`Start your application so that it is accessible on <0>${appUrl}</0>`}
            components={[
              <LinkComponent
                type={'primary'}
                to={appUrl}
                nativeBehavior={true}
                target="_blank"
              >
                {appUrl}
              </LinkComponent>,
            ]}
          />
        </TextComponent>
      </div>
      <div className="space-y-4">
        <HeadingComponent type={'h2'} size={'xl'}>
          2. {t('Navigate to /login and get redirected to Nblocks Login')}
        </HeadingComponent>
        <TextComponent>
          <Trans
            i18nKey="userReadinessTestOnboardingLogin"
            defaults={`Open a browser tab and navigate to your app's login URL <0>${loginUrl}</0>`}
            components={[
              <LinkComponent
                type={'primary'}
                to={loginUrl}
                nativeBehavior={true}
                target="_blank"
              >
                {loginUrl}
              </LinkComponent>,
            ]}
          />
        </TextComponent>
        <TextComponent>
          {t('Your new code redirects the user to Nblocks Login.')}
        </TextComponent>
      </div>
      <div className="space-y-4">
        <HeadingComponent type={'h2'} size={'xl'}>
          3. {t('Sign up as a new user and return back to your app')}
        </HeadingComponent>
        <TextComponent>
          {t(
            'Click "Sign up" button on the bottom of the login screen, and you will be redirected to the sign up screen.',
          )}
        </TextComponent>
        <TextComponent>
          {t(
            "After sign up, you'll be redirected back to your application as a logged in user. Check your console!",
          )}
        </TextComponent>
        <TextComponent>
          {t('Your users and workspaces are shown here in the dashboard')}
        </TextComponent>
      </div>
    </div>
  );
};

export { TestStep };
