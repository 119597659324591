import React, { FunctionComponent, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import {
  CreateAppTaxDocument,
  CreateTaxGraphqlInput,
  DeleteAppTaxDocument,
  GetAppTaxesDocument,
  TaxGraphql,
  UpdateAppTaxDocument,
  UpdateTaxGraphqlInput,
} from 'src/gql/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SaveArgs } from './tax.modal.component';
import { ApolloUtils } from 'src/shared/apollo-utils';
import { TaxModalComponent } from './tax.modal.component';
import { supportedCountries } from './country-input.component';
import { TableComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TableComponent';
import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { DateTimeComponent } from '../shared/date/date-time-component';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const TaxesComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editTax, setEditTax] = useState<TaxGraphql>();
  const { t } = useTranslation();
  const { setSuccessMessage } = useFeedback();
  const { appId } = useCurrentApp();
  const { data, loading } = useQuery(GetAppTaxesDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });

  const [updateAppTaxMutation, updateAppTaxData] =
    useMutation(UpdateAppTaxDocument);
  const [createAppTaxMutation, createAppTaxData] =
    useMutation(CreateAppTaxDocument);
  const [deleteAppTaxMutation, deleteAppTaxData] =
    useMutation(DeleteAppTaxDocument);

  const isLoading =
    loading ||
    updateAppTaxData.loading ||
    createAppTaxData.loading ||
    deleteAppTaxData.loading;

  const onDidClickDelete = async (row: TaxGraphql) => {
    if (appId && row.id) {
      await deleteAppTaxMutation({
        variables: { appId, id: row.id },
        refetchQueries: [GetAppTaxesDocument],
      });
      setSuccessMessage('The tax was deleted!');
    }
  };

  const didClickCreateBtn = () => {
    setEditTax(undefined);
    setModalOpen(true);
  };

  const didClickEditBtn = (row: TaxGraphql) => {
    setEditTax(row);
    setModalOpen(true);
  };

  const didClickModalSave = async (args: SaveArgs) => {
    if (appId) {
      if (editTax) {
        const tax = ApolloUtils.removeTypeName<UpdateTaxGraphqlInput>(args);
        await updateAppTaxMutation({
          variables: {
            appId,
            id: editTax.id,
            tax,
          },
          refetchQueries: [GetAppTaxesDocument],
        });
        setSuccessMessage('The tax was updated!');
      } else {
        const tax = ApolloUtils.removeTypeName<CreateTaxGraphqlInput>(args);
        await createAppTaxMutation({
          variables: {
            appId,
            tax,
          },
          refetchQueries: [GetAppTaxesDocument],
        });
        setSuccessMessage('A new tax was created!');
      }
    }

    setModalOpen(false);
  };

  const renderCountryCode = (countryCode: string) => {
    const country = supportedCountries.find((sc) => sc.value === countryCode);
    return (
      <TextComponent>
        {country ? `${country.value} (${country.label})` : countryCode}
      </TextComponent>
    );
  };

  const EditComponent: FunctionComponent<{ row: TaxGraphql }> = ({ row }) => {
    const options: Option[] = [
      {
        label: 'Edit',
        onClick: () => {
          didClickEditBtn(row);
        },
        labelPosition: 'start',
      },
      {
        label: 'Delete',
        type: 'danger',
        onClick: () => {
          onDidClickDelete(row);
        },
        labelPosition: 'start',
      },
    ];

    return (
      <div className="flex items-center justify-end">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  const cols: ColumnDef<TaxGraphql>[] = [
    {
      header: t('Name'),
      cell: (cell) => (
        <TextComponent>{cell.renderValue() as string}</TextComponent>
      ),
      accessorKey: 'name',
      size: 150,
    },
    {
      header: t('Country code'),
      cell: (cell) => renderCountryCode(cell.renderValue() as string),
      accessorKey: 'countryCode',
      size: 150,
    },
    {
      header: t('Rate'),
      cell: (cell) => (
        <ChipComponent type={'primary'}>
          {cell.renderValue() as number}%
        </ChipComponent>
      ),
      accessorKey: 'percentage',
      size: 200,
    },
    {
      header: t('Created'),
      cell: (cell) => (
        <DateTimeComponent value={cell.renderValue() as string} />
      ),
      accessorKey: 'createdAt',
      size: 200,
    },
    {
      id: 'edit',
      header: '',
      cell: ({ row }) => {
        return <EditComponent row={row.original} />;
      },
      size: 100,
    },
  ];

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="flex flex-col">
          <SubHeadingComponent type={'primary'} size={'2xl'}>
            {t('Taxes')}
          </SubHeadingComponent>

          <TextComponent size={'base'} colorName="text-gray-500">
            {t('Create and manage taxes')}
          </TextComponent>
        </div>
        <div className="flex flex-col justify-center">
          <NblocksButton
            size="md"
            className="justify-self-center"
            type="primary"
            onClick={() => didClickCreateBtn()}
          >
            {t('Create tax')}
          </NblocksButton>
        </div>
      </div>
      <TableComponent
        columns={cols}
        data={data?.getAppTaxes}
        loading={isLoading}
        defaultPageSize={10}
      />
      <ModalComponent
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        heading={editTax ? 'Edit tax' : 'New tax'}
      >
        <TaxModalComponent
          didClickSave={(args) => didClickModalSave(args)}
          didClickCancel={() => setModalOpen(false)}
          editTax={editTax}
        />
      </ModalComponent>
    </div>
  );
};

export { TaxesComponent };
