import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';

import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { TogglerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TogglerComponent';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import { useTranslation } from 'react-i18next';

import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { LoadingSpinner } from '../spinner/loading-spinner.component';

// Should be exported by plugin
export type LabelType = {
  position: 'left' | 'right';
  src?: string;
  node?: React.ReactNode;
  srcset?: string;
  alt: string;
};

export interface InputFieldProps {
  type: 'password' | 'text';
  placeholder: string;
  label: string;
  caption: string;
  field: string;
  value: string;
  errorLabel?: LabelType;
  inputError?: boolean;
}

interface ModalProps {
  enableModalHeading: string;
  editModalHeading: string;
  modalDescription: ReactElement;
  modalFooter?: ReactElement;
  deleteModalHeading: string;
  deleteModalPrompt: string;
  credentialsAdded: boolean | null | undefined;
  didClickModalSave: () => Promise<void> | void;
  onModalChange: (field: string, value: string) => void;
  onDelete: () => void;
  modalInputFields: InputFieldProps[];
  modalValid: boolean;
}

export interface ConfigCardsComponentProps {
  logo?: ReactNode;
  label: string;
  description: string;
  isEnabled: boolean | null | undefined;
  onEnabledChange: (enabled: boolean) => Promise<void> | void;
  isLoading: boolean;
  modalProps?: ModalProps;
}

const ToggleCardComponent = ({
  logo,
  label,
  description,
  isEnabled,
  onEnabledChange,
  isLoading,
  modalProps,
}: ConfigCardsComponentProps) => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(!!isEnabled);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleEnable = async (enabledState: boolean) => {
    if (enabledState) {
      if (modalProps && !modalProps.credentialsAdded) setShowConfigModal(true);
      else {
        setEnabled(true);
        await onEnabledChange(enabledState);
      }
    } else {
      setEnabled(false);
      await onEnabledChange(enabledState);
    }
  };

  const onDidClickSave = async () => {
    if (modalProps) {
      await modalProps.didClickModalSave();
      if (!modalProps.credentialsAdded) {
        await onEnabledChange(true);
        setEnabled(true);
      }
    }
    setShowConfigModal(false);
  };

  const onDidClickDelete = () => {
    if (modalProps) modalProps.onDelete();
    setEnabled(false);
    setShowDeleteModal(false);
  };

  const EditComponent: FunctionComponent = () => {
    const options: Option[] = [
      {
        label: modalProps?.credentialsAdded ? 'Edit' : 'Enable',
        onClick: () => {
          setShowConfigModal(true);
        },
        labelPosition: 'start',
      },
    ];

    if (modalProps?.credentialsAdded)
      options.push({
        label: 'Delete',
        type: 'danger',
        onClick: () => {
          setShowDeleteModal(true);
        },
        labelPosition: 'start',
      });

    return (
      <div className="flex items-center justify-end">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  return (
    <>
      <div className="rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            {logo && (
              <div className="w-10 h-10 mr-5 rounded-lg overflow-hidden">
                {logo}
              </div>
            )}
            <div>
              <TextComponent>{label}</TextComponent>
              <TextComponent size="sm" colorName="text-gray-500">
                {description}
              </TextComponent>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            {isLoading ? (
              <LoadingSpinner className="w-8 h-8 mr-2 text-white" />
            ) : (
              <TogglerComponent
                enabled={enabled}
                setEnabled={(value) => handleEnable(value as boolean)}
              />
            )}
            {modalProps && <EditComponent />}
          </div>
        </div>
      </div>
      {modalProps && (
        <ModalComponent
          isOpen={showConfigModal}
          setIsOpen={setShowConfigModal}
          heading={
            modalProps.credentialsAdded
              ? modalProps.editModalHeading
              : modalProps.enableModalHeading
          }
        >
          <div>
            <div className="space-y-2">
              <div>{modalProps.modalDescription}</div>
              <form
                onSubmit={() => onDidClickSave()}
                className="space-y-6 max-w-sm w-full"
              >
                {modalProps.modalInputFields.map((inputField, index) => (
                  <InputComponent
                    caption={
                      <TextComponent size="sm" colorName="text-gray-500">
                        {inputField.caption}
                      </TextComponent>
                    }
                    errorLabel={inputField.errorLabel}
                    inputError={inputField.inputError}
                    type={inputField.type}
                    label={inputField.label}
                    placeholder={
                      modalProps.credentialsAdded
                        ? '*********************'
                        : inputField.placeholder
                    }
                    name={inputField.field}
                    onChange={(event) =>
                      modalProps.onModalChange(
                        inputField.field,
                        event.target.value,
                      )
                    }
                    value={inputField.value}
                    key={`modal-input-${index}`}
                  />
                ))}
              </form>
              {modalProps.modalFooter && <div>{modalProps.modalFooter}</div>}
            </div>
            <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
              <NblocksButton
                size="md"
                className="w-full"
                type="tertiary"
                onClick={() => setShowConfigModal(false)}
              >
                {t('Cancel')}
              </NblocksButton>
              <NblocksButton
                size="md"
                className="w-full"
                type="primary"
                isLoading={isLoading}
                onClick={() => onDidClickSave()}
                disabled={!modalProps.modalValid || isLoading}
              >
                {modalProps.credentialsAdded ? t('Update') : t('Save')}
              </NblocksButton>
            </div>
          </div>
        </ModalComponent>
      )}
      {modalProps && (
        <ModalComponent
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          heading={modalProps.deleteModalHeading}
          iconType="warning"
        >
          <div>
            <div>
              <TextComponent size="sm" colorName="text-gray-500">
                {modalProps?.deleteModalPrompt}
              </TextComponent>
            </div>
            <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
              <NblocksButton
                size="md"
                className="w-full"
                type="tertiary"
                onClick={() => setShowDeleteModal(false)}
              >
                {t('Cancel')}
              </NblocksButton>
              <NblocksButton
                size="md"
                className="w-full"
                type="danger"
                onClick={() => onDidClickDelete()}
              >
                {t('Delete')}
              </NblocksButton>
            </div>
          </div>
        </ModalComponent>
      )}
    </>
  );
};

export { ToggleCardComponent };
