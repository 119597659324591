import React from 'react';

const SafeTenantName = (name?: string | null) => {
  return name ? name : 'Unamed workspace';
};

const SafeTenantNameComponent = ({ name }: { name?: string | null }) => {
  return <>{SafeTenantName(name)}</>;
};

export { SafeTenantNameComponent, SafeTenantName };
