import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import React, { useState } from 'react';
import { PaymentsBreadcrumpsComponent } from 'src/components/payments/breadcrumps/payments-breadcrumps-component';
import { PlansComponent } from 'src/components/payments/plans.component';
import { ProvidersComponent } from 'src/components/payments/providers.component';
import { SettingsComponent } from 'src/components/payments/settings.component';
import { TaxesComponent } from 'src/components/payments/taxes.component';
import { TabItem, Tabs } from 'src/components/shared/tabs/tabs';

const AppPaymentsScreen = () => {
  const tabs: TabItem[] = [
    { name: 'Plans', id: 'plans' },
    { name: 'Taxes', id: 'taxes' },
    { name: 'Providers', id: 'providers' },
    { name: 'Settings', id: 'settings' },
  ];

  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs[0]);

  const onDidClickTab = (tab: TabItem) => {
    setSelectedTab(tab);
  };

  const renderSelectedTab = () => {
    switch (selectedTab.id) {
      case 'providers':
        return <ProvidersComponent />;
      case 'taxes':
        return <TaxesComponent />;
      case 'settings':
        return <SettingsComponent />;
      case 'plans':
      default:
        return <PlansComponent />;
    }
  };

  return (
    <div className="space-y-6">
      <PaymentsBreadcrumpsComponent selectedTab={selectedTab} />
      <HeadingComponent type={'h1'} size={'2xl'} className={'mt-5'}>
        Subscription Control
      </HeadingComponent>
      <Tabs tabs={tabs} didClickTab={(tab) => onDidClickTab(tab)} />
      {renderSelectedTab()}
    </div>
  );
};

export { AppPaymentsScreen };
