import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';

import {
  AppConfig,
  AppConfigGraphqlInput,
  GetAppPropertiesDocument,
  UpdateAppPropertiesDocument,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { useButtonSuccess } from 'src/shared/hooks/use-button-success';

const EmailCustomizationComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const { buttonSuccess, triggerButtonSuccess } = useButtonSuccess();

  const [currentAppProperties, setCurrentAppProperties] = useState<
    AppConfig | undefined
  >();
  const [appProperties, setAppProperties] = useState<
    AppConfigGraphqlInput | undefined
  >();

  const { data } = useQuery(GetAppPropertiesDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });

  const [updateAppProperties, updateAppPropertiesData] = useMutation(
    UpdateAppPropertiesDocument,
  );

  const didClickSave = async () => {
    if (appId) {
      updateAppProperties({
        variables: { id: appId, app: { ...appProperties } },
      });
      triggerButtonSuccess();
    }
  };

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line
      const { __typename, ...appPropertiesData } = data.getApp;
      setCurrentAppProperties({ ...appPropertiesData });
    }
  }, [data]);

  return (
    <>
      <div>
        <div className="my-6 flex justify-between">
          <div className="flex flex-col">
            <SubHeadingComponent type={'primary'} size={'2xl'}>
              {t('Email settings')}
            </SubHeadingComponent>

            <TextComponent size={'base'} colorName="text-gray-500">
              {t('Configure your email settings')}
            </TextComponent>
          </div>
        </div>
        {currentAppProperties && appId && (
          <div className="space-y-6">
            <div className="rounded-lg border border-gray-300 bg-white shadow p-6">
              <div className="flex flex-col w-2/5">
                <div className="w-4/5 my-2">
                  <InputComponent
                    label={t('Email sender name')}
                    type={'text'}
                    value={
                      appProperties?.emailSenderName ??
                      currentAppProperties.emailSenderName ??
                      ''
                    }
                    onChange={(event) =>
                      setAppProperties({
                        ...appProperties,
                        emailSenderName: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-4/5 my-2">
                  <InputComponent
                    label={t('Email sender email')}
                    type={'text'}
                    value={
                      appProperties?.emailSenderEmail ??
                      currentAppProperties.emailSenderEmail ??
                      ''
                    }
                    onChange={(event) =>
                      setAppProperties({
                        ...appProperties,
                        emailSenderEmail: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-1/5 my-2">
                  <NblocksButton
                    size="sm"
                    className="h-12"
                    type={buttonSuccess ? 'success' : 'primary'}
                    isLoading={updateAppPropertiesData.loading}
                    onClick={() => didClickSave()}
                  >
                    {buttonSuccess ? t('Saved!') : t('Save')}
                  </NblocksButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { EmailCustomizationComponent };
