import React, { useEffect, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { CheckboxComponent } from '@nebulr-group/nblocks-react/lib/components/shared/CheckboxComponent';
import { useTranslation } from 'react-i18next';
import {
  MultiOptions,
  Selectable,
} from '../shared/options/multi-options.component';
import {
  CreateRoleGraphqlInput,
  ListPrivilegesDocument,
  PrivilegeGraphql,
  RoleGraphql,
  RoleGraphqlInput,
} from 'src/gql/graphql';
import { useQuery } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { Util } from 'src/shared/util';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';

export interface ComponentProps {
  didClickSaveEdit: (args: RoleGraphqlInput) => void;
  didClickSaveCreate: (args: CreateRoleGraphqlInput) => void;
  didClickCancel: () => void;
  editRole?: RoleGraphql;
  allRoles: RoleGraphql[];
}

const RoleModalComponent = ({
  didClickSaveEdit,
  didClickSaveCreate,
  didClickCancel,
  editRole,
  allRoles,
}: ComponentProps) => {
  const { t } = useTranslation();
  const [key, setKey] = useState('');
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [uniquenessViolation, setUniquenessViolation] =
    useState<boolean>(false);
  const [privileges, setPrivileges] = useState<string[]>([]);
  const [isDefault, setIsDefault] = useState<boolean>(false);

  const { appId } = useCurrentApp();
  const { data, loading } = useQuery(ListPrivilegesDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });

  useEffect(() => {
    if (editRole) {
      setKey(editRole.key);
      setDescription(editRole.description ? editRole.description : '');
      setName(editRole.name);
      setPrivileges(editRole.privileges.map((p: PrivilegeGraphql) => p.id));
      setIsDefault(!!editRole.isDefault);
    }
  }, [editRole]);

  const onRoleKeyChange = (key: string) => {
    const allRoleKeys = allRoles.map((r) => r.key);
    setUniquenessViolation(allRoleKeys.includes(key));
    setKey(key);
  };

  const onDidClickSave = () => {
    if (editRole) {
      didClickSaveEdit({
        key,
        description,
        name,
        privileges,
        isDefault,
      });
    } else {
      didClickSaveCreate({
        key,
        description,
        name,
        privileges,
        isDefault,
      });
    }
  };

  const formValid =
    key && !uniquenessViolation && name && privileges.length > 0;

  const listPrivileges: Selectable[] = data
    ? data.listPrivileges.map((p) => {
        return { label: p.key, value: p.id };
      })
    : [];

  const selectedPrivileges: Selectable[] = listPrivileges.filter((p) =>
    privileges.includes(p.value),
  );

  const onDidSelectPrivileges = (selectables: Selectable[]) => {
    setPrivileges(selectables.map((s) => s.value));
  };

  return (
    <div>
      <div>
        <form
          onSubmit={() => onDidClickSave()}
          className="space-y-6 max-w-sm w-full"
        >
          {key === 'OWNER' && (
            <TextComponent size={'base'} colorName="text-gray-500">
              {t('This role is required to be present')}
            </TextComponent>
          )}
          <InputComponent
            type="text"
            label="Name*"
            placeholder="E.g. Admin"
            name="name"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
          <InputComponent
            type="text"
            label="Role key*"
            placeholder="E.g. ADMIN"
            name="id"
            disabled={key === 'OWNER'}
            onChange={(event) =>
              onRoleKeyChange(Util.cleanKeyInput(event.target.value, true))
            }
            errorLabel={{
              position: 'right',
              alt: 'Input error: This key already exists',
              node: 'This key already exists',
            }}
            inputError={uniquenessViolation}
            value={key}
          />
          <InputComponent
            type="text"
            label="Description (optional)"
            placeholder="E.g. This role gives admin privileges"
            name="description"
            onChange={(event) => setDescription(event.target.value)}
            value={description}
          />
          <CheckboxComponent
            name="default"
            label={'Make this role default'}
            onChange={(checked) => setIsDefault(checked)}
            checked={isDefault}
          />
          {loading && <SkeletonLoader className="h-12 w-full rounded-md" />}
          {data && (
            <MultiOptions
              label="Matching privileges"
              name="privileges"
              data={listPrivileges}
              init={selectedPrivileges}
              didSelect={(selectables) => onDidSelectPrivileges(selectables)}
            />
          )}
          <TextComponent size={'sm'} className="flex">
            Don’t see the privilege you need?
            <LinkComponent
              to={'../privileges'}
              type={'primary'}
              size={'sm'}
              className={'ml-1'}
              reloadDocument
            >
              Create a privilege
            </LinkComponent>
          </TextComponent>
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {'Cancel'}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSave()}
          disabled={!formValid}
        >
          {'Save changes'}
        </NblocksButton>
      </div>
    </div>
  );
};

export { RoleModalComponent };
