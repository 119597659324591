import React, { FC, ChangeEvent } from 'react';

type TextareaWithLabelProps = {
  label?: string;
  id: string;
  rows: number;
  value: string | null | undefined;
  onChange: (value: string) => void;
  showLabel?: boolean;
};

const TextareaComponent: FC<TextareaWithLabelProps> = ({
  label,
  id,
  rows,
  value,
  onChange,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="flex flex-col">
      {!!label && (
        <label htmlFor={id} className={`text-gray-900 customizable`}>
          {label}
        </label>
      )}
      <textarea
        rows={rows}
        name={id}
        id={id}
        className="block w-full h-full p-4 rounded-lg border border-gray-300 bg-white shadow text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
        value={value ?? ''}
        onChange={handleChange}
      />
    </div>
  );
};

export { TextareaComponent };
