import React, { FunctionComponent } from 'react';
import { CodeBlock } from 'src/components/shared/code/code-block';
import { OnboardingStepType } from '../onboarding.modal.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Trans } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { InlineCode } from 'src/components/shared/code/inline-code';
import { Config } from 'src/shared/Config';
import { TestStep } from './user-readiness-test.component';

const LoginRedirectStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const code = `@Component({
  template: '',
})
export class Login {
  constructor(
  ) {

    const APP_ID = "${appId}";

    // Immediately redirect the web browser to Nblocks login
    window.location.replace(\`${Config.getOauthApiUrl()}/url/login/\${APP_ID}\`);
  }
}
`;

  return appId ? (
    <>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingLogin"
          defaults="Create a new component that we call <0>Login</0> and add this to the <1>/login</1> route. In Angular routes can be created with the built in RouterModule."
          components={[
            <InlineCode>Login</InlineCode>,
            <InlineCode>/login</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={code} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const CallbackStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const code = `import { jwtVerify, createRemoteJWKSet } from 'jose';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  template: '<div>Loading...</div>',
})
export class Callback {

  const APP_ID = "${appId}";

  private accessToken: any;

  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient) {
    const code = this.activatedRoute.queryParams['code'];
    if (code) {
      this.handleCallback(this.activatedRoute.queryParams['code']);
    }
  }

  private handleCallback(code: string) {
    // Get tokens
    this.http
      .post(\`${Config.getOauthApiUrl()}/token/code/\${this.APP_ID}\`, {
        code: code,
      })
      .subscribe(async (tokens: any) => {
        // Verify the tokens result using public keys from Nblocks JWKS
        const { access_token, refresh_token, id_token } = tokens;
        const { payload } = await jwtVerify(
          access_token,
          createRemoteJWKSet(
            new URL('${Config.getOauthApiUrl()}/.well-known/jwks.json')
          ),
          { issuer: '${Config.getOauthApiUrl()}' }
        );

        // Store the result in component state and localstorage
        window.localStorage.setItem('access_token', access_token);
        window.localStorage.setItem('refresh_token', refresh_token);
        window.localStorage.setItem('id_token', id_token);
        this.accessToken = payload;
        console.log('User access token', payload);
      });
  }
}
`;
  return appId ? (
    <>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingCallback"
          defaults="Create a new component that we call <0>Callback</0> and add this to the <1>/auth/</1> route. In Angular routes can be created with the built in RouterModule."
          components={[
            <InlineCode>Callback</InlineCode>,
            <InlineCode>/auth/</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={code} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const UserReadinessAngularSteps: OnboardingStepType[] = [
  {
    label: 'Login redirect',
    component: LoginRedirectStep,
    nextButtonLabel: 'Next',
  },
  { label: 'Callback', component: CallbackStep, nextButtonLabel: 'Test' },
  {
    label: 'Test',
    component: TestStep,
    nextButtonLabel: 'See your new user!',
    onboardingMutationVariables: { userReadiness: { test: true } },
  },
];

export { UserReadinessAngularSteps };
