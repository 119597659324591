import React, { useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { TaxGraphql } from 'src/gql/graphql';
import {
  CountryInputComponent,
  supportedCountries,
} from './country-input.component';

export interface ComponentProps {
  didClickSave: (args: SaveArgs) => void;
  didClickCancel: () => void;
  editTax?: TaxGraphql;
}

export interface SaveArgs {
  name: string;
  countryCode: string;
  percentage: number;
}

const TaxModalComponent = ({
  didClickSave,
  didClickCancel,
  editTax,
}: ComponentProps) => {
  const [name, setName] = useState(editTax?.name || '');
  const [countryCode, setCountryCode] = useState(
    editTax?.countryCode || supportedCountries[0].value,
  );
  const [percentage, setPercentage] = useState<number | undefined>(
    editTax?.percentage || undefined,
  );

  const onDidClickSave = () => {
    didClickSave({
      name,
      countryCode,
      percentage: percentage || 0,
    });
  };

  const formValid = name && countryCode && percentage;

  return (
    <div>
      <div>
        <form
          onSubmit={() => onDidClickSave()}
          className="space-y-6 max-w-sm w-full"
        >
          <InputComponent
            type="text"
            label="Tax name*"
            placeholder="E.g. Example tax"
            name="name"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
          <CountryInputComponent
            didSelect={(args) => setCountryCode(args.value)}
            init={supportedCountries.find((c) => c.value === countryCode)}
          />
          <InputComponent
            type="number"
            label="Rate (%)*"
            placeholder="15"
            name="percentage"
            onChange={(event) =>
              event.target.value
                ? setPercentage(Number.parseInt(event.target.value))
                : setPercentage(undefined)
            }
            value={percentage}
          />
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {'Cancel'}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSave()}
          disabled={!formValid}
        >
          {'Save changes'}
        </NblocksButton>
      </div>
    </div>
  );
};

export { TaxModalComponent };
