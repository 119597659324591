import React from 'react';
import {
  OptionsComponent,
  Selectable,
} from 'src/components/shared/options/options.component';
import { useTranslation } from 'react-i18next';

// 'day' | 'month' | 'week' | 'year'
const supportedCountries: Selectable[] = [
  {
    label: 'Germany',
    value: 'DE',
  },
  {
    label: 'France',
    value: 'FR',
  },
  {
    label: 'United Kingdom',
    value: 'UK',
  },
  {
    label: 'Sweden',
    value: 'SE',
  },
  {
    label: 'Denmark',
    value: 'DK',
  },
  {
    label: 'Norway',
    value: 'NO',
  },
  {
    label: 'Finland',
    value: 'FI',
  },
];

export interface ComponentProps {
  didSelect: (args: Selectable) => void;
  init?: Selectable;
}

const CountryInputComponent = ({ didSelect, init }: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <OptionsComponent
      data={supportedCountries}
      init={init}
      label={t('Country*')}
      didSelect={didSelect}
    />
  );
};

export { CountryInputComponent, supportedCountries };
