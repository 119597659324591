import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  ConfigCardsComponentProps,
  ToggleCardComponent,
} from '../../shared/toggle-card/toggle-card.component';
import { LoginSettingsCardProps } from '../login.component';
import { PasskeyLogo } from '@nebulr-group/nblocks-react/lib/components/auth/shared/logos/passkey.logo';
import { UpdateAppEnabledAuthDocument } from 'src/gql/graphql';

const PasskeysToggleCard = ({ data }: LoginSettingsCardProps) => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const [updateEnabledAuthMutation, updateEnabledAuthData] = useMutation(
    UpdateAppEnabledAuthDocument,
  );

  const { data: updateData, loading } = updateEnabledAuthData;

  const onEnabledChange = async (enabled: boolean) => {
    await updateEnabledAuthMutation({
      variables: {
        id: appId!,
        app: {
          passkeysEnabled: enabled,
        },
      },
    });
  };

  const cardProps: ConfigCardsComponentProps = {
    logo: <PasskeyLogo />,
    label: 'Passkeys',
    description: t('Allow authentication using passkeys authentication'),
    isEnabled: updateData
      ? updateData.updateApp.passkeysEnabled
      : data.getApp.passkeysEnabled,
    onEnabledChange: onEnabledChange,
    isLoading: loading,
  };

  return <ToggleCardComponent {...cardProps} />;
};

export { PasskeysToggleCard };
