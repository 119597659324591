import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ListAppsDocument } from '../../gql/graphql';
import { useParams } from 'react-router-dom';

const useCurrentApp = () => {
  const [appId, setAppId] = useState<string | undefined>();
  const { data, loading, refetch } = useQuery(ListAppsDocument);
  const params = useParams();

  useEffect(() => {
    if (params.appId) {
      setAppId(params.appId);
    }
  }, [params]);

  return {
    appId,
    app:
      !loading && data ? data.listApps.find((a) => a.id === appId) : undefined,
    apps: !loading && data ? data.listApps : [],
    loading,
    refetch,
  };
};

export { useCurrentApp };
