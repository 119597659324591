import { FunctionComponent, useEffect, useState } from 'react';

export interface Unit {
  value: string;
  label: string;
  asciIcon?: string;
}

export interface InputUnitData {
  unit: Unit;
  amount: number;
}

interface Props {
  amountLabel: string;
  unitLabel: string;
  onChange: (data: InputUnitData) => void;
  initData: Partial<InputUnitData>;
  units: Unit[];
  showAsciIcon?: boolean;
}

const InputUnitComponent: FunctionComponent<Props> = ({
  amountLabel,
  unitLabel,
  onChange,
  initData,
  units,
  showAsciIcon,
}) => {
  const [amount, setAmount] = useState<number | undefined>(
    initData.amount || 0,
  );
  const [unit, setUnit] = useState<Unit>(initData.unit || units[0]);

  const changedUnit = (value: string) => {
    const curr = units.find((curr) => curr.value === value);
    if (curr) {
      setUnit(curr);
    }
  };

  useEffect(() => {
    amount && onChange({ amount, unit });
  }, [amount, unit]);

  return (
    <div>
      <label htmlFor="amount" className="text-gray-900 mb-1">
        {amountLabel}
      </label>
      <div className="relative w-full">
        {showAsciIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">{unit.asciIcon}</span>
          </div>
        )}
        <input
          type="text"
          name="amount"
          id="amount"
          className={`box-border w-full shadow-sm placeholder:text-gray-500 py-3 border rounded-md disabled:bg-gray-50 border-gray-300 bg-white focus:outline-purple-400 text-gray focus-visible:outline-purple-400 pl-${
            showAsciIcon ? '7' : '3'
          } pr-20`}
          placeholder="0.00"
          min={0}
          value={amount}
          onChange={(event) =>
            setAmount(
              event.target.value !== '' && !event.target.value.endsWith('.')
                ? parseFloat(event.target.value)
                : undefined,
            )
          }
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="unit" className="sr-only">
            {unitLabel}
          </label>
          <select
            id="unit"
            name="unit"
            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:outline-purple-400 focus-visible:outline-purple-400 sm:text-sm border-transparent border-r-8"
            onChange={(event) => changedUnit(event.target.value)}
            value={unit.value}
          >
            {units.map((unit, index) => {
              return (
                <option key={`unit-${index}`} value={unit.value}>
                  {unit.label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export { InputUnitComponent };
