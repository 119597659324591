import React from 'react';
import {
  OnboardingModalComponent,
  OnboardingTabs,
} from '../onboarding.modal.component';
import { CreateAppSteps } from './create-app-steps.component';

interface ComponentProps {
  didFinishOnboarding: () => void;
}

const CreateAppModalComponent = ({ didFinishOnboarding }: ComponentProps) => {
  const tabs: OnboardingTabs = {
    main: {
      title: 'Create app',
      steps: CreateAppSteps,
    },
  };

  return (
    <OnboardingModalComponent
      didFinishOnboarding={didFinishOnboarding}
      tabs={tabs}
    />
  );
};

export { CreateAppModalComponent };
