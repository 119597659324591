import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';

export type Step = {
  completed: boolean;
  current: boolean;
  label: string;
};

const StepButton = ({
  step,
  index,
  onStepClick,
}: {
  step: Step;
  index: number;
  onStepClick: (index: number) => void;
}) => {
  if (!step.current && step.completed) {
    return (
      <a
        onClick={() => onStepClick(index)}
        className="w-6 h-6 mx-auto bg-indigo-600 hover:bg-indigo-900 rounded-full text-lg flex items-center justify-center hover:cursor-pointer"
      >
        <CheckIcon className="h-4 w-4 text-white" aria-hidden="true" />
        <span className="sr-only">{step.label}</span>
      </a>
    );
  } else if (step.current) {
    return (
      <a
        onClick={() => onStepClick(index)}
        className="w-6 h-6 mx-auto border-2 border-indigo-600 bg-white rounded-full text-lg flex items-center justify-center"
      >
        <span
          className="h-2 w-2 rounded-full bg-indigo-600"
          aria-hidden="true"
        />
        <span className="sr-only">{step.label}</span>
      </a>
    );
  } else {
    return (
      <a
        onClick={() => onStepClick(index)}
        className="group w-6 h-6 mx-auto border-2 border-gray-300 hover:border-gray-400 bg-white rounded-full text-lg flex items-center justify-center hover:cursor-pointer"
      >
        <span
          className="h-2 w-2 rounded-full bg-transparent group-hover:bg-gray-300"
          aria-hidden="true"
        />
        <span className="sr-only">{step.label}</span>
      </a>
    );
  }
};

export function StepsComponent({
  steps,
  onStepClick,
}: {
  steps: Step[];
  onStepClick: (index: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <section className="w-full">
      <div className="container mx-auto w-full">
        <div className="flex" id="ProgressStepper">
          {steps.map((step, index) => (
            <div className="w-full" key={step.label}>
              <div className="relative mb-1">
                {index !== 0 && (
                  <div className="absolute flex align-center items-center align-middle content-center w-full px-8 -translate-x-1/2 -translate-y-1/2 top-1/2 pointer-events-none">
                    <div
                      className={`w-full ${
                        steps[index - 1].completed
                          ? 'bg-indigo-600'
                          : 'bg-gray-200'
                      } rounded py-0.5`}
                    ></div>
                  </div>
                )}

                <StepButton
                  step={step}
                  index={index}
                  onStepClick={onStepClick}
                />
              </div>

              <TextComponent size="sm" className="text-center">
                {step.label}
              </TextComponent>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
