import React, { FunctionComponent, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { useQuery, useMutation } from '@apollo/client';
import {
  ListRolesDocument,
  RoleGraphql,
  UpdateRoleDocument,
  CreateRoleDocument,
  DeleteRoleDocument,
  PrivilegeGraphql,
  RoleGraphqlInput,
  CreateRoleGraphqlInput,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import type { ColumnDef } from '@tanstack/react-table';
import { TableComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TableComponent';
import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { RoleModalComponent } from './role.modal.component';
import { CopyChipComponent } from './copy.button.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const RolesComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editRole, setEditRole] = useState<RoleGraphql>();
  const { t } = useTranslation();
  const { setSuccessMessage } = useFeedback();
  const { appId } = useCurrentApp();
  const { data, loading } = useQuery(ListRolesDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });

  const [updateRoleMutation, updateRoleData] = useMutation(UpdateRoleDocument);
  const [createRoleMutation, createRoleData] = useMutation(CreateRoleDocument);
  const [deleteRoleMutation, deleteRoleData] = useMutation(DeleteRoleDocument);

  const isLoading =
    loading ||
    updateRoleData.loading ||
    createRoleData.loading ||
    deleteRoleData.loading;

  const didClickDelete = async (row: RoleGraphql) => {
    if (appId) {
      await deleteRoleMutation({
        variables: { id: row.id, appId: appId },
        refetchQueries: [
          { query: ListRolesDocument, variables: { appId: appId } },
        ],
      });
      setSuccessMessage('The roles was deleted!');
    }
  };

  const didClickCreateBtn = () => {
    setEditRole(undefined);
    setModalOpen(true);
  };

  const didClickEdit = (row: RoleGraphql) => {
    setEditRole(row);
    setModalOpen(true);
  };

  const didClickEditModalSave = async (args: RoleGraphqlInput) => {
    if (appId) {
      if (editRole) {
        await updateRoleMutation({
          variables: {
            id: editRole.id,
            appId: appId,
            role: { ...args },
          },
        });
      }
      setModalOpen(false);
      setSuccessMessage('The roles was updated!');
    }
  };

  const didClickCreateModalSave = async (args: CreateRoleGraphqlInput) => {
    if (appId) {
      await createRoleMutation({
        variables: {
          appId: appId,
          role: { ...args },
        },
        refetchQueries: [
          { query: ListRolesDocument, variables: { appId: appId } },
        ],
      });
      setModalOpen(false);
      setSuccessMessage('A new roles was created!');
    }
  };

  const renderPrivileges = (privileges: PrivilegeGraphql[]) => {
    return (
      <>
        {privileges.map((p, i) => (
          <span className="text-gray-500" key={`key-${i}`}>
            {p.key}
            {i !== privileges.length - 1 && privileges.length !== 1 ? ', ' : ''}
          </span>
        ))}
      </>
    );
  };

  const EditComponent: FunctionComponent<{ row: RoleGraphql }> = ({ row }) => {
    const options: Option[] = [
      {
        label: 'Edit',
        onClick: () => {
          didClickEdit(row);
        },
        labelPosition: 'start',
      },
    ];

    if (!row.isDefault && row.key != 'OWNER')
      options.push({
        label: 'Delete',
        type: 'danger',
        onClick: () => {
          didClickDelete(row);
        },
        labelPosition: 'start',
      });

    return (
      <div className="flex items-center justify-end">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  const cols: ColumnDef<RoleGraphql>[] = [
    {
      header: 'Name',
      cell: ({ row }) => {
        return (
          <>
            <span>{row.original.name}</span>
            {row.original.isDefault && (
              <ChipComponent className="ml-2" type={'primary'}>
                Default
              </ChipComponent>
            )}
          </>
        );
      },
      size: 150,
    },
    {
      header: 'Key',
      cell: (cell) => (
        <CopyChipComponent value={cell.renderValue() as string} />
      ),
      accessorKey: 'key',
      size: 150,
    },
    {
      header: 'Description',
      cell: (cell) => (
        <span className="block pr-4">{cell.renderValue() as string}</span>
      ),
      accessorKey: 'description',
      size: 300,
    },
    {
      header: 'Privileges',
      cell: (cell) =>
        renderPrivileges(cell.renderValue() as PrivilegeGraphql[]),
      accessorKey: 'privileges',
      size: 200,
    },
    {
      id: 'edit',
      header: '',
      cell: ({ row }) => {
        return <EditComponent row={row.original} />;
      },
      size: 100,
    },
  ];

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="flex flex-col">
          <SubHeadingComponent type={'primary'} size={'2xl'}>
            Roles
          </SubHeadingComponent>

          <TextComponent size={'base'} colorName="text-gray-500">
            {t('Create and manage roles.')}
          </TextComponent>
        </div>
        <div className="flex flex-col justify-center">
          <NblocksButton
            size="md"
            className="justify-self-center"
            type="primary"
            onClick={() => didClickCreateBtn()}
          >
            Create role
          </NblocksButton>
        </div>
      </div>
      <TableComponent
        columns={cols}
        data={data?.listRoles}
        loading={isLoading}
        defaultPageSize={10}
      />
      <ModalComponent
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        heading={editRole ? 'Edit role' : 'New role'}
      >
        {data && (
          <RoleModalComponent
            didClickSaveEdit={(args) => didClickEditModalSave(args)}
            didClickSaveCreate={(args) => didClickCreateModalSave(args)}
            didClickCancel={() => setModalOpen(false)}
            editRole={editRole}
            allRoles={data?.listRoles}
          />
        )}
      </ModalComponent>
    </div>
  );
};

export { RolesComponent };
