import React, { useEffect, useState } from 'react';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';
import {
  OptionsComponent,
  Selectable,
} from 'src/components/shared/options/options.component';
import { useListCurrentPlans } from 'src/shared/hooks/use-list-plans';
import { useTranslation } from 'react-i18next';
import { SetTenantPlanDetailsInput } from '@nebulr-group/nblocks-react/lib/gql/graphql';
import { priceOneLiner } from 'src/components/payments/plans.component';
import { TenantPaymentDetailsGraphql } from 'src/gql/graphql';

interface ComponentProps {
  didSetPlanDetails: (details: SetTenantPlanDetailsInput) => void;
  paymentDetails?: TenantPaymentDetailsGraphql;
}

const TenantSubscriptionComponent = ({
  didSetPlanDetails,
  paymentDetails,
}: ComponentProps) => {
  const { t } = useTranslation();
  const { data: plansQuery, loading: plansLoading } = useListCurrentPlans();
  const [existingValue, setExistingValue] = useState<string | undefined>();

  const offers: Selectable[] = [];

  const serializeValue = (details: SetTenantPlanDetailsInput) => {
    return JSON.stringify(details);
  };

  const unserializeValue = (details: string): SetTenantPlanDetailsInput => {
    return JSON.parse(details);
  };

  const currentPlanKey = paymentDetails?.details.plan?.key;
  const currentCurrency = paymentDetails?.details.price?.currency;
  const currentInterval = paymentDetails?.details.price?.recurrenceInterval;

  useEffect(() => {
    if (currentPlanKey && currentCurrency && currentInterval) {
      setExistingValue(
        serializeValue({
          planKey: currentPlanKey,
          priceOffer: {
            currency: currentCurrency,
            recurrenceInterval: currentInterval,
          },
        }),
      );
    }
  }, [paymentDetails]);

  const setPlanDetails = (str: string) => {
    setExistingValue(str);
    didSetPlanDetails(unserializeValue(str));
  };

  if (plansQuery?.getAppPlans.length && plansQuery?.getAppPlans.length > 0) {
    const plans = plansQuery.getAppPlans;
    plans.map((plan) => {
      plan.prices.map((price) => {
        if (!currentCurrency || price.currency == currentCurrency)
          offers.push({
            label: `${plan.name} (${priceOneLiner(price)})`,
            value: serializeValue({
              planKey: plan.key,
              priceOffer: {
                currency: price.currency,
                recurrenceInterval: price.recurrenceInterval,
              },
            }),
          });
      });
    });
  }

  const existingSelectable = offers.find((o) => o.value == existingValue);

  return (
    <>
      {plansLoading && <SkeletonLoader className="h-12 w-full rounded-md" />}
      {!plansLoading && (
        <OptionsComponent
          noSelectionLabel={t('No plan')}
          init={existingSelectable}
          data={offers}
          didSelect={(selectable) => setPlanDetails(selectable.value)}
        />
      )}
    </>
  );
};

export { TenantSubscriptionComponent };
