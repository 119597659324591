import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  ConfigCardsComponentProps,
  ToggleCardComponent,
} from '../../shared/toggle-card/toggle-card.component';
import { LoginSettingsCardProps } from '../login.component';
import { UpdateAppEnabledAuthDocument } from 'src/gql/graphql';
import { MagicLinkLogo } from '@nebulr-group/nblocks-react/lib/components/auth/shared/logos/magicLink.logo';

const MagicLinkToggleCard = ({ data }: LoginSettingsCardProps) => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const [updateEnabledAuthMutation, updateEnabledAuthData] = useMutation(
    UpdateAppEnabledAuthDocument,
  );

  const { data: updateData, loading } = updateEnabledAuthData;

  const onEnabledChange = async (enabled: boolean) => {
    await updateEnabledAuthMutation({
      variables: {
        id: appId!,
        app: {
          magicLinkEnabled: enabled,
        },
      },
    });
  };

  const cardProps: ConfigCardsComponentProps = {
    logo: <MagicLinkLogo />,
    label: 'Magic Link',
    description: t('Allow authentication using a link sent as an email'),
    isEnabled: updateData
      ? updateData.updateApp.magicLinkEnabled
      : data.getApp.magicLinkEnabled,
    onEnabledChange: onEnabledChange,
    isLoading: loading,
  };

  return <ToggleCardComponent {...cardProps} />;
};

export { MagicLinkToggleCard };
