import React, { FunctionComponent } from 'react';
import { CodeBlock } from 'src/components/shared/code/code-block';
import { OnboardingStepType } from '../onboarding.modal.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Trans } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { InlineCode } from 'src/components/shared/code/inline-code';
import { Config } from 'src/shared/Config';
import { TestStep } from './user-readiness-test.component';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';

const LoginRedirectStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const code = `import { redirect } from 'next/navigation'
 
export default function Login() {

  const APP_ID = "${appId}";

  // Immediately redirect the web browser to Nblocks login
  redirect(\`${Config.getOauthApiUrl()}/url/login/\${APP_ID}\`);
}
`;

  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        1. Create a login component
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingLogin"
          defaults="Create a new file <0>/app/login/page.jsx</0> and call this component <1>Login</1>. This will add a new route to <2>/login</2> by the framework."
          components={[
            <InlineCode>/app/login/page.jsx</InlineCode>,
            <InlineCode>Login</InlineCode>,
            <InlineCode>/login</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={code} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const CallbackStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const code = `'use client'

import { useSearchParams, redirect } from "next/navigation";
import { useEffect, useState } from "react";
import { jwtVerify, createRemoteJWKSet } from "jose";

// Users will get back to this component after finishing login
export default function Callback() {

  const APP_ID = "${appId}";

  const searchParams = useSearchParams();
  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      handleCallback(code);
    }
  }, [searchParams]);

  const handleCallback = async (code) => {
    // Get tokens
    const tokens = await fetch(\`${Config.getOauthApiUrl()}/token/code/\${APP_ID}\`,
      {
        method: "POST", headers: { "Content-Type": "application/json", },
        body: JSON.stringify({ code }),
      }
    ).then(res => res.json());

    // Verify the tokens result using public keys from Nblocks JWKS
    const { access_token, refresh_token, id_token } = tokens;
    const { payload } = await jwtVerify(
      access_token, createRemoteJWKSet(
          new URL('${Config.getOauthApiUrl()}/.well-known/jwks.json')
      ), { issuer: '${Config.getOauthApiUrl()}' }
    );

    // Store the result in component state and localstorage
    window.localStorage.setItem('access_token', access_token);
    window.localStorage.setItem('refresh_token', refresh_token);
    window.localStorage.setItem('id_token', id_token);
    setAccessToken(payload);
    console.log("User access token", payload);
  };

  if (accessToken)
      redirect("/");
  else
    return (<p>Loading...</p>);
}
`;
  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        1. Create a callback component
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingCallback"
          defaults="Create a new file <0>/app/auth/oauth-callback/page.jsx</0> and call this component <1>Callback</1>. This will add a new route to <2>/auth/oauth-callback</2> by the framework. Don't forget to add Jose, a common JWT util by running <3>npm i jose</3>."
          components={[
            <InlineCode>/app/auth/oauth-callback/page.jsx</InlineCode>,
            <InlineCode>Callback</InlineCode>,
            <InlineCode>/auth/oauth-callback</InlineCode>,
            <InlineCode>npm i jose</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={code} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const UserReadinessNextSteps: OnboardingStepType[] = [
  {
    label: 'Login redirect',
    component: LoginRedirectStep,
    nextButtonLabel: 'Next',
  },
  { label: 'Callback', component: CallbackStep, nextButtonLabel: 'Test' },
  {
    label: 'Test',
    component: TestStep,
    nextButtonLabel: 'See your new user!',
    onboardingMutationVariables: { userReadiness: { test: true } },
  },
];

export { UserReadinessNextSteps };
