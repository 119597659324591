import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom'; // Updated import to include 'Routes'
import { TabItem, Tabs } from 'src/components/shared/tabs/tabs';
import { useTranslation } from 'react-i18next';
import { AuthenticationBreadcrumpsComponent } from 'src/components/authentication/breadcrumps/authentication-breadcrumps-component';
import { useFlags } from 'src/shared/hooks/use-flags';
import { FlagKeys } from 'src/shared/flag-keys';

const AppAuthenticationScreen = () => {
  const { t } = useTranslation();
  const { flagEnabled } = useFlags();
  const navigate = useNavigate();

  const tabs: TabItem[] = [{ name: t('Login'), id: 'login' }];

  if (flagEnabled(FlagKeys.ENTERPRISE_CONNECTIONS)) {
    tabs.push({ name: t('Enterprise connections'), id: 'enterprise' });
  }

  tabs.push(
    { name: t('Security'), id: 'security' },
    { name: t('Signup'), id: 'signup' },
  );

  const tabFromUrl = tabs.find((tab) => location.pathname.includes(tab.id));

  const handleTabClick = (tab: TabItem) => {
    navigate(`${tab.id}`);
  };

  return (
    <div>
      <AuthenticationBreadcrumpsComponent tabs={tabs} />
      <HeadingComponent type={'h1'} size={'2xl'} className={'mt-5'}>
        {t('Authentication')}
      </HeadingComponent>
      <Tabs
        tabs={tabs}
        initTab={tabFromUrl ? tabFromUrl : undefined}
        didClickTab={(tab) => handleTabClick(tab)}
      />
      <Outlet />
    </div>
  );
};

export { AppAuthenticationScreen };
