import React, { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

export type ColorPickerProps = {
  color: string;
  onChange: (color: string) => void;
};

const ColorPickerComponent = ({ color, onChange }: ColorPickerProps) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleClose = () => {
    setShowColorPicker(false);
  };

  const handleChange = (color: ColorResult) => {
    onChange(color.hex);
  };

  return (
    <div>
      <div
        style={{ backgroundColor: color }}
        className="w-full h-12 border-2 border-gray-300 rounded-lg cursor-pointer"
        onClick={handleClick}
      />
      {showColorPicker && (
        <div
          style={{
            position: 'absolute',
            zIndex: '2',
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClose}
          />
          <SketchPicker color={color} onChange={handleChange} disableAlpha />
        </div>
      )}
    </div>
  );
};

export { ColorPickerComponent };
