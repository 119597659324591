import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { AppIdComponent } from 'src/components/app/app-id-component/app-id.component';
import { ApiKeysComponent } from 'src/components/app/api-keys-component/api-keys.component';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';

const AppKeysScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="space-y-6">
      <HeadingComponent type={'h1'} size={'2xl'} className={'mt-5'}>
        {t('Keys')}
      </HeadingComponent>
      <SubHeadingComponent type={'primary'} size={'xl'}>
        {t('App ID')}
      </SubHeadingComponent>
      <TextComponent size={'base'} colorName="text-gray-500">
        {t("This is your app's unique id")}
      </TextComponent>
      <AppIdComponent />
      <SubHeadingComponent type={'primary'} size={'xl'}>
        {t('API Keys')}
      </SubHeadingComponent>
      <TextComponent size={'base'} colorName="text-gray-500">
        {t(
          'API keys are used to authenticate when making REST API calls to Nblocks',
        )}
      </TextComponent>
      <ApiKeysComponent />
    </div>
  );
};

export { AppKeysScreen };
