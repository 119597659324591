import React, { FunctionComponent } from 'react';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Trans, useTranslation } from 'react-i18next';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';

const TestStep: FunctionComponent = () => {
  const { t } = useTranslation();

  const appUrl = 'whatever';

  return (
    <div className="w-full h-full flex flex-col items-left space-y-6">
      <div className="space-y-4">
        <HeadingComponent type={'h2'} size={'xl'}>
          1. {t('Start your app')}
        </HeadingComponent>
        <TextComponent>
          <Trans
            i18nKey="userReadinessTestOnboardingStartApp"
            defaults={`Start your application so that it is accessible on <0>${appUrl}</0>`}
            components={[
              <LinkComponent
                type={'primary'}
                to={appUrl}
                nativeBehavior={true}
                target="_blank"
              >
                {appUrl}
              </LinkComponent>,
            ]}
          />
        </TextComponent>
      </div>
      <div className="space-y-4">
        <HeadingComponent type={'h2'} size={'xl'}>
          2. {t('Navigate to /login and get redirected to Nblocks Login')}
        </HeadingComponent>
        <TextComponent>
          <Trans
            i18nKey="userReadinessTestOnboardingLogin"
            defaults={`Open a browser tab and navigate to your app's login URL <0>${appUrl}</0>`}
            components={[
              <LinkComponent
                type={'primary'}
                to={appUrl}
                nativeBehavior={true}
                target="_blank"
              >
                {appUrl}
              </LinkComponent>,
            ]}
          />
        </TextComponent>
        <TextComponent>
          {t('Your new code redirects the user to Nblocks Login.')}
        </TextComponent>
      </div>
      <div className="space-y-4">
        <HeadingComponent type={'h2'} size={'xl'}>
          3. {t('Sign up as a new user and return back to your app')}
        </HeadingComponent>
        <TextComponent>
          {t(
            'Click "Sign up" button on the bottom of the login screen, and you will be redirected to the signup screen.',
          )}
        </TextComponent>
        <TextComponent>
          {t(
            'After being redirected back to your application, you should see user profile information in the console.',
          )}
        </TextComponent>
        <TextComponent>
          {t('Your users and workspaces are shown here in the dashboard')}
        </TextComponent>
      </div>
    </div>
  );
};

export { TestStep };
