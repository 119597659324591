import React from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export interface ComponentProps {
  title: string;
  message: string;
  submitBtnText: string;
  cancelBtnText: string;
  onSubmit: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
}

const DangerVerifyModalComponent = ({
  title,
  message,
  submitBtnText,
  cancelBtnText,
  onSubmit,
  onCancel,
  isOpen,
  setIsOpen,
  loading,
}: ComponentProps) => {
  const didClickSubmit = async () => {
    if (onSubmit) await onSubmit();
  };

  const didClickCancel = async () => {
    if (onCancel) await onCancel();
    setIsOpen(false);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      heading={title}
      description={message}
      icon={<ExclamationTriangleIcon />}
    >
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {cancelBtnText}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="danger"
          isLoading={loading}
          disabled={loading}
          onClick={() => didClickSubmit()}
        >
          {submitBtnText}
        </NblocksButton>
      </div>
    </ModalComponent>
  );
};

export { DangerVerifyModalComponent };
