import React, { useEffect, useState } from 'react';
import { PriceGraphql } from 'src/gql/graphql';
import {
  RecurIntervalsInputComponent,
  supportedIntervals,
} from './recur-interval.input.component';
import { supportedCurrencies } from 'src/shared/currency-utils';
import {
  InputUnitData,
  Unit,
} from 'src/components/shared/input-unit/input-unit.component';
import { InputUnitComponent } from 'src/components/shared/input-unit/input-unit.component';

export interface ComponentProps {
  editPrice?: PriceGraphql;
  onChange?: (data: PriceGraphql, valid: boolean) => void;
}

const PlanPriceInputComponent = ({ editPrice, onChange }: ComponentProps) => {
  const [amount, setAmount] = useState<number | undefined>(
    editPrice?.amount || undefined,
  );
  const [currency, setCurrency] = useState(editPrice?.currency || 'EUR');
  const [recurrenceInterval, setRecurrenceInterval] = useState(
    editPrice?.recurrenceInterval || 'month',
  );

  useEffect(() => {
    if (onChange) {
      const formValid = !!currency && !!recurrenceInterval;
      onChange(
        {
          amount: amount || 0,
          currency,
          recurrenceInterval,
        },
        formValid,
      );
    }
  }, [amount, currency, recurrenceInterval]);

  const onPriceDataUpdate = (data: InputUnitData) => {
    setAmount(data.amount);
    setCurrency(data.unit.value);
  };

  const units: Unit[] = supportedCurrencies.map(
    (currency): Unit => ({
      value: currency.isoCode,
      label: currency.name,
      asciIcon: currency.asciIcon,
    }),
  );

  return (
    <div className="space-y-6 w-full">
      <InputUnitComponent
        amountLabel={'Price'}
        unitLabel={'Currency'}
        onChange={(data) => onPriceDataUpdate(data)}
        initData={{ amount, unit: units.find((i) => i.value === currency) }}
        units={units}
        showAsciIcon
      />
      <RecurIntervalsInputComponent
        didSelect={(args) => setRecurrenceInterval(args.value)}
        init={supportedIntervals.find((i) => i.value === recurrenceInterval)}
      />
    </div>
  );
};

export { PlanPriceInputComponent };
