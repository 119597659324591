import React, { FunctionComponent, useState } from 'react';

const FeedbackContext = React.createContext<
  | {
      setSuccessMessage: (message?: string) => void;
      successMessage: string | undefined;
    }
  | undefined
>(undefined);

const FeedbackContextProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState<string | undefined>();

  return (
    <FeedbackContext.Provider value={{ setSuccessMessage, successMessage }}>
      {children}
    </FeedbackContext.Provider>
  );
};

export { FeedbackContext, FeedbackContextProvider };
