import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Square3Stack3DIcon,
  IdentificationIcon,
  CreditCardIcon,
  ShieldCheckIcon,
  LockClosedIcon,
  SwatchIcon,
  KeyIcon,
  CommandLineIcon,
} from '@heroicons/react/24/outline';
import { AppSidebarComponent } from 'src/components/app-sidebar.component';
import { AppTopbarComponent } from 'src/components/app-topbar.component';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { CreateAppPromptComponent } from 'src/components/onboarding/create-app/create-app-prompt.component';
import { useFlags } from 'src/shared/hooks/use-flags';
import { FlagKeys } from 'src/shared/flag-keys';

export interface Item {
  name: string;
  href: string;
  active?: string;
  icon: ReactNode;
}

const AppScreen = () => {
  const { appId } = useCurrentApp();
  const { flagEnabled } = useFlags();

  // Used in both app side bar and top bar (mobile)
  const menuItems: Item[] = [
    {
      name: 'Workspaces',
      href: `/app/${appId}/tenants`,
      active: 'tenants',
      icon: <IdentificationIcon />,
    },
    {
      name: 'Authentication',
      href: `/app/${appId}/authentication`,
      active: 'authentication',
      icon: <LockClosedIcon />,
    },
    {
      name: 'Roles',
      href: `/app/${appId}/access`,
      active: 'access',
      icon: <ShieldCheckIcon />,
    },
    {
      name: 'Feature Control',
      href: `/app/${appId}/flags`,
      active: 'flags',
      icon: <Square3Stack3DIcon />,
    },
    {
      name: 'Subscription Control',
      href: `/app/${appId}/payments`,
      active: 'payments',
      icon: <CreditCardIcon />,
    },
    {
      name: 'Customization',
      href: `/app/${appId}/customization`,
      active: 'customization',
      icon: <SwatchIcon />,
    },
    {
      name: 'Keys',
      href: `/app/${appId}/keys`,
      active: 'keys',
      icon: <KeyIcon />,
    },
  ];

  if (flagEnabled(FlagKeys.ADVANCED_BTN)) {
    menuItems.push({
      name: 'Advanced',
      href: `/app/${appId}/advanced`,
      active: 'advanced',
      icon: <CommandLineIcon />,
    });
  }

  return (
    <div>
      <AppTopbarComponent menuItems={menuItems} />
      <AppSidebarComponent menuItems={menuItems}>
        <Outlet />
      </AppSidebarComponent>
      <CreateAppPromptComponent />
    </div>
  );
};

export { AppScreen };
