/**
 * Hot Fix
 * process is not defined
 * ReferenceError: process is not defined
 */

(window as any).process = {
  ...(window as any).process,
};

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
