import React from 'react';

const SafePlanName = (name?: string | null) => {
  return name ? name : 'No plan set';
};

const SafePlanNameComponent = ({ name }: { name?: string | null }) => {
  return <>{SafePlanName(name)}</>;
};

export { SafePlanNameComponent, SafePlanName };
