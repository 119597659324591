import React, { useEffect, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { useTranslation } from 'react-i18next';
import {
  CreatePrivilegeGraphqlInput,
  PrivilegeGraphql,
  PrivilegeGraphqlInput,
} from 'src/gql/graphql';
import { Util } from 'src/shared/util';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';

export interface ComponentProps {
  didClickSaveEdit: (args: PrivilegeGraphqlInput) => void;
  didClickSaveCreate: (args: CreatePrivilegeGraphqlInput) => void;
  didClickCancel: () => void;
  editPrivilege?: PrivilegeGraphql;
  allPrivileges: PrivilegeGraphql[];
}

const PrivilegeModalComponent = ({
  didClickSaveEdit,
  didClickSaveCreate,
  didClickCancel,
  editPrivilege,
  allPrivileges,
}: ComponentProps) => {
  const { t } = useTranslation();
  const [key, setKey] = useState('');
  const [uniquenessViolation, setUniquenessViolation] =
    useState<boolean>(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (editPrivilege) {
      setKey(editPrivilege.key);
      setDescription(
        editPrivilege.description ? editPrivilege.description : '',
      );
    }
  }, [editPrivilege]);

  const onDidClickSave = () => {
    if (editPrivilege) {
      didClickSaveEdit({
        key,
        description,
      });
    } else {
      didClickSaveCreate({
        key,
        description,
      });
    }
  };

  const onPrivilegeKeyChange = (key: string) => {
    const allPrivilegeKeys = allPrivileges.map((p) => p.key);
    setUniquenessViolation(allPrivilegeKeys.includes(key));
    setKey(key);
  };

  const formValid = key && !uniquenessViolation;

  return (
    <div>
      <div>
        <form
          onSubmit={() => onDidClickSave()}
          className="space-y-6 max-w-sm w-full"
        >
          {key === 'AUTHENTICATED' && (
            <TextComponent size={'base'} colorName="text-gray-500">
              {t('This privilege is required to be present')}
            </TextComponent>
          )}
          <InputComponent
            type="text"
            label="Privilege key*"
            placeholder="E.g. USER_DELETION"
            name="id"
            disabled={key === 'AUTHENTICATED'}
            onChange={(event) =>
              onPrivilegeKeyChange(Util.cleanKeyInput(event.target.value, true))
            }
            errorLabel={{
              position: 'right',
              alt: 'Input error: This key already exists',
              node: 'This key already exists',
            }}
            inputError={uniquenessViolation}
            value={key}
          />
          <InputComponent
            type="text"
            label="Description (optional)"
            placeholder="E.g. This privilege allows user deletion"
            name="description"
            onChange={(event) => setDescription(event.target.value)}
            value={description}
          />
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {'Cancel'}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSave()}
          disabled={!formValid}
        >
          {'Save changes'}
        </NblocksButton>
      </div>
    </div>
  );
};

export { PrivilegeModalComponent };
