import React, { FunctionComponent, useState } from 'react';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { useListCurrentFederationConnections } from 'src/shared/hooks/use-list-federation-connections';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import type { ColumnDef } from '@tanstack/react-table';
import {
  CreateFederationConnectionDocument,
  CreateFederationConnectionGraphqlInput,
  DeleteFederationConnectionDocument,
  FederationConnectionGraphql,
  ListFederationConnectionsDocument,
  UpdateFederationConnectionDocument,
} from 'src/gql/graphql';
import { DateTimeComponent } from '../shared/date/date-time-component';
import { CopyChipComponent } from '../access-role/copy.button.component';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { TableComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TableComponent';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { useMutation } from '@apollo/client';
import { ConnectionModalComponent } from './connection.modal.component';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const EnterpriseConnectionsComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();
  const { setSuccessMessage } = useFeedback();
  const { data, loading } = useListCurrentFederationConnections();

  const [modalOpen, setModalOpen] = useState(false);
  const [editConnection, setEditConnection] =
    useState<FederationConnectionGraphql>();

  const [updateFederationConnectionMutation, { loading: updateLoading }] =
    useMutation(UpdateFederationConnectionDocument);
  const [createFederationConnectionMutation, { loading: createLoading }] =
    useMutation(CreateFederationConnectionDocument);
  const [deleteFederationConnectionMutation, { loading: deleteLoading }] =
    useMutation(DeleteFederationConnectionDocument);

  const isLoading = loading || updateLoading || createLoading || deleteLoading;

  const onDidClickDelete = async (row: FederationConnectionGraphql) => {
    if (appId && row.id) {
      await deleteFederationConnectionMutation({
        variables: { appId, id: row.id },
        refetchQueries: [ListFederationConnectionsDocument],
      });
      setSuccessMessage(t('The connection was deleted!'));
    }
  };

  const didClickCreateBtn = () => {
    setEditConnection(undefined);
    setModalOpen(true);
  };

  const didClickEditBtn = (row: FederationConnectionGraphql) => {
    setEditConnection(row);
    setModalOpen(true);
  };

  const didClickModalSave = async (
    connection: CreateFederationConnectionGraphqlInput,
  ) => {
    if (appId) {
      if (editConnection) {
        await updateFederationConnectionMutation({
          variables: {
            appId,
            id: editConnection.id,
            federationConnection: connection,
          },
          refetchQueries: [ListFederationConnectionsDocument],
        });
        setSuccessMessage(t('The connection was updated!'));
      } else {
        await createFederationConnectionMutation({
          variables: {
            appId,
            federationConnection: connection,
          },
          refetchQueries: [ListFederationConnectionsDocument],
        });
        setSuccessMessage(t('A new connection was created!'));
      }
    }
    setModalOpen(false);
  };

  const EditComponent: FunctionComponent<{
    row: FederationConnectionGraphql;
  }> = ({ row }) => {
    const options: Option[] = [
      {
        label: 'Edit',
        onClick: () => {
          didClickEditBtn(row);
        },
      },
      {
        label: 'Delete',
        type: 'danger',
        onClick: () => {
          onDidClickDelete(row);
        },
      },
    ];

    return (
      <div className="flex items-center justify-end">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  const cols: ColumnDef<FederationConnectionGraphql>[] = [
    {
      header: t('Name'),
      cell: (cell) => (
        <TextComponent>{cell.renderValue() as string}</TextComponent>
      ),
      accessorKey: 'name',
      size: 150,
    },
    {
      header: t('Key'),
      cell: (cell) => (
        <CopyChipComponent value={cell.renderValue() as string} />
      ),
      accessorKey: 'id',
      size: 150,
    },
    {
      header: t('Type'),
      cell: (cell) => (
        <TextComponent>
          {(cell.renderValue() as string).toUpperCase()}
        </TextComponent>
      ),
      accessorKey: 'type',
      size: 100,
    },
    {
      header: t('Created'),
      cell: (cell) => (
        <DateTimeComponent value={cell.renderValue() as string} />
      ),
      accessorKey: 'createdAt',
      size: 200,
    },
    {
      id: 'edit',
      header: '',
      cell: ({ row }) => {
        return <EditComponent row={row.original} />;
      },
      size: 100,
    },
  ];

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <SubHeadingComponent type={'primary'} size={'2xl'}>
              {t('Enterprise connections')}
            </SubHeadingComponent>

            <TextComponent size={'base'} colorName="text-gray-500">
              {t(
                'Enable connections between your application and your enterprise customers with SAML',
              )}
            </TextComponent>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <NblocksButton
            size="md"
            className="justify-self-center"
            type="primary"
            onClick={() => didClickCreateBtn()}
          >
            {t('Create connection')}
          </NblocksButton>
        </div>
      </div>
      <TableComponent
        columns={cols}
        data={data?.listFederationConnections}
        loading={isLoading}
        defaultPageSize={10}
      />
      <ModalComponent
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        heading={editConnection ? 'Edit connection' : 'New connection'}
      >
        <ConnectionModalComponent
          editConnectionData={editConnection}
          didClickSave={didClickModalSave}
          didClickCancel={() => setModalOpen(false)}
          isLoading={isLoading}
        />
      </ModalComponent>
    </div>
  );
};

export { EnterpriseConnectionsComponent };
