import { AxiosInstance } from 'axios';
import { Config } from './Config';

export class FileUtil {
  static async upload(
    file: File,
    authHttpInstance: AxiosInstance,
  ): Promise<{ key: string }> {
    const prepareResult = await this.prepareUpload(file, authHttpInstance);
    await this.uploadWithSession(file, prepareResult.session);
    return { key: prepareResult.key };
  }

  static async prepareUpload(
    file: File,
    authHttpInstance: AxiosInstance,
  ): Promise<PrepareUploadResponse> {
    const response = await authHttpInstance.post<UploadSession>(
      `/file/prepareUpload`,
      { name: file.name, contentType: file.type },
    );

    return { session: response.data, key: response.data.fields['key'] };
  }

  static async uploadWithSession(
    file: File,
    uploadSession: UploadSession,
  ): Promise<void> {
    const sessionFields = uploadSession.fields;
    const formData: FormData = new FormData();
    for (const key of Object.keys(sessionFields)) {
      formData.append(key, sessionFields[key]);
    }
    formData.append('file', file);

    await fetch(uploadSession.url, {
      method: 'POST',
      body: formData,
    });
  }
}

export interface PrepareUploadArgs {
  fileName: string;
  contentType: string;
}

export interface PrepareUploadResponse {
  key: string;
  session: UploadSession;
}

export interface UploadSession {
  /** Upload to this url */
  url: string;

  /** Attach these headers to the upload call. Keep order of headers */
  fields: Record<string, string>;
}
