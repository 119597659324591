import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { Config } from 'src/shared/Config';
import { CopyChipComponent } from '../access-role/copy.button.component';

const GenericLoginLinkComponent = () => {
  const { appId } = useCurrentApp();
  const { t } = useTranslation();
  const link = `${Config.getOauthApiUrl()}/url/login/${appId}`;

  return <CopyChipComponent label={t('Login link')} value={link} />;
};

export { GenericLoginLinkComponent };
