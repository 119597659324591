import React from 'react';

const SafeUserName = (name?: string | null) => {
  return name ? name : 'Unamed user';
};

const SafeUserNameComponent = ({ name }: { name?: string | null }) => {
  return <>{SafeUserName(name)}</>;
};

export { SafeUserNameComponent, SafeUserName };
