import React, { useEffect, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { TogglerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TogglerComponent';
import { DividerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/DividerComponent';
import { Switch } from '@headlessui/react';
import {
  CreateFederationConnectionGraphqlInput,
  FederationConnectionGraphql,
  UpdateFederationConnectionGraphqlInput,
} from 'src/gql/graphql';
import { useTranslation } from 'react-i18next';
import {
  OptionsComponent,
  Selectable,
} from '../shared/options/options.component';
import { ApolloUtils } from 'src/shared/apollo-utils';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';
import { TextareaComponent } from '../shared/textarea/textarea.component';

export interface ComponentProps {
  didClickSave: (connection: CreateFederationConnectionGraphqlInput) => void;
  didClickCancel: () => void;
  editConnectionData?: FederationConnectionGraphql;
  isLoading: boolean;
}

const requestMethodsOptions: Selectable[] = [
  {
    label: 'GET',
    value: 'GET',
  },
  {
    label: 'POST',
    value: 'POST',
  },
];

const signedRequestAlgorithmOptions: Selectable[] = [
  {
    label: 'SHA-256',
    value: 'sha256',
  },
  {
    label: 'SHA-512',
    value: 'sha512',
  },
];

const ConnectionModalComponent = ({
  didClickSave,
  didClickCancel,
  editConnectionData,
  isLoading,
}: ComponentProps) => {
  const { t } = useTranslation();
  const [connection, setConnection] = useState<
    | CreateFederationConnectionGraphqlInput
    | UpdateFederationConnectionGraphqlInput
  >({
    type: 'saml',
    requestMethod: requestMethodsOptions[0].value,
    signedRequest: false,
    signedRequestAlgorithm: signedRequestAlgorithmOptions[0].value,
  });

  useEffect(() => {
    if (editConnectionData) {
      // "Deleting" id from the object
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...editConnectionRest } = editConnectionData;
      const editConnectionDetails =
        ApolloUtils.removeTypeName<UpdateFederationConnectionGraphqlInput>(
          editConnectionRest,
        );
      setConnection({
        ...editConnectionDetails,
      });
    }
  }, [editConnectionData]);

  const onDidClickSave = () => {
    didClickSave(connection);
  };

  const formValid =
    connection.name &&
    connection.loginUrl &&
    connection.certificate &&
    connection.requestMethod &&
    (!connection.signedRequest ||
      (connection.signedRequestPrivateKey &&
        connection.signedRequestAlgorithm));

  return (
    <div>
      <div className="mb-2">
        <TextComponent>
          {t('See our documentation for guidance')}{' '}
          <LinkComponent
            target="_blank"
            to="https://nebulr-group.github.io/nblocks-docs/docs/authentication-and-access/enterprise-login"
            type="primary"
            nativeBehavior
          >
            {t('here')}
          </LinkComponent>
        </TextComponent>
      </div>
      <form
        onSubmit={() => onDidClickSave()}
        className="space-y-6 max-w-sm w-full"
      >
        <InputComponent
          type="text"
          label={t('Connection name*')}
          placeholder={t('E.g. Acme Corp SAML')}
          name="name"
          onChange={(event) =>
            setConnection({ ...connection, name: event.target.value })
          }
          value={connection.name ?? ''}
        />
        <InputComponent
          type="text"
          label={t('Client id')}
          placeholder={t('E.g. client_1234')}
          name="name"
          onChange={(event) =>
            setConnection({ ...connection, clientId: event.target.value })
          }
          value={connection.clientId ?? ''}
        />
        <InputComponent
          type="text"
          label={t('SSO / Login URL*')}
          placeholder={t('E.g.') + ' https://acmecorp.com/auth/saml/sso'}
          name="name"
          onChange={(event) =>
            setConnection({ ...connection, loginUrl: event.target.value })
          }
          value={connection.loginUrl ?? ''}
        />
        <TextareaComponent
          label={t('Certificate*')}
          id="certificate"
          rows={3}
          value={connection.certificate}
          onChange={(value) => {
            setConnection({
              ...connection,
              certificate: value,
            });
          }}
        />
        <OptionsComponent
          data={requestMethodsOptions}
          init={
            connection.requestMethod
              ? requestMethodsOptions.find(
                  (option) => option.value === connection.requestMethod,
                )
              : requestMethodsOptions[0]
          }
          label="Request method"
          didSelect={(option: Selectable) =>
            setConnection({ ...connection, requestMethod: option.value })
          }
        />
        <Switch.Group>
          <div className="flex items-center">
            <Switch.Label className="mr-4">{t('Signed request')}</Switch.Label>
            <TogglerComponent
              name="signedRequest"
              enabled={!!connection.signedRequest}
              setEnabled={(value) =>
                setConnection({ ...connection, signedRequest: !!value })
              }
            ></TogglerComponent>
          </div>
        </Switch.Group>
        {connection.signedRequest && (
          <>
            <DividerComponent text={t('Signed Request')} />
            <OptionsComponent
              data={signedRequestAlgorithmOptions}
              init={
                connection.signedRequestAlgorithm
                  ? signedRequestAlgorithmOptions.find(
                      (option) =>
                        option.value === connection.signedRequestAlgorithm,
                    )
                  : signedRequestAlgorithmOptions[0]
              }
              label="Signed request algorithm"
              didSelect={(option: Selectable) =>
                setConnection({
                  ...connection,
                  signedRequestAlgorithm: option.value,
                })
              }
            />
            <TextareaComponent
              label={t('Signed request private key*')}
              id="signedRequestPK"
              rows={3}
              value={connection.signedRequestPrivateKey}
              onChange={(value) => {
                setConnection({
                  ...connection,
                  signedRequestPrivateKey: value,
                });
              }}
            />
          </>
        )}
      </form>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
          isLoading={isLoading}
        >
          {t('Cancel')}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSave()}
          disabled={!formValid}
        >
          {t('Save changes')}
        </NblocksButton>
      </div>
    </div>
  );
};

export { ConnectionModalComponent };
