export class Util {
  /**
   * Env must come from TSX file to be resolved by react-scripts
   * @param input - String to clean
   * @param capitalize - Converts using underscore and caps if true, otherwise lowercase and dash
   * @returns
   */
  static cleanKeyInput = (input: string, capitalize: boolean) => {
    if (input) {
      return capitalize
        ? input.toUpperCase().replace(' ', '_')
        : input.toLowerCase().replace(' ', '-');
    } else {
      return '';
    }
  };
}
