import React, { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Fragment, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ComponentProps {
  label?: string;
  data: Selectable[];
  init?: Selectable;
  search?: boolean;
  noSelectionLabel?: string;
  addOptions?: { label: string; onAdd: () => void };
  didSelect: (args: Selectable) => void;
  noBorder?: boolean;
  disabled?: boolean;
}

export interface Selectable {
  value: string;
  label: string;
  icon?: ReactElement;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const OptionsComponent = ({
  label,
  data,
  init,
  search,
  didSelect,
  addOptions,
  noBorder,
  noSelectionLabel,
  disabled,
}: ComponentProps) => {
  const [selected, setSelected] = useState<Selectable | undefined>(
    init || undefined,
  );
  const [query, setQuery] = useState('');
  const { t } = useTranslation();

  const didChange = (selectable: Selectable) => {
    if (selected != selectable) {
      setSelected(selectable);
      onDidChange(selectable);
    }
  };

  const onDidChange = (selectable: Selectable) => {
    if (selectable.value === 'add-option') {
      addOptions?.onAdd();
    } else {
      didSelect(selectable);
    }
  };

  const filteredData = query
    ? data.filter((o) => o.label.toLowerCase().includes(query.toLowerCase()))
    : data;

  // It's important we keep object equality, even if we have a state since the object might be another one
  const _selected = selected
    ? data.find((s) => s.value == selected.value)
    : data.length > 0
    ? data[0]
    : undefined;

  return (
    <Listbox value={_selected} onChange={didChange} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Label className={'mb-1'}>
              <TextComponent size="base">{label}</TextComponent>
            </Listbox.Label>
            <Listbox.Button
              className={classNames(
                'relative w-full flex justify-between items-center cursor-default rounded-md pl-4 py-3 text-left text-gray-900',
                disabled ? 'bg-gray-50 ' : 'bg-white',
                !noBorder
                  ? 'ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-400'
                  : '',
              )}
            >
              <span className="block truncate pr-4">
                {_selected ? _selected.label : noSelectionLabel}
              </span>
              <span className="pointer-events-none flex items-center pr-4">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-600"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {search && (
                  <InputComponent
                    type="text"
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={t('Search')}
                    value={query}
                  />
                )}
                {filteredData.map((selectable) => (
                  <Listbox.Option
                    key={selectable.value}
                    className={
                      'text-gray-900 relative cursor-default select-none py-2 pl-4 pr-4 hover:cursor-pointer flex justify-between'
                    }
                    value={selectable}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected || active
                              ? 'font-semibold'
                              : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {selectable.icon}
                          {selectable.label}
                        </span>

                        <span
                          className={classNames(
                            selected ? 'text-indigo-600' : 'text-white',
                            'flex items-center pl-4',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
                {addOptions && (
                  <Listbox.Option
                    key={'add-option'}
                    className={
                      'border-t-2 text-gray-900 relative cursor-default select-none py-2 pl-4 pr-4 hover:cursor-pointer flex'
                    }
                    value={{ label: 'add-option', value: 'add-option' }}
                  >
                    <span
                      className={
                        'font-semibold block truncate text-indigo-600 w-60'
                      }
                    >
                      {addOptions.label}
                    </span>
                  </Listbox.Option>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export { OptionsComponent };
