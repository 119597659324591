import React, { FunctionComponent, useEffect, useState } from 'react';
import { OnboardingStepType } from '../onboarding.modal.component';
import { useTranslation } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  GetAppPropertiesDocument,
  ListAppsDocument,
  UpdateAppPropertiesDocument,
} from 'src/gql/graphql';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { useQuery, useMutation } from '@apollo/client';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { useApp } from '@nebulr-group/nblocks-react';
import { useButtonSuccess } from 'src/shared/hooks/use-button-success';

const CreateAppRenameStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();
  const { logo } = useApp();
  const [appName, setAppName] = useState<string>('');

  const { buttonSuccess, triggerButtonSuccess } = useButtonSuccess();

  const { data } = useQuery(GetAppPropertiesDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });

  const [updateAppProperties, updateAppData] = useMutation(
    UpdateAppPropertiesDocument,
    {
      refetchQueries: [ListAppsDocument],
    },
  );

  const doUpdateAppName = async () => {
    if (appId && appName) {
      await updateAppProperties({
        variables: { id: appId, app: { name: appName } },
      });
      triggerButtonSuccess();
    }
  };

  useEffect(() => {
    if (data?.getApp.name) {
      setAppName(data.getApp.name);
    }
  }, [data]);

  return (
    <>
      {appId && (appName || appName === '') && (
        <div className="w-full h-full flex flex-col items-center justify-center space-y-6">
          {logo && <img className="h-12 w-auto" src={logo} alt="Nblocks" />}
          <HeadingComponent type="h2" size="3xl">
            {t('Welcome to nblocks and your new app!')}
          </HeadingComponent>
          <SubHeadingComponent type="primary" size="lg">
            {t('Start by giving it a better name')}
          </SubHeadingComponent>
          <div className="w-2/3 my-2">
            <InputComponent
              type={'text'}
              value={appName}
              onChange={(event: any) => setAppName(event.target.value)}
            />
          </div>
          <div className="my-2">
            <NblocksButton
              size="sm"
              className="h-12"
              type={buttonSuccess ? 'success' : 'primary'}
              isLoading={updateAppData.loading}
              onClick={() => doUpdateAppName()}
            >
              {buttonSuccess ? t('Saved!') : t('Save')}
            </NblocksButton>
          </div>
        </div>
      )}
    </>
  );
};

const CreateAppSteps: OnboardingStepType[] = [
  {
    label: 'Rename',
    component: CreateAppRenameStep,
    nextButtonLabel: 'Start exploring',
  },
];

export { CreateAppSteps };
