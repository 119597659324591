import React, { useEffect, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { TogglerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TogglerComponent';
import { DividerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/DividerComponent';
import { Switch } from '@headlessui/react';
import { PlanGraphql, PriceGraphql } from 'src/gql/graphql';
import { PlanPriceInputComponent } from './prices/plan-price.input.component';
import { useTranslation } from 'react-i18next';

export interface ComponentProps {
  didClickSave: (args: SaveArgs) => void;
  didClickCancel: () => void;
  editPlan?: PlanGraphql;
}

export interface SaveArgs {
  name: string;
  key: string;
  description?: string;
  trial: boolean;
  trialDays?: number;
  prices: PriceGraphql[];
}

const PlanModalComponent = ({
  didClickSave,
  didClickCancel,
  editPlan,
}: ComponentProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [key, setKey] = useState('');
  const [description, setDescription] = useState<string>('');
  const [trialEnabled, setTrialEnabled] = useState(false);
  const [trialDays, setTrialDays] = useState<number | undefined>();
  const [prices, setPrices] = useState<PriceGraphql[]>([]);
  const [pricesValid, setPricesValid] = useState<boolean[]>([]);
  const [showNewPriceInput, setShowNewPriceInput] = useState(false);
  const [newPriceInput, setNewPriceInput] = useState<
    PriceGraphql | undefined
  >();
  const [newPriceInputValid, setNewPriceInputValid] = useState(false);

  useEffect(() => {
    if (editPlan) {
      setName(editPlan.name);
      setKey(editPlan.key);
      setDescription(editPlan.description || '');
      setTrialEnabled(editPlan.trial);
      setTrialDays(editPlan.trialDays || undefined);
      setPrices(editPlan.prices);
      setPricesValid(editPlan.prices.map((p) => true));
    } else {
      setShowNewPriceInput(true);
    }
  }, [editPlan]);

  useEffect(() => {
    if (name && !editPlan) {
      setKey(cleanKeyInput(name));
    }
  }, [name]);

  const onDidClickSave = () => {
    didClickSave({
      key,
      name,
      description,
      trial: trialEnabled,
      trialDays: trialEnabled ? trialDays : 0,
      prices:
        showNewPriceInput && newPriceInput
          ? [...prices, newPriceInput]
          : prices,
    });
  };

  const handleNewPriceInput = (price: PriceGraphql, valid: boolean) => {
    setNewPriceInput(price);
    setNewPriceInputValid(valid);
  };

  const didClickAddAnotherPrice = () => {
    if (newPriceInput) {
      const nextPrices = [...prices, newPriceInput];
      const nextPricesValid = [...pricesValid, newPriceInputValid];
      setPrices(nextPrices);
      setPricesValid(nextPricesValid);
      setNewPriceInput(undefined);
      setNewPriceInputValid(false);
    } else {
      setShowNewPriceInput(true);
    }
  };

  const formValid =
    key &&
    !pricesValid.some((valid) => !valid) &&
    (!trialEnabled || (trialEnabled && trialDays && trialDays > 0)) &&
    (!showNewPriceInput || (showNewPriceInput && newPriceInputValid));

  const cleanKeyInput = (input: string) => {
    return input ? input.toLowerCase().replace(' ', '-') : '';
  };

  const didChangePrice = (
    data: { price: PriceGraphql; valid: boolean },
    i: number,
  ) => {
    const { price, valid } = data;
    console.log(price, valid, i);
    const next = prices.map((old, j) => {
      if (j === i) {
        return price;
      } else {
        return old;
      }
    });

    setPrices(next);

    const nextValid = [...pricesValid];
    nextValid[i] = valid;
    setPricesValid(nextValid);
  };

  return (
    <div>
      <div>
        <form
          onSubmit={() => onDidClickSave()}
          className="space-y-6 max-w-sm w-full"
        >
          <InputComponent
            type="text"
            label={t('Plan name*')}
            placeholder={t('E.g. Example plan')}
            name="name"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
          <InputComponent
            type="text"
            label={t('Plan key*')}
            placeholder={t('E.g. example-plan')}
            name="key"
            onChange={(event) => setKey(cleanKeyInput(event.target.value))}
            value={key}
            disabled={!!editPlan}
          />
          <InputComponent
            type="text"
            label={t('Description (optional)')}
            placeholder={t('E.g. This is an example plan')}
            name="description"
            onChange={(event) => setDescription(event.target.value)}
            value={description}
          />
          <Switch.Group>
            <div className="flex items-center">
              <Switch.Label className="mr-4">{t('Free trial')}</Switch.Label>
              <TogglerComponent
                name="freeTrial"
                enabled={trialEnabled}
                setEnabled={(value) => setTrialEnabled(value)}
              ></TogglerComponent>
            </div>
          </Switch.Group>
          {trialEnabled && (
            <InputComponent
              type="number"
              label={t('Trial period days*')}
              placeholder={t('E.g. 14')}
              name="description"
              min={0}
              onChange={(event) =>
                event.target.value
                  ? setTrialDays(Number.parseInt(event.target.value))
                  : setTrialDays(undefined)
              }
              value={trialDays}
            />
          )}
          <DividerComponent text={t('Pricing')} />
          {prices.map((p, i) => (
            <PlanPriceInputComponent
              key={`price-${i}`}
              editPrice={p}
              onChange={(price, valid) => didChangePrice({ price, valid }, i)}
            />
          ))}
          {showNewPriceInput && (
            <PlanPriceInputComponent
              onChange={(price, valid) => handleNewPriceInput(price, valid)}
            />
          )}
          <NblocksButton
            size="md"
            className="w-full"
            type="tertiary"
            onClick={() => didClickAddAnotherPrice()}
          >
            {t('Add additional price')}
          </NblocksButton>
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {t('Cancel')}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSave()}
          disabled={!formValid}
        >
          {t('Save changes')}
        </NblocksButton>
      </div>
    </div>
  );
};

export { PlanModalComponent };
