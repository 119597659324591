import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { DeleteAppDocument } from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { useTranslation } from 'react-i18next';
import { DangerVerifyModalComponent } from 'src/components/shared/danger-verify-modal/danger-verify-modal.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';

const DeleteAppComponent = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const { app } = useCurrentApp();

  const [deleteAppMutation, deleteAppMutationData] =
    useMutation(DeleteAppDocument);

  const deleteApp = async () => {
    if (app) {
      await deleteAppMutation({ variables: { appId: app.id } });
      setIsOpen(false);

      // This is an unusal approach, screens should usualy do the navigation
      // A full reload is required to pull new data and refresh Apollo
      window.location.href = '/';
    }
  };

  const onCancel = () => {
    setInputVal('');
  };

  const deleteBtnDisabled = inputVal != 'permanently delete';

  return (
    <div className="space-y-6 max-w-md">
      <div>
        <TextComponent size="sm">
          {t(
            'To confirm deletion, type "permanently delete" in the text input field.',
          )}
        </TextComponent>
        <InputComponent
          placeholder={`${t('permanently delete')}`}
          type="text"
          label=""
          onChange={(event) => setInputVal(event.target.value)}
          value={inputVal}
        />
        <NblocksButton
          onClick={() => setIsOpen(true)}
          type="danger"
          disabled={deleteBtnDisabled}
          size="md"
          className="mt-1"
        >
          {t('Delete')}
        </NblocksButton>
      </div>
      <DangerVerifyModalComponent
        title={t('Are you sure?')}
        message={t(
          'Do you want to delete this app and all its data? This cannot be undone',
        )}
        submitBtnText={t('Delete')}
        cancelBtnText={t('Cancel')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={() => deleteApp()}
        onCancel={() => onCancel()}
        loading={deleteAppMutationData.loading}
      />
    </div>
  );
};

export { DeleteAppComponent };
