import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  BreadcrumpsComponent,
  Crump,
} from 'src/components/shared/breadcrumps/breadcrumps-component';
import { FunctionComponent } from 'react';
import { TabItem } from 'src/components/shared/tabs/tabs';

const PaymentsBreadcrumpsComponent: FunctionComponent<{
  selectedTab: TabItem;
}> = ({ selectedTab }) => {
  const { appId } = useCurrentApp();

  const crumps: Crump[] = [
    {
      name: 'Payments',
      href: `/app/${appId}/payments`,
      active: false,
    },
  ];

  crumps.push({
    name: selectedTab.name,
    href: `/app/${appId}/payments`,
    active: true,
  });

  return <BreadcrumpsComponent crumps={crumps} />;
};

export { PaymentsBreadcrumpsComponent };
