import React, { FunctionComponent } from 'react';
import { CodeBlock } from 'src/components/shared/code/code-block';
import { OnboardingStepType } from '../onboarding.modal.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Trans } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { InlineCode } from 'src/components/shared/code/inline-code';
import { Config } from 'src/shared/Config';
import { TestStep } from './feature-flags-test.component';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';

const FeatureFlagStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const code = `package com.mycompany.app;

import java.io.IOException;
import java.net.URI;
import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import org.json.JSONObject;

import javax.servlet.ServletException;
import javax.servlet.http.HttpServletRequest;
import javax.servlet.http.HttpServletResponse;

public class FeatureFlag {

    private static final String APP_ID = "${appId}";

    private static final String FEATURE_FLAG_URL = "${Config.getBackendlessUrl()}/flags/evaluate/" + APP_ID + "/";

    public static Boolean evaluateFlag(String flagId, HttpServletRequest request, HttpServletResponse response)
            throws ServletException, IOException {
        try {
            // Extract the access token from the Authorization header
            String authHeader = request.getHeader("Authorization");
            String accessToken = authHeader.substring("Bearer ".length());

            // Prepare the request
            JSONObject requestBody = new JSONObject().put("accessToken", accessToken);

            HttpRequest flagsRequest = HttpRequest.newBuilder()
                    .uri(URI.create(FEATURE_FLAG_URL + flagId))
                    .header("Content-Type", "application/json")
                    .POST(HttpRequest.BodyPublishers.ofString(requestBody.toString()))
                    .build();

            // Send the request
            HttpClient client = HttpClient.newHttpClient();

            HttpResponse<String> flagResponse = client.send(flagsRequest, HttpResponse.BodyHandlers.ofString());
            // Extract flag status from response
            String flagResponseBody = flagResponse.body();
            JSONObject flag = new JSONObject(flagResponseBody);

            return flag.getBoolean("enabled");
        } catch (Exception e) {
            e.printStackTrace();
            return false;
        }
    }
}
`;

  return appId ? (
    <>
      <TextComponent>
        <Trans
          i18nKey="userReadinessJavaOnboardingLogin"
          defaults="To conditionally run logic, we can create a new class called <0>FeatureFlags</0>"
          components={[<InlineCode>FeatureFlags</InlineCode>]}
        />
      </TextComponent>
      <CodeBlock code={code} language="java" />
    </>
  ) : (
    <></>
  );
};

const ComponentsStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const code = `package com.mycompany.app;

import java.io.IOException;

import javax.servlet.ServletException;
import javax.servlet.http.HttpServlet;
import javax.servlet.http.HttpServletRequest;
import javax.servlet.http.HttpServletResponse;

public class ModelServlet extends HttpServlet {
    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response)
            throws ServletException, IOException {
        try {
            Boolean useNewModel = FeatureFlag.evaluateFlag("new-model", request, response);
            if (useNewModel) {
                // Logic for new model
                response.getWriter().write("Running new model");
                return;
            } else {
                // Logic for old model
                response.getWriter().write("Running old model");
                return;
            }
        } catch (Exception e) {
            e.printStackTrace();
        }
    }
}
`;
  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        Use the new method
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessJavaOnboardingCallback"
          defaults="Now we can use the <0>evaluateFlag</0> method anywhere in your app to conditionally run operations, such as the new model"
          components={[<InlineCode>evaluateFlag</InlineCode>]}
        />
      </TextComponent>
      <CodeBlock code={code} language="java" />
    </>
  ) : (
    <></>
  );
};

const FeatureFlagsJavaSteps: OnboardingStepType[] = [
  {
    label: 'Create flag method',
    component: FeatureFlagStep,
    nextButtonLabel: 'Next',
  },
  {
    label: 'Use the method',
    component: ComponentsStep,
    nextButtonLabel: 'Done',
    // nextButtonLabel: 'Test',
  },
  {
    label: 'Done',
    // label: 'Test',
    component: TestStep,
    nextButtonLabel: 'Finish',
    onboardingMutationVariables: { featureFlags: { test: true } },
  },
];

export { FeatureFlagsJavaSteps };
