import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom'; // Updated import to include 'Routes'
import { TabItem, Tabs } from 'src/components/shared/tabs/tabs';

const AppAccessRoleScreen = () => {
  const navigate = useNavigate();

  const tabs = [
    { name: 'Roles', id: 'roles' },
    { name: 'Privileges', id: 'privileges' },
  ];

  const tabFromUrl = tabs.find((tab) => location.pathname.includes(tab.id));

  const handleTabClick = (tab: TabItem) => {
    navigate(`${tab.id}`);
  };

  return (
    <div>
      <HeadingComponent type={'h1'} size={'2xl'} className={'mt-5'}>
        Roles
      </HeadingComponent>
      <Tabs
        tabs={tabs}
        initTab={tabFromUrl ? tabFromUrl : undefined}
        didClickTab={(tab) => handleTabClick(tab)}
      />
      <Outlet />
    </div>
  );
};

export { AppAccessRoleScreen };
