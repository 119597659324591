import React, { FunctionComponent } from 'react';
import { CodeBlock } from 'src/components/shared/code/code-block';
import { OnboardingStepType } from '../onboarding.modal.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { InlineCode } from 'src/components/shared/code/inline-code';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { TestStep } from './feature-flags-test.component';

const InstallStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const installCode = `
npx @nebulr-group/nblocks-cli setup-react
  `;

  const providerCode = `import { NblocksProvider } from '@nebulr-group/nblocks-react';

export default function App() {
  return (
    <NblocksProvider config={{ appId: '${appId}' }}>
      ... App components ...
    </NblocksProvider>
  );
}
`;

  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        1. Install the Nblocks react plugin
      </HeadingComponent>
      <CodeBlock code={installCode} language="typescript" />
      <HeadingComponent type="h2" size="xl">
        2. Add the NblocksProvider
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingLogin"
          defaults="Add the <0>NblocksProvider</0> to your top most component, e.g. the <1>App</1> component and wrap the rest of your app as children."
          components={[<InlineCode>App</InlineCode>]}
        />
      </TextComponent>
      <CodeBlock code={providerCode} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const ComponentsStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();
  const { t } = useTranslation();

  const beforeComponent = `// We just want to render this for premium customers
<span>Premium content</span>

// We just want to render this for admins 
<a href="/beta">Button to admin features</a>

// We just want to render this if we're doing a release
<h1>We're currently doing a release and will be back soon</h1>
`;

  const code = `import { FeatureFlagComponent } from '@nebulr-group/nblocks-react';

<FeatureFlagComponent flagKey="premium-features">
    <span>Premium content</span>
</FeatureFlagComponent>
<FeatureFlagComponent flagKey="admin-features">
    <a href="/beta">Button to admin features</a>
</FeatureFlagComponent>
<FeatureFlagComponent flagKey="release-announcement">
    <h1>We're currently doing a release and will be back soon</h1>
</FeatureFlagComponent>
`;

  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        Use the FeatureFlag component
      </HeadingComponent>
      <TextComponent>
        {t(
          'Imagine you have a component that looks something like this:',
        )}
        :
      </TextComponent>
      <CodeBlock code={beforeComponent} language="typescript" copyBtn={false}/>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingLogin"
          defaults="Now you can use the <0>FeatureFlagComponent</0> from the plugin to conditionally show these components in your React app."
          components={[<InlineCode>FeatureFlagComponent</InlineCode>]}
        />
      </TextComponent>
      <CodeBlock code={code} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const FeatureFlagsReactPluginSteps: OnboardingStepType[] = [
  {
    label: 'Install plugin',
    component: InstallStep,
    nextButtonLabel: 'Next',
  },
  {
    label: 'Use the FeatureFlag component',
    component: ComponentsStep,
    nextButtonLabel: 'Done',
    // nextButtonLabel: 'Test',
  },
  {
    label: 'Done',
    // label: 'Test',
    component: TestStep,
    nextButtonLabel: 'Finish',
    onboardingMutationVariables: { featureFlags: { test: true } },
  },
];

export { FeatureFlagsReactPluginSteps };
