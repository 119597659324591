import { HomeIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

export interface Crump {
  name: string;
  href: string;
  active: boolean;
}

const BreadcrumpsComponent = ({ crumps }: { crumps: Crump[] }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <div className="h-5 w-5 flex-shrink-0 text-gray-400">
          <HomeIcon />
        </div>
        {crumps.map((crump, i) => (
          <li key={crump.name}>
            <div className="flex items-center">
              {i > 0 && (
                <svg
                  className="h-5 w-5 flex-shrink-0 text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              )}
              <Link
                to={crump.href}
                className={`${i != 0 ? 'ml-4 ' : ''}text-sm font-medium ${
                  crump.active ? 'text-gray-700' : 'text-gray-500'
                } hover:text-gray-700`}
                aria-current={crump.active ? 'page' : undefined}
                reloadDocument
              >
                {crump.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export { BreadcrumpsComponent };
