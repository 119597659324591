import React, { FunctionComponent, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import {
  GetAppPlansDocument,
  PlanGraphql,
  CreateAppPlanDocument,
  UpdateAppPlanDocument,
  PlanGraphqlInput,
  DeleteAppPlanDocument,
  PriceGraphql,
} from 'src/gql/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { PlanModalComponent, SaveArgs } from './plan.modal.component';
import { ApolloUtils } from 'src/shared/apollo-utils';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import type { ColumnDef } from '@tanstack/react-table';
import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import { useTranslation } from 'react-i18next';
import { CopyChipComponent } from '../access-role/copy.button.component';
import { DateTimeComponent } from '../shared/date/date-time-component';
import { TableComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TableComponent';
import { PlanSignupLinkComponent } from '../authentication/signup.component';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const priceOneLiner = (price: PriceGraphql) => {
  return `${price.amount} ${price.currency}/${price.recurrenceInterval}`;
};

const PlansComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editPlan, setEditPlan] = useState<PlanGraphql>();
  const { t } = useTranslation();
  const { setSuccessMessage } = useFeedback();
  const { appId } = useCurrentApp();
  const { data, loading } = useQuery(GetAppPlansDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });
  const [createAppPlanMutation, createAppPlanData] = useMutation(
    CreateAppPlanDocument,
  );
  const [updateAppPlanMutation, updateAppPlanData] = useMutation(
    UpdateAppPlanDocument,
  );
  const [deleteAppPlanMutation, deleteAppPlanData] = useMutation(
    DeleteAppPlanDocument,
  );

  const isLoading =
    loading ||
    createAppPlanData.loading ||
    updateAppPlanData.loading ||
    deleteAppPlanData.loading;

  const onDidClickDelete = async (row: PlanGraphql) => {
    if (appId && row.id) {
      await deleteAppPlanMutation({
        variables: { appId, id: row.id },
        refetchQueries: [GetAppPlansDocument],
      });
    }
  };

  const didClickCreateBtn = () => {
    setEditPlan(undefined);
    setModalOpen(true);
  };

  const didClickEditBtn = (row: PlanGraphql) => {
    setEditPlan(row);
    setModalOpen(true);
  };

  const didClickModalSave = async (args: SaveArgs) => {
    const plan: PlanGraphqlInput =
      ApolloUtils.removeTypeName<PlanGraphql>(args);

    if (appId) {
      if (editPlan) {
        await updateAppPlanMutation({
          variables: {
            appId,
            id: editPlan.id,
            plan,
          },
          refetchQueries: [GetAppPlansDocument],
        });
        setSuccessMessage('The plan was updated!');
      } else {
        await createAppPlanMutation({
          variables: {
            appId,
            plan,
          },
          refetchQueries: [GetAppPlansDocument],
        });
        setSuccessMessage('A new plan was created!');
      }
    }
    setModalOpen(false);
  };

  const EditComponent: FunctionComponent<{ row: PlanGraphql }> = ({ row }) => {
    const options: Option[] = [
      {
        label: 'Edit',
        onClick: () => {
          didClickEditBtn(row);
        },
        labelPosition: 'start',
      },
    ];

    options.push({
      label: 'Delete',
      type: 'danger',
      onClick: () => {
        onDidClickDelete(row);
      },
      labelPosition: 'start',
    });

    return (
      <div className="flex items-center justify-end">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  const cols: ColumnDef<PlanGraphql>[] = [
    {
      header: t('Name'),
      cell: ({ row }) => {
        return (
          <>
            <span>{row.original.name}</span>
            {row.original.trial && (
              <ChipComponent className="ml-2" type={'primary'}>
                {t('trial')}
              </ChipComponent>
            )}
          </>
        );
      },
      size: 150,
    },
    {
      header: t('Key'),
      cell: (cell) => (
        <CopyChipComponent value={cell.renderValue() as string} />
      ),
      accessorKey: 'key',
      size: 150,
    },
    {
      header: t('Pricing'),
      cell: ({ row }) =>
        row.original.prices.map((price) => (
          <ChipComponent
            key={`${price.amount}-${price.currency}-${price.recurrenceInterval}`}
            className="ml-2"
            type={'primary'}
          >
            {price.amount > 0 ? priceOneLiner(price) : t('Free')}
          </ChipComponent>
        )),
      size: 300,
    },
    {
      header: t('Links'),
      cell: ({ row }) => <PlanSignupLinkComponent plan={row.original} />,
      size: 200,
    },
    {
      header: t('Created'),
      cell: ({ row }) => <DateTimeComponent value={row.original.createdAt} />,
      size: 200,
    },
    {
      id: 'edit',
      header: '',
      cell: ({ row }) => {
        return <EditComponent row={row.original} />;
      },
      size: 100,
    },
  ];

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="flex flex-col">
          <SubHeadingComponent type={'primary'} size={'2xl'}>
            Plans
          </SubHeadingComponent>

          <TextComponent size={'base'} colorName="text-gray-500">
            {t('Create and manage plans')}
          </TextComponent>
        </div>
        <div className="flex flex-col justify-center">
          <NblocksButton
            size="md"
            className="justify-self-center"
            type="primary"
            onClick={() => didClickCreateBtn()}
          >
            Create plan
          </NblocksButton>
        </div>
      </div>
      <TableComponent
        columns={cols}
        data={data?.getAppPlans}
        loading={isLoading}
        defaultPageSize={10}
      />
      <ModalComponent
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        heading={editPlan ? 'Edit plan' : 'New plan'}
      >
        <PlanModalComponent
          didClickSave={(args) => didClickModalSave(args)}
          didClickCancel={() => setModalOpen(false)}
          editPlan={editPlan}
        />
      </ModalComponent>
    </div>
  );
};

export { PlansComponent, priceOneLiner };
