import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useListUsers } from './use-list-users';

const useCurrentTenantUser = () => {
  const [tenantUserId, setTenantUserId] = useState<string | undefined>();
  const { data, loading } = useListUsers();
  const params = useParams();

  useEffect(() => {
    if (params.tenantUserId) {
      setTenantUserId(params.tenantUserId);
    }
  }, [params]);

  return {
    tenantUserId: tenantUserId,
    tenantUser:
      !loading && data
        ? data.listUsers.find((t) => t.id === tenantUserId)
        : undefined,
  };
};

export { useCurrentTenantUser };
