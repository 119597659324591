import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { GetCssFileDocument, UpdateCssFileDocument } from 'src/gql/graphql';
import { CopyChipComponent } from 'src/components/access-role/copy.button.component';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { TextareaComponent } from 'src/components/shared/textarea/textarea.component';

interface BrandingCustomComponent {
  appId: string;
}

const BrandingCustomComponent = ({ appId }: BrandingCustomComponent) => {
  const { t } = useTranslation();

  const [cssFile, setCssFile] = React.useState<string | null | undefined>();

  useQuery(GetCssFileDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId },
    onCompleted: (data) => {
      setCssFile(data.getCssFile.cssFile);
    },
  });

  const [updateCssFile] = useMutation(UpdateCssFileDocument, {
    variables: { appId: appId, input: { cssFile } },
  });

  return (
    <>
      {cssFile && (
        <div className="flex flex-col w-full">
          <div className="relative my-2">
            <TextareaComponent
              id="cssFile"
              rows={15}
              value={cssFile}
              onChange={(value) => {
                setCssFile(value);
              }}
            />
            <div className="absolute top-4 right-4">
              <CopyChipComponent label={'Copy'} value={cssFile} />
            </div>
          </div>
          <div className="w-1/5 my-2">
            <NblocksButton
              size="sm"
              className="h-12"
              type="primary"
              onClick={() => updateCssFile()}
            >
              {t('Save')}
            </NblocksButton>
          </div>
        </div>
      )}
    </>
  );
};

export { BrandingCustomComponent };
