import React, { FunctionComponent, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { SaveArgs, SegmentModalComponent } from './segment.modal.component';
import {
  CreateSegmentDocument,
  DeleteSegmentDocument,
  ListFlagsDocument,
  ListSegmentsDocument,
  SegmentInput,
  SegmentOutput,
  Target,
  UpdateSegmentDocument,
} from 'src/gql/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { ApolloUtils } from 'src/shared/apollo-utils';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import type { ColumnDef } from '@tanstack/react-table';
import { TableComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TableComponent';
import { CopyChipComponent } from '../access-role/copy.button.component';
import { useTranslation } from 'react-i18next';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import { fields, operators, reverseTarget } from './segment-target.component';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const SegmentsComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editSegment, setEditSegment] = useState<SegmentOutput>();
  const { setSuccessMessage } = useFeedback();
  const { t } = useTranslation();
  const { appId } = useCurrentApp();
  const { data, loading } = useQuery(ListSegmentsDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });
  const [updateSegmentMutation, updateSegmentData] = useMutation(
    UpdateSegmentDocument,
  );
  const [createSegmentMutation, createSegmentData] = useMutation(
    CreateSegmentDocument,
  );
  const [deleteSegmentMutation, deleteSegmentData] = useMutation(
    DeleteSegmentDocument,
  );

  const isLoading =
    loading ||
    updateSegmentData.loading ||
    createSegmentData.loading ||
    deleteSegmentData.loading;

  const onDidClickDelete = async (row: SegmentOutput) => {
    await deleteSegmentMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { id: row.id, appId: appId! },
      refetchQueries: [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { query: ListSegmentsDocument, variables: { appId: appId! } },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { query: ListFlagsDocument, variables: { appId: appId! } },
      ],
    });
  };

  const didClickCreateBtn = () => {
    setEditSegment(undefined);
    setModalOpen(true);
  };

  const didClickEditBtn = (row: SegmentOutput) => {
    setEditSegment(row);
    setModalOpen(true);
  };

  const didClickModalSave = async (args: SaveArgs) => {
    if (editSegment) {
      await updateSegment({
        ...args,
        id: editSegment.id,
      });
    } else {
      await createSegment(args);
    }
    setModalOpen(false);
  };

  const createSegment = async (args: SaveArgs) => {
    const { key: id, description, targets } = args;
    await createSegmentMutation({
      variables: {
        segment: { key: id, description, targets },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: appId!,
      },
      refetchQueries: [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { query: ListSegmentsDocument, variables: { appId: appId! } },
      ],
    });
    setSuccessMessage(t('A new group was created!'));
  };
  const updateSegment = async (update: SegmentInput) => {
    const segment = ApolloUtils.removeTypeName<SegmentOutput>(update);
    await updateSegmentMutation({
      variables: {
        id: segment.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: appId!,
        segment: {
          ...segment,
        },
      },
    });
    setSuccessMessage(t('The group was updated!'));
  };

  const EditComponent: FunctionComponent<{ row: SegmentOutput }> = ({
    row,
  }) => {
    const options: Option[] = [
      {
        label: 'Edit',
        onClick: () => {
          didClickEditBtn(row);
        },
        labelPosition: 'start',
      },
      {
        label: 'Delete',
        type: 'danger',
        onClick: () => {
          onDidClickDelete(row);
        },
        labelPosition: 'start',
      },
    ];

    return (
      <div className="flex items-center justify-end">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  const cols: ColumnDef<SegmentOutput>[] = [
    {
      header: t('Key'),
      cell: (cell) => (
        <CopyChipComponent
          value={cell.renderValue() as string}
        ></CopyChipComponent>
      ),
      accessorKey: 'key',
      size: 150,
    },
    {
      header: t('Description'),
      cell: (cell) => (
        <span className="block pr-4">{cell.renderValue() as string}</span>
      ),
      accessorKey: 'description',
      size: 250,
    },
    {
      header: t('Targets'),
      cell: ({ row }) => {
        const targets = row.original.targets || [];
        if (targets.length == 0)
          return (
            <ChipComponent className="ml-2" type={'primary'}>
              {t('No targets')}
            </ChipComponent>
          );
        else
          return targets.map((target, i) => (
            <div key={`segment-target-${i}`} className="mb-4">
              <SegmentTargetChip target={target} />
              {i < targets.length - 1 && (
                <ChipComponent type="primary" className="ml-2">
                  <span className="text-purple-600">{t('and')}</span>
                </ChipComponent>
              )}
            </div>
          ));
      },
      size: 200,
    },
    {
      id: 'edit',
      header: '',
      cell: ({ row }) => {
        return <EditComponent row={row.original} />;
      },
      size: 100,
    },
  ];

  const hasCustomTargets =
    editSegment?.targets?.some((t) => targetIsCustom(t)) || false;

  return (
    <div className="space-y-6">
      <div className="flex justify-end">
        <NblocksButton
          size="md"
          className=""
          type="primary"
          onClick={() => didClickCreateBtn()}
        >
          Create group
        </NblocksButton>
      </div>
      <TableComponent
        columns={cols}
        data={data?.listSegments}
        loading={isLoading}
        defaultPageSize={10}
      />
      <ModalComponent
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        heading={editSegment ? 'Edit group' : 'New group'}
      >
        <SegmentModalComponent
          didClickSave={(args) => didClickModalSave(args)}
          didClickCancel={() => setModalOpen(false)}
          editSegment={editSegment}
          custom={hasCustomTargets}
        />
      </ModalComponent>
    </div>
  );
};

// As of now, we render info that this is a custom target via API and refer to the API
const targetIsCustom = (target: Target) => {
  return !target.device && !target.org && !target.tenant && !target.user;
};

const SegmentTargetChip: FunctionComponent<{
  target: Target;
}> = ({ target }) => {
  const reversed = reverseTarget(target);
  const customTarget = targetIsCustom(target);
  const { t } = useTranslation();

  // As of now, we render info that this is a custom target via API and refer to the API
  if (customTarget) {
    return (
      <div className="mb-4">
        <ChipComponent type="tertiary" className="space-x-1">
          <span>{t('Custom target via API')}</span>
        </ChipComponent>
      </div>
    );
  }

  const fieldsWithLegacy = [
    // Legacy support
    { value: 'org-plan', label: 'Workspace plan' },
    { value: 'org-key', label: 'Workspace key' },
    { value: 'org-id', label: 'Workspace ID' },
    { value: 'org-name', label: 'Workspace name' },
    // End legacy support
    ...fields,
  ];

  const field = fieldsWithLegacy.find(
    (f) => f.value === reversed?.field,
  )?.label;
  const operator = operators.find((o) => o.value === reversed?.operator)?.label;
  const value = reversed?.value;

  return (
    <ChipComponent type="tertiary" className="space-x-1">
      <span>{field}</span>
      <span className="text-purple-600">{operator?.toLowerCase()}</span>
      <span>{value}</span>
    </ChipComponent>
  );
};

export { SegmentsComponent };
