import { useSecureContext } from '@nebulr-group/nblocks-react';
import { AlertComponent } from '@nebulr-group/nblocks-react/lib/components/shared/AlertComponent';
import { ClientError } from '@nebulr-group/nblocks-react/lib/utils/errors/ClientError';
import React, { useEffect, useState, Fragment } from 'react';
import { GraphQLError } from 'graphql';
import { useTranslation } from 'react-i18next';
import { Popover, Transition } from '@headlessui/react';
import { useFeedback } from 'src/shared/hooks/use-feedback';

type FeedbackType = 'danger' | 'primary' | 'secondary' | 'success' | 'warning';

const FeedbackComponent = () => {
  const displayMs = 5000; // 10000 milliseconds = 10 seconds
  const { t } = useTranslation();
  const { authApolloClient, authHttpClient } = useSecureContext();
  const [messages, setMessages] = useState<string[]>([]);
  const [type, setType] = useState<FeedbackType | undefined>();
  const [open, setOpen] = useState(false);

  const { successMessage, setSuccessMessage } = useFeedback();

  // Register the error listners
  useEffect(() => {
    authHttpClient.setForbiddenCallback(onForbidden);
    authHttpClient.setErrorCallback(onError);

    authApolloClient.setForbiddenCallback(onForbidden);
    authApolloClient.setErrorCallback(onError);
  }, []);

  useEffect(() => {
    if (successMessage) {
      openFeedback([successMessage], 'success');
    }
  }, [successMessage]);

  // Handle auto close afte 10 seconds
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        closeFeedback();
      }, displayMs);

      // Cleanup the timer if the component unmounts or if open changes
      return () => clearTimeout(timer);
    }
  }, [open]);

  const onForbidden = () => {
    console.log('I cought something forbidden!!!');
    openFeedback([t("You're not allowed to do this")], 'danger');
  };

  const onError = (error?: ClientError | GraphQLError) => {
    console.log('I cought an error', error);
    openFeedback(
      [
        error?.message || t('An error occured...'),
        t(
          "We've recorded this incident and the team will follow up on this as soon as possible",
        ),
      ],
      'danger',
    );
  };

  const openFeedback = (messages: string[], type: FeedbackType) => {
    setMessages(messages);
    setType(type);
    setOpen(true);
  };

  const closeFeedback = () => {
    setMessages([]);
    setSuccessMessage(); // Clear success message so that another one can happen again
    setOpen(false);
  };

  return (
    <div className="fixed bottom-0 mb-2 mr-2 right-0 w-2/6">
      <Popover className="relative">
        <Transition
          show={open}
          as={Fragment}
          enter="transition transform duration-300 ease-out"
          enterFrom="translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="transition transform duration-300 ease-in"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="translate-x-full opacity-0"
        >
          <Popover.Panel className="" static={open}>
            <AlertComponent
              closable={true}
              onCloseClick={closeFeedback}
              type={type}
              title={type === 'danger' ? t('Oh no!') : ''}
              messages={messages}
            ></AlertComponent>
            {/* <div className="overflow-hidden rounded-full bg-gray-200">
              <div
                className="h-2 rounded-full bg-indigo-600"
                style={{ width: '37.5%' }}
              />
            </div> */}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export { FeedbackComponent };
