import React, { FunctionComponent, useEffect, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { TogglerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TogglerComponent';
import { Switch } from '@headlessui/react';
import {
  MultiOptions,
  Selectable,
} from '../shared/options/multi-options.component';
import { FlagOutput, ListSegmentsDocument } from 'src/gql/graphql';
import { useQuery } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { Util } from 'src/shared/util';
import { DividerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/DividerComponent';
import { useTranslation } from 'react-i18next';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';

export interface ComponentProps {
  didClickSave: (args: SaveArgs) => void;
  didClickCancel: () => void;
  editFlag?: FlagOutput;
}

export interface SaveArgs {
  key: string;
  enabled: boolean;
  defaultValue: boolean;
  targetValue: boolean;
  description: string;
  segments: string[];
}

const FlagModalComponent = ({
  didClickSave,
  didClickCancel,
  editFlag,
}: ComponentProps) => {
  const [active, setActive] = useState(true);
  const [invert, setInvert] = useState(false);

  const [key, setKey] = useState('');
  const [description, setDescription] = useState('');
  const [defaultValue, setDefaultValue] = useState(true);
  const [targetValue, setTargetValue] = useState(false);
  const [segments, setSegments] = useState<string[]>([]);
  const [segmentsResolved, setSegmentsResolved] = useState(false);
  const { t } = useTranslation();
  const { appId } = useCurrentApp();
  const { data, loading } = useQuery(ListSegmentsDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });

  useEffect(() => {
    if (editFlag) {
      setKey(editFlag.key);
      setDescription(editFlag.description);
      setDefaultValue(!!editFlag.defaultValue);
      setTargetValue(!!editFlag.targetValue);
      setSegments(editFlag.segments.map((s) => s.id));
      setSegmentsResolved(true);

      const uiLogic = getUIValues(
        {
          defaultValue: !!editFlag.defaultValue,
          targetValue: !!editFlag.targetValue,
        },
        editFlag.segments.length > 0,
      );
      setActive(uiLogic.active);
      setInvert(uiLogic.invert);
    } else {
      setSegmentsResolved(true);
    }
  }, [editFlag]);

  const onDidClickSave = () => {
    didClickSave({
      key: key,
      enabled: true,
      defaultValue,
      description,
      targetValue,
      segments,
    });
  };

  useEffect(() => {
    const logic = getLogicValues({ active, invert }, segments.length > 0);
    setDefaultValue(logic.defaultValue);
    setTargetValue(logic.targetValue);
  }, [active, invert, segments]);

  const formValid = key;

  const listSegments: Selectable[] = data
    ? data.listSegments.map((s) => {
        return { label: s.key, value: s.id };
      })
    : [];

  const selectedSegments: Selectable[] = listSegments.filter((s) =>
    segments.includes(s.value),
  );

  const onDidSelectSegments = (selectables: Selectable[]) => {
    setSegments(selectables.map((s) => s.value));
  };

  return (
    <div>
      <div>
        <form
          onSubmit={() => onDidClickSave()}
          className="space-y-6 max-w-sm w-full"
        >
          <InputComponent
            type="text"
            label="Flag key*"
            placeholder="E.g. premium-feature"
            name="key"
            onChange={(event) =>
              setKey(Util.cleanKeyInput(event.target.value, false))
            }
            value={key}
          />
          <InputComponent
            type="text"
            label="Description (optional)"
            placeholder="E.g. This flag hides / shows premium content"
            name="description"
            onChange={(event) => setDescription(event.target.value)}
            value={description}
          />
          <Switch.Group>
            <div className="flex items-center">
              <Switch.Label className="mr-4">
                {t('Flag is Active')}
              </Switch.Label>
              <TogglerComponent
                name="active"
                enabled={active}
                setEnabled={setActive}
              ></TogglerComponent>
            </div>
          </Switch.Group>

          <DividerComponent text={t('Groups')} />
          {loading && <SkeletonLoader className="h-12 w-full rounded-md" />}
          {/** See MultiOptions comment why this fix is required */}
          {!loading && segmentsResolved && (
            <MultiOptions
              label=""
              name="segments"
              data={listSegments}
              init={selectedSegments}
              didSelect={(selectables) => onDidSelectSegments(selectables)}
            />
          )}
          {segments.length > 0 && (
            <Switch.Group>
              <div className="flex items-center">
                <Switch.Label className="mr-4">
                  {t('Invert selection')}
                </Switch.Label>
                <TogglerComponent
                  name="invert"
                  enabled={invert}
                  setEnabled={(val) => setInvert(val)}
                ></TogglerComponent>
              </div>
            </Switch.Group>
          )}
          <DividerComponent />
          <FlagActiveDescription
            defaultValue={defaultValue}
            targetValue={targetValue}
            segments={segments}
          />
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {'Cancel'}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSave()}
          disabled={!formValid}
        >
          {'Save changes'}
        </NblocksButton>
      </div>
    </div>
  );
};

const FlagActiveDescription: FunctionComponent<{
  defaultValue: boolean;
  targetValue: boolean;
  segments: string[];
}> = ({ segments, defaultValue, targetValue }) => {
  const { t } = useTranslation();

  const getActiveText = (val: boolean) => {
    return val ? t('Active') : t('Inactive');
  };

  const message =
    segments.length > 0
      ? t(
          'Flag is {{targetStatus}} for selected groups and {{defaultStatus}} for everyone else',
          {
            targetStatus: getActiveText(targetValue),
            defaultStatus: getActiveText(defaultValue),
          },
        )
      : t('Flag is {{defaultStatus}} for everyone', {
          defaultStatus: getActiveText(defaultValue),
        });

  return (
    <TextComponent size={'base'} colorName="text-gray-500">
      {message}
    </TextComponent>
  );
};

type LogicValues = {
  defaultValue: boolean;
  targetValue: boolean;
};
type UIValues = { active: boolean; invert: boolean };

/** These are tested (but cloned) in flag.modal.component.spec.ts */
const getLogicValues = (args: UIValues, hasSegments: boolean): LogicValues => {
  const { active, invert } = args;
  const targetValue = hasSegments && active && !invert;
  const defaultValue = (!hasSegments && active) || (hasSegments && invert);
  return { defaultValue, targetValue };
};

/** These are tested (but cloned) in flag.modal.component.spec.ts */
const getUIValues = (args: LogicValues, hasSegments: boolean): UIValues => {
  const { defaultValue, targetValue } = args;
  const active = defaultValue || targetValue;
  const invert = hasSegments && !targetValue && targetValue != defaultValue;
  return { active, invert };
};

export { FlagModalComponent };
