import './style.css';

const SplashComponent = () => {
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <img
        className="pulse"
        src="https://public.nblocks.dev/assets/logos/nblocks-logo-black-text.png"
      ></img>
    </div>
  );
};

export { SplashComponent };
