import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  ConfigCardsComponentProps,
  ToggleCardComponent,
} from '../../shared/toggle-card/toggle-card.component';
import { SocialProviderCardProps } from '../social.component';
import { FacebookLogo } from '@nebulr-group/nblocks-react/lib/components/auth/shared/logos/facebook.logo';
import {
  UpdateAppEnabledAuthDocument,
  UpdateCredentialsDocument,
} from 'src/gql/graphql';
import { useMutation } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { CopyChipComponent } from 'src/components/access-role/copy.button.component';
import { Config } from 'src/shared/Config';

const FacebookToggleCard = ({ data }: SocialProviderCardProps) => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const emptyState = {
    facebookClientId: '',
    facebookClientSecret: '',
  };
  const [credentials, setCredentials] = useState<{
    facebookClientId: string;
    facebookClientSecret: string;
  }>(emptyState);

  const [updateCredentialsMutation, updateCredentialsData] = useMutation(
    UpdateCredentialsDocument,
  );

  const { data: credentialsUpdateData, loading: credentialsUpdateLoading } =
    updateCredentialsData;

  const [updateEnabledAuthMutation, updateEnabledAuthData] = useMutation(
    UpdateAppEnabledAuthDocument,
  );

  const { data: enableUpdateData, loading: enableUpdateLoading } =
    updateEnabledAuthData;

  const isLoading = enableUpdateLoading || credentialsUpdateLoading;

  const onEnabledChange = async (enabled: boolean) => {
    await updateEnabledAuthMutation({
      variables: {
        id: appId!,
        app: {
          facebookSsoEnabled: enabled,
        },
      },
    });
  };

  const onDelete = async () => {
    setCredentials(emptyState);
    await updateCredentialsMutation({
      variables: {
        id: appId!,
        credentials: emptyState,
      },
    });
    await updateEnabledAuthMutation({
      variables: {
        id: appId!,
        app: {
          facebookSsoEnabled: false,
        },
      },
    });
  };

  const didClickModalSave = async () => {
    await updateCredentialsMutation({
      variables: {
        id: appId!,
        credentials: {
          ...credentials,
        },
      },
    });
    setCredentials(emptyState);
  };

  const handleInputChange = (field: string, value: string) => {
    setCredentials((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const cardProps: ConfigCardsComponentProps = {
    logo: <FacebookLogo />,
    label: 'Facebook',
    description: t('Allow authentication using Facebook'),
    isEnabled: enableUpdateData
      ? enableUpdateData.updateApp.facebookSsoEnabled
      : data.getApp.facebookSsoEnabled,
    onEnabledChange,
    isLoading,
    modalProps: {
      enableModalHeading: t('Enable Facebook'),
      editModalHeading: t('Update Facebook credentials'),
      modalDescription: (
        <TextComponent>
          {t('You can find your Facebook credentials')}{' '}
          <LinkComponent
            target="_blank"
            to="https://developers.facebook.com/apps/"
            type="primary"
            nativeBehavior
          >
            {t('here')}
          </LinkComponent>
        </TextComponent>
      ),
      modalFooter: (
        <div>
          <HeadingComponent type={'h3'} size="lg">
            {t('Add email permission')}
          </HeadingComponent>
          <TextComponent size="sm" colorName="text-gray-500">
            {t(
              'Add the email permission to your app in Meta for Developers › Apps › Use cases › Customize',
            )}
          </TextComponent>
          <HeadingComponent type={'h3'} size="lg">
            {t('Redirect URL')}
          </HeadingComponent>
          <TextComponent size="sm" colorName="text-gray-500">
            {t(
              'Add this url in Meta for Developers › Apps › Use cases › Customize › Settings › Valid OAuth Redirect URIs',
            )}
            <CopyChipComponent
              value={`${Config.getOauthApiUrl()}/federated/facebook/return`}
            />
          </TextComponent>
        </div>
      ),
      deleteModalHeading: t('Do you want to delete your Facebook credentials?'),
      deleteModalPrompt: t(
        'Deleting your credentials will disable Facebook from your app until you reactivate it again with new credentials.',
      ),
      credentialsAdded: credentialsUpdateData
        ? credentialsUpdateData.updateCredentials.facebookSsoCredentialsAdded
        : data.getAppCredentialsState.facebookSsoCredentialsAdded,
      didClickModalSave: didClickModalSave,
      onModalChange: handleInputChange,
      onDelete: onDelete,
      modalInputFields: [
        {
          type: 'text',
          label: t('Client ID'),
          placeholder: '',
          caption: t(
            'Enter the client ID from Meta for Developers › Apps › App settings › Basic › App ID',
          ),
          field: 'facebookClientId',
          value: credentials.facebookClientId,
        },
        {
          type: 'password',
          label: t('Client secret'),
          placeholder: '',
          caption: t(
            'Enter the client secret from Meta for Developers › Apps › App settings › Basic › App secret',
          ),
          field: 'facebookClientSecret',
          value: credentials.facebookClientSecret,
        },
      ],
      modalValid:
        credentials.facebookClientId.length > 0 &&
        credentials.facebookClientSecret.length > 0,
    },
  };

  return <ToggleCardComponent {...cardProps} />;
};

export { FacebookToggleCard };
