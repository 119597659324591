import React, { FunctionComponent } from 'react';
import { CodeBlock } from 'src/components/shared/code/code-block';
import { OnboardingStepType } from '../onboarding.modal.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Trans } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { InlineCode } from 'src/components/shared/code/inline-code';
import { Config } from 'src/shared/Config';
import { TestStep } from './user-readiness-test.component';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';

const LoginRedirectStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const loginCode = `import { useEffect } from "react";

export default function Login() {

  const APP_ID = "${appId}";

  // Immediately redirect the web browser to Nblocks login
  window.location.replace(\`${Config.getOauthApiUrl()}/url/login/\${APP_ID}\`);
  return ("");
}
`;

  const logoutCode = `import { useEffect } from "react";

export default function Logout() {

  const APP_ID = "${appId}";

  useEffect(() => {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('id_token');

    // Immediately redirect the web browser to Nblocks logout
    window.location.replace(\`${Config.getOauthApiUrl()}/url/logout/\${APP_ID}\`);
  })

  return ("");
}
`;

  const reactRouterCode = `import { Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <div>
      <Routes>
        ...
        <!-- Create new routes that renders the login and logout component -->
        <Route path="/login" element={ <Login /> }/>
        <Route path="/logout" element={ <Logout /> }/>
      </Routes>
    </div>
  );
}
`;

  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        1. Create a login component
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingLogin"
          defaults="Create a new component that we call <0>Login</0>."
          components={[<InlineCode>login</InlineCode>]}
        />
      </TextComponent>
      <CodeBlock code={loginCode} language="typescript" />
      <HeadingComponent type="h2" size="xl">
        2. Create a logout component
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingLogin"
          defaults="Create a new component that we call <0>Logout</0>."
          components={[<InlineCode>logout</InlineCode>]}
        />
      </TextComponent>
      <CodeBlock code={logoutCode} language="typescript" />
      <HeadingComponent type="h2" size="xl">
        3. Create two routes and add the components
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingRouting"
          defaults="Add the login component to a new route <0>/login</0> and the logout component to <1>/logout</1>. Here's an example adding the new route using the <2>react-router</2> plugin."
          components={[
            <InlineCode>/login</InlineCode>,
            <InlineCode>/logout</InlineCode>,
            <InlineCode>react-router</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={reactRouterCode} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const CallbackStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const callbackCode = `import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { jwtVerify, createRemoteJWKSet } from "jose";

// Users will get back to this component after finishing login
export default function Callback() {

  const APP_ID = "${appId}";

  const location = useLocation();
  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    const code = new URLSearchParams(location.search).get("code");
    if (code) {
      handleCallback(code);
    }
  }, []);

  const handleCallback = async (code) => {
    // Get tokens
    const tokens = await fetch(\`${Config.getOauthApiUrl()}/token/code/\${APP_ID}\`,
      {
        method: "POST", headers: { "Content-Type": "application/json", },
        body: JSON.stringify({ code }),
      }
    ).then(res => res.json());

    // Verify the tokens result using public keys from Nblocks JWKS
    const { access_token, refresh_token, id_token } = tokens;
    const { payload } = await jwtVerify(
      access_token, createRemoteJWKSet(
          new URL('${Config.getOauthApiUrl()}/.well-known/jwks.json')
      ), { issuer: '${Config.getOauthApiUrl()}' }
    );

    // Store the result in component state and localstorage
    window.localStorage.setItem('access_token', access_token);
    window.localStorage.setItem('refresh_token', refresh_token);
    window.localStorage.setItem('id_token', id_token);
    setAccessToken(payload);
    console.log("User access token", payload);
  };

  if (accessToken)
      return (<Navigate to={"/"}/>);
  else
    return (<p>Loading...</p>);
}
`;

  const reactRouterCode = `import { Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <div>
      <Routes>
        ...
        <!-- Adding a new route /auth/oauth-callback that renders the callback component -->
        <Route path="/auth/oauth-callback" element={ <Callback /> }/>
      </Routes>
    </div>
  );
}
`;

  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        1. Install Jose
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingCallback"
          defaults="Install Jose which is a common JWT util by running <0>npm i jose</0>."
          components={[<InlineCode>npm i jose</InlineCode>]}
        />
      </TextComponent>
      <HeadingComponent type="h2" size="xl">
        2. Create a callback component
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingCallback"
          defaults="Create another component that we call <0>Callback</0>."
          components={[
            <InlineCode>Callback</InlineCode>,
            <InlineCode>/auth/oauth-callback</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={callbackCode} language="typescript" />
      <HeadingComponent type="h2" size="xl">
        3. Create a route and add the component
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingRouting"
          defaults="Add the component to a new route <0>/auth/oauth-callback</0>. In React routes can be created with react-router plugin. Here's an example adding the new route using the <1>react-router</1> plugin."
          components={[
            <InlineCode>/auth/oauth-callback</InlineCode>,
            <InlineCode>react-router</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={reactRouterCode} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const UserReadinessReactVanillaSteps: OnboardingStepType[] = [
  {
    label: 'Login redirect',
    component: LoginRedirectStep,
    nextButtonLabel: 'Next',
  },
  { label: 'Callback', component: CallbackStep, nextButtonLabel: 'Test' },
  {
    label: 'Test',
    component: TestStep,
    nextButtonLabel: 'See your new user!',
    onboardingMutationVariables: { userReadiness: { test: true } },
  },
];

export { UserReadinessReactVanillaSteps };
