export class ApolloUtils {
  static removeTypeName<T>(object: any & { __typename?: string }): T {
    const newObj = { ...object };
    Object.keys(newObj).map((key) => {
      if (newObj[key] && typeof newObj[key] == 'object') {
        if (Array.isArray(newObj[key])) {
          newObj[key] = newObj[key].map((el: any) => {
            if (el && typeof el == 'object') {
              return ApolloUtils.removeTypeName(el);
            } else {
              return el;
            }
          });
        } else {
          newObj[key] = ApolloUtils.removeTypeName(newObj[key]);
        }
      }
    });
    delete newObj['__typename'];
    return newObj;
  }
}
