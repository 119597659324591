import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  ConfigCardsComponentProps,
  ToggleCardComponent,
} from '../../shared/toggle-card/toggle-card.component';
import {
  GetAppPaymentsSettingsQuery,
  UpdateAppPaymentSettingsDocument,
} from 'src/gql/graphql';

export interface PaymentsAutoRedirectToggleCardProps {
  data: GetAppPaymentsSettingsQuery;
}

const PaymentsAutoRedirectToggleCard = ({
  data,
}: PaymentsAutoRedirectToggleCardProps) => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const [updateAppPaymentSettingsMutation, updateAppPaymentSettingsData] =
    useMutation(UpdateAppPaymentSettingsDocument);

  const { data: updateData, loading } = updateAppPaymentSettingsData;

  const onEnabledChange = async (enabled: boolean) => {
    await updateAppPaymentSettingsMutation({
      variables: {
        id: appId!,
        app: {
          paymentsAutoRedirect: enabled,
        },
      },
    });
  };

  const cardProps: ConfigCardsComponentProps = {
    label: t('Activate select payments plans'),
    description: t(
      'Takes a user to selection of payment plans directly after signup or after a trial has ended.',
    ),
    isEnabled: updateData
      ? updateData.updateApp.paymentsAutoRedirect
      : data.getApp.paymentsAutoRedirect,
    onEnabledChange: onEnabledChange,
    isLoading: loading,
  };

  return <ToggleCardComponent {...cardProps} />;
};

export { PaymentsAutoRedirectToggleCard };
