import {
  CurrencyEuroIcon,
  CurrencyDollarIcon,
  CurrencyPoundIcon,
} from '@heroicons/react/24/outline';
import React, { ReactElement } from 'react';

export interface Currency {
  name: string;
  /** https://www.iso.org/iso-4217-currency-codes.html */
  isoCode: string;
  asciIcon?: string;
  icon?: ReactElement;
}

const supportedCurrencies: Currency[] = [
  {
    name: 'Euro',
    isoCode: 'EUR',
    asciIcon: '€',
    icon: <CurrencyEuroIcon className="h-5 w-5" />,
  },
  {
    name: 'US dollar',
    isoCode: 'USD',
    asciIcon: '$',
    icon: <CurrencyDollarIcon className="h-5 w-5" />,
  },
  {
    name: 'Brittish Pound',
    isoCode: 'GBP',
    asciIcon: '£',
    icon: <CurrencyPoundIcon className="h-5 w-5" />,
  },
  {
    name: 'Swedish krona',
    isoCode: 'SEK',
  },
];

export { supportedCurrencies };
