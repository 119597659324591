import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { CreateAppDocument } from 'src/gql/graphql';
import { useNavigate } from 'react-router-dom';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { LoadingSpinner } from 'src/components/shared/spinner/loading-spinner.component';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const CreateAppScreen: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const [createAppMutation, { loading, client: apolloClient }] =
    useMutation(CreateAppDocument);
  const navigate = useNavigate();
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const [appId, setAppId] = useState<string | undefined>(undefined);

  useEffect(() => {
    createApp();
  }, []);

  const createApp = async () => {
    const result = await createAppMutation({ variables: { args: {} } });
    setAppId(result.data?.createApp.app.id);
    setTimeout(() => setShowCheckIcon(true), 1000);
  };

  useEffect(() => {
    if (showCheckIcon) {
      setTimeout(() => {
        doNavigate(appId);
      }, 1500);
    }
  }, [showCheckIcon, appId]);

  const doNavigate = async (appId?: string) => {
    if (appId) {
      await apolloClient.resetStore();
      navigate(`/app/${appId}`);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      {loading || !showCheckIcon ? (
        <LoadingSpinner className="h-32 w-32 text-white fill-indigo-700" />
      ) : (
        <CheckCircleIcon className="h-32 w-32 text-green-500" />
      )}
      <HeadingComponent type={'h1'} size={'xl'}>
        {t('Creating your app...')}
      </HeadingComponent>
    </div>
  );
};

export { CreateAppScreen };
