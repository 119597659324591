import React from 'react';
import { NblocksProvider } from '@nebulr-group/nblocks-react';
import { AppRoutes } from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { Config } from './shared/Config';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FeedbackContextProvider } from './components/feedback/feedback.provider';
import { NblocksClientContextProvider } from './shared/providers/nblocks-client.provider';
import { FlagsContextProvider } from './shared/providers/feature-flags.provider';

function App() {
  const debug = !!localStorage.getItem('NB_DEBUG');

  console.log('Admin UI. Running in env: ' + process.env.REACT_APP_ENV);

  return (
    <BrowserRouter>
      <NblocksProvider
        config={{
          handoverRoute: '/',
          debug,
          authLegacy: false,
          accountApiBaseUri: Config.getAccountApiUrl(),
          oauthRedirectUri: `${Config.getUrl()}/auth/oauth-callback`,
          oAuthBaseURI: Config.getOauthApiUrl(),
          apiHost: Config.getBackendUrl(),
          appId: Config.getAppId(),
        }}
      >
        <NblocksClientContextProvider>
          <FlagsContextProvider>
            <FeedbackContextProvider>
              <>
                <AppRoutes />
                <FeedbackComponent />
              </>
            </FeedbackContextProvider>
          </FlagsContextProvider>
        </NblocksClientContextProvider>
      </NblocksProvider>
    </BrowserRouter>
  );
}

export default App;
