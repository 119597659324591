import { Fragment, ReactElement } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Item } from 'src/screens/app/app.screen';

const MenuOptionsComponent = ({
  menuOptionsButton,
  menuOptionsButtonClassName,
  menuOptionsItems,
}: {
  menuOptionsButton: ReactElement;
  menuOptionsButtonClassName: string;
  menuOptionsItems: Item[];
}) => {
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Open user menu</span>
          <div className={menuOptionsButtonClassName}>{menuOptionsButton}</div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {menuOptionsItems.map((item) => (
            <Menu.Item key={item.name}>
              <a
                href={item.href}
                className={
                  'bg-gray-50 text-indigo-600 text-gray-700 hover:text-indigo-600 hover:bg-gray-50 w-full group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                }
              >
                <div
                  className={
                    'text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0'
                  }
                  aria-hidden="true"
                >
                  {item.icon}
                </div>
                {item.name}
              </a>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export { MenuOptionsComponent };
