import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  BulkEvaluationResponse,
  FlagContext,
} from '@nebulr-group/nblocks-ts-client/dist';
import { useNblocksClient } from '../hooks/user-nblocks-client';
import { useLog } from '@nebulr-group/nblocks-react/lib/hooks/use-log';
import { useAuth, useSecureContext } from '@nebulr-group/nblocks-react';

// I don't think these even are visible to this component since they don't render children
const restrictedPaths = ['/auth/login', '/auth/logout', '/auth/oauth-callback'];

const FlagsContext = React.createContext<
  | {
      /** Check if a given flag is enabled or not */
      flagEnabled: (flagKey: string) => boolean;
      /** Update user context. This operation issues a new bulk evaluation request */
      setContext: (ctx?: FlagContext) => void;
      /** Access to raw storage of all flags */
      flagsStorage: BulkEvaluationResponse | undefined;
    }
  | undefined
>(undefined);

const FlagsContextProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { authenticated, authService } = useSecureContext();
  const { nblocksClient } = useNblocksClient();
  const { log } = useLog();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [flagsStorage, setFlagsStorage] = useState<
    BulkEvaluationResponse | undefined
  >();

  const restrictedTokenPath = () => {
    const restricted = restrictedPaths.some(
      (path) => path == window.location.pathname,
    );
    if (restricted) {
      log(
        `Current pathname: ${window.location.pathname} is restriced for token refresh operations`,
      );
    }
    return restricted;
  };

  const [context, setContext] = useState<FlagContext | undefined>();

  useEffect(() => {
    // Retrieve the access token JWT from localstorage
    const _accessToken = window.localStorage.getItem('NB_OAUTH_ACCESS_TOKEN');
    if (_accessToken) setAccessToken(_accessToken);
  }, [authenticated]);

  useEffect(() => {
    if (!restrictedTokenPath() && accessToken) {
      doBulkEvaluation();
    } else {
      log(`Will not pull flags on current path, or missing accessToken`);
    }
  }, [accessToken, context]);

  const doBulkEvaluation = async () => {
    try {
      const response = await nblocksClient.flag.bulkEvaluate({
        accessToken,
        context,
      });
      log('Got new flags!');
      setFlagsStorage(response);
    } catch (error) {
      console.error(error);
    }
  };

  // Checks if a specific flag is enabled via storage
  const flagEnabled = (flagKey: string) => {
    if (!flagsStorage) return false;

    return flagsStorage.flags.some(
      (flag) => flag.flag === flagKey && flag.evaluation.enabled,
    );
  };

  // log(`5. Rendering FlagsContextProvider`);

  return (
    <FlagsContext.Provider value={{ flagEnabled, flagsStorage, setContext }}>
      {children}
    </FlagsContext.Provider>
  );
};

export { FlagsContextProvider, FlagsContext };
