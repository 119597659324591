import React from 'react';

import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { ColorPickerComponent, ColorPickerProps } from './color-picker';

type ColorPickerInputProps = {
  label: string;
} & ColorPickerProps;

const ColorPickerInputComponent = ({
  label,
  color,
  onChange,
}: ColorPickerInputProps) => {
  return (
    <div className="flex items-end">
      <div className="w-4/5">
        <InputComponent
          label={label}
          type={'text'}
          value={color.toUpperCase()}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
      <div className="w-1/5 pl-3">
        <ColorPickerComponent color={color} onChange={onChange} />
      </div>
    </div>
  );
};

export { ColorPickerInputComponent };
