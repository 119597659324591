import React from 'react';
import { useTranslation } from 'react-i18next';

import { BrandingInput } from 'src/gql/graphql';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ColorPickerInputComponent } from '../../shared/color-picker/color-picker-input';
import {
  InputUnitComponent,
  InputUnitData,
  Unit,
} from '../../shared/input-unit/input-unit.component';

const designUnits: Unit[] = [
  { value: 'px', label: 'px' },
  { value: '%', label: '%' },
  { value: 'em', label: 'em' },
  { value: 'rem', label: 'rem' },
];

const extractAmountAndUnit = (value: string): InputUnitData => {
  let amount;
  let unit = designUnits[0]; // default to the first unit if no match is found

  // Try to find a unit that matches the end of the value
  designUnits.forEach((u) => {
    if (value.endsWith(u.value)) {
      amount = parseFloat(value.replace(u.value, ''));
      unit = u;
    }
  });

  return { amount: amount ?? 0, unit };
};

interface BrandingFieldsComponentProps {
  branding: BrandingInput;
  setBranding: React.Dispatch<React.SetStateAction<BrandingInput | undefined>>;
  onSave: () => void;
  saveLoading: boolean;
  saveSuccess: boolean;
}

const BrandingFieldsComponent = ({
  branding,
  setBranding,
  saveLoading,
  saveSuccess,
  onSave,
}: BrandingFieldsComponentProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-1/2">
      <div className="w-4/5 my-2">
        <InputComponent
          label={t('Font')}
          type={'text'}
          value={branding.fontFamily}
          onChange={(event) =>
            setBranding({
              ...branding,
              fontFamily: event.target.value,
            })
          }
        />
      </div>
      <div className="w-4/5 my-2">
        <ColorPickerInputComponent
          label={t('Background color')}
          color={branding.bgColor}
          onChange={(color) =>
            setBranding({
              ...branding,
              bgColor: color,
            })
          }
        />
      </div>
      <div className="w-4/5 my-2">
        <ColorPickerInputComponent
          label={t('Text color')}
          color={branding.textColor}
          onChange={(color) =>
            setBranding({
              ...branding,
              textColor: color,
            })
          }
        />
      </div>
      <div className="w-4/5 my-2">
        <ColorPickerInputComponent
          label={t('Link color')}
          color={branding.linkColor}
          onChange={(color) =>
            setBranding({
              ...branding,
              linkColor: color,
            })
          }
        />
      </div>
      <div className="w-4/5 my-2">
        <ColorPickerInputComponent
          label={t('Button background color')}
          color={branding.primaryButtonBgColor}
          onChange={(color) =>
            setBranding({
              ...branding,
              primaryButtonBgColor: color,
            })
          }
        />
      </div>
      <div className="w-4/5 my-2">
        <ColorPickerInputComponent
          label={t('Button text color')}
          color={branding.primaryButtonTextColor}
          onChange={(color) =>
            setBranding({
              ...branding,
              primaryButtonTextColor: color,
            })
          }
        />
      </div>
      <div className="w-4/5 my-2">
        <ColorPickerInputComponent
          label={t('Login alternative background color')}
          color={branding.tertiaryButtonBgColor}
          onChange={(color) =>
            setBranding({
              ...branding,
              tertiaryButtonBgColor: color,
            })
          }
        />
      </div>
      <div className="w-4/5 my-2">
        <ColorPickerInputComponent
          label={t('Login alternative text color')}
          color={branding.tertiaryButtonTextColor}
          onChange={(color) =>
            setBranding({
              ...branding,
              tertiaryButtonTextColor: color,
            })
          }
        />
      </div>
      <div className="w-4/5 my-2">
        <InputUnitComponent
          amountLabel={'Border radius'}
          unitLabel={'Unit'}
          onChange={(data) =>
            setBranding({
              ...branding,
              borderRadius: `${data.amount}${data.unit.value}`,
            })
          }
          initData={extractAmountAndUnit(branding.borderRadius)}
          units={designUnits}
        />
      </div>
      <div className="w-4/5 my-2">
        <InputComponent
          label={t('Drop shadow')}
          type={'text'}
          value={branding.boxShadow}
          onChange={(event) =>
            setBranding({
              ...branding,
              boxShadow: event.target.value,
            })
          }
        />
      </div>
      <div className="w-1/5 my-2">
        <NblocksButton
          size="sm"
          className="h-12"
          type={saveSuccess ? 'success' : 'primary'}
          isLoading={saveLoading}
          onClick={() => onSave()}
        >
          {saveSuccess ? t('Saved!') : t('Save')}
        </NblocksButton>
      </div>
    </div>
  );
};

export { BrandingFieldsComponent };
