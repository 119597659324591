import React, { FunctionComponent, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { FlagModalComponent, SaveArgs } from './flag.modal.component';
import {
  CreateFlagDocument,
  DeleteFlagDocument,
  FlagInput,
  FlagOutput,
  ListFlagsDocument,
  UpdateFlagDocument,
} from 'src/gql/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { ApolloUtils } from 'src/shared/apollo-utils';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import { useTranslation } from 'react-i18next';
import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import { CopyChipComponent } from '../access-role/copy.button.component';
import type { ColumnDef } from '@tanstack/react-table';
import { TableComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TableComponent';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const FlagsComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editFlag, setEditFlag] = useState<FlagOutput>();
  const { setSuccessMessage } = useFeedback();
  const { t } = useTranslation();

  const { appId } = useCurrentApp();
  const { data, loading } = useQuery(ListFlagsDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });
  const [updateFlagMutation, updateFlagData] = useMutation(UpdateFlagDocument);
  const [createFlagMutation, createFlagData] = useMutation(CreateFlagDocument);
  const [deleteFlagMutation, deleteFlagData] = useMutation(DeleteFlagDocument);

  const isLoading =
    loading ||
    updateFlagData.loading ||
    createFlagData.loading ||
    deleteFlagData.loading;

  const onDidClickDelete = async (row: FlagOutput) => {
    await deleteFlagMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { id: row.id, appId: appId! },
      refetchQueries: [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { query: ListFlagsDocument, variables: { appId: appId! } },
      ],
    });
  };

  const didClickCreateBtn = () => {
    setEditFlag(undefined);
    setModalOpen(true);
  };

  const didClickEditBtn = (row: FlagOutput) => {
    setEditFlag(row);
    setModalOpen(true);
  };

  const didClickModalSave = async (args: SaveArgs) => {
    if (editFlag) {
      await updateFlag({ ...args, id: editFlag.id });
    } else {
      await createFlag(args);
    }
    setModalOpen(false);
  };

  const createFlag = async (args: SaveArgs) => {
    const { key, enabled, description, defaultValue, targetValue, segments } =
      args;
    await createFlagMutation({
      variables: {
        flag: {
          key,
          enabled,
          description,
          defaultValue,
          targetValue,
          segments,
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: appId!,
      },
      refetchQueries: [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { query: ListFlagsDocument, variables: { appId: appId! } },
      ],
    });

    setSuccessMessage(t('A new flag was created!'));
  };

  const updateFlag = async (update: FlagInput) => {
    const flag = ApolloUtils.removeTypeName<FlagInput>(update);

    await updateFlagMutation({
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: flag.id!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: appId!,
        flag: {
          ...flag,
        },
      },
    });

    setSuccessMessage(t('The flag was updated!'));
  };

  const EditComponent: FunctionComponent<{ row: FlagOutput }> = ({ row }) => {
    const options: Option[] = [
      {
        label: 'Edit',
        onClick: () => {
          didClickEditBtn(row);
        },
        labelPosition: 'start',
      },
    ];

    options.push({
      label: 'Delete',
      type: 'danger',
      onClick: () => {
        onDidClickDelete(row);
      },
      labelPosition: 'start',
    });

    return (
      <div className="flex items-center justify-end">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  const renderActiveChip = (value: boolean, forSegments: boolean) => {
    if (value)
      return (
        <ChipComponent type="success">
          {forSegments
            ? t('Active for selected groups')
            : t('Active for everyone')}
        </ChipComponent>
      );
    else
      return (
        <ChipComponent type="tertiary">
          {forSegments
            ? t('Inactive for selected groups')
            : t('Inactive for everyone')}
        </ChipComponent>
      );
  };

  const renderActiveChips = (row: FlagOutput) => {
    if (row.segments.length > 0) {
      return <>{renderActiveChip(!!row.targetValue, true)}</>;
    } else {
      return <>{renderActiveChip(!!row.defaultValue, false)}</>;
    }
  };

  const cols: ColumnDef<FlagOutput>[] = [
    {
      header: t('Key'),
      cell: (cell) => (
        <CopyChipComponent
          value={cell.renderValue() as string}
        ></CopyChipComponent>
      ),
      accessorKey: 'key',
      size: 150,
    },
    {
      header: t('Groups'),
      cell: ({ row }) =>
        row.original.segments.length > 0 ? (
          row.original.segments.map((segment, i) => (
            <>
              <ChipComponent
                key={`${row.id}-${segment.id}`}
                className="ml-2"
                type={'primary'}
              >
                {segment.key}
              </ChipComponent>
              {i < row.original.segments.length - 1 && (
                <ChipComponent type="tertiary" className="ml-2">
                  <span className="text-purple-600">{t('or')}</span>
                </ChipComponent>
              )}
            </>
          ))
        ) : (
          <ChipComponent className="ml-2" type={'primary'}>
            everyone
          </ChipComponent>
        ),
      size: 400,
    },
    {
      header: t('Status'),
      cell: ({ row }) => renderActiveChips(row.original),
      size: 100,
    },
    {
      id: 'edit',
      header: '',
      cell: ({ row }) => {
        return <EditComponent row={row.original} />;
      },
      size: 100,
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-end">
        <NblocksButton
          size="md"
          className=""
          type="primary"
          onClick={() => didClickCreateBtn()}
        >
          Create flag
        </NblocksButton>
      </div>
      <TableComponent
        columns={cols}
        data={data?.listFlags}
        loading={isLoading}
        defaultPageSize={10}
      />
      <ModalComponent
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        heading={editFlag ? 'Edit flag' : 'New flag'}
      >
        <FlagModalComponent
          didClickSave={(args) => didClickModalSave(args)}
          didClickCancel={() => setModalOpen(false)}
          editFlag={editFlag}
        />
      </ModalComponent>
    </div>
  );
};

export { FlagsComponent };
