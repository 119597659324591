import React from 'react';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { CopyChipComponent } from 'src/components/access-role/copy.button.component';

const AppIdComponent = () => {
  const { appId } = useCurrentApp();

  return (
    <div className="space-y-6 max-w-md">
      <CopyChipComponent label={appId} value={appId || ''} />
    </div>
  );
};

export { AppIdComponent };
