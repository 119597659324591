import React from 'react';
import {
  OnboardingModalComponent,
  OnboardingTabs,
} from '../onboarding.modal.component';
import { FeatureFlagsReactPluginSteps } from './feature-flags-react-plugin.component';
import { FeatureFlagsReactVanillaSteps } from './feature-flags-react-vanilla.component';
import { FeatureFlagsJavaSteps } from './feature-flags-java.component';
import { FeatureFlagsNextSteps } from './feature-flags-next.component';
import { ReactIcon } from 'src/components/shared/tabs/icons/react-icon';
import { NextJsIcon } from 'src/components/shared/tabs/icons/next-icon';
import { JavaIcon } from 'src/components/shared/tabs/icons/java-icon';

interface ComponentProps {
  didFinishOnboarding: () => void;
}

const FeatureFlagsModalComponent = ({
  didFinishOnboarding,
}: ComponentProps) => {
  const tabs: OnboardingTabs = {
    react: {
      title: 'ReactJS',
      steps: FeatureFlagsReactPluginSteps,
      icon: ReactIcon,
    },
    reactVanilla: {
      title: 'ReactJS (vanilla)',
      steps: FeatureFlagsReactVanillaSteps,
      icon: ReactIcon,
    },
    next: {
      title: 'NextJS',
      steps: FeatureFlagsNextSteps,
      icon: NextJsIcon,
    },
    java: {
      title: 'Java',
      steps: FeatureFlagsJavaSteps,
      icon: JavaIcon,
    },
  };

  return (
    <OnboardingModalComponent
      didFinishOnboarding={didFinishOnboarding}
      tabs={tabs}
    />
  );
};

export { FeatureFlagsModalComponent };
