import { useCurrentApp } from './use-current-app';
import { ListRolesDocument } from 'src/gql/graphql';
import { useQuery } from '@apollo/client';

const useListCurrentRoles = () => {
  const { appId } = useCurrentApp();

  const { data, loading, error } = useQuery(ListRolesDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });

  return { data, loading, error };
};

export { useListCurrentRoles };
