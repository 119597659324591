import React, { useEffect, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { User } from 'src/gql/graphql';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { DividerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/DividerComponent';
import { useTranslation } from 'react-i18next';
import {
  OptionsComponent,
  Selectable,
} from '../../../shared/options/options.component';
import { useListCurrentRoles } from 'src/shared/hooks/use-list-roles';
import { TogglerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TogglerComponent';
import { Switch } from '@headlessui/react';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';

export interface ComponentProps {
  didClickSubmit: (args: SaveArgs) => void;
  didClickCancel: () => void;
  editUser?: User;
  singleOwner?: boolean;
}

export interface SaveArgs {
  username: string;
  role?: string;
  enabled?: boolean;
  firstName?: string;
  lastName?: string;
  muteNotifications?: boolean;
}

const UserModalComponent = ({
  didClickSubmit,
  didClickCancel,
  editUser,
  singleOwner,
}: ComponentProps) => {
  const { t } = useTranslation();
  const { data: rolesQuery, loading } = useListCurrentRoles();

  const [username, setUsername] = useState<string>('');
  const [roleKey, setRoleKey] = useState<string | undefined>();
  const [enabled, setEnabled] = useState(true);

  const [sendNotification, setSendNotification] = useState(true);

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();

  useEffect(() => {
    if (editUser) {
      setUsername(editUser.username || '');
      setEnabled(!!editUser.enabled);
      setRoleKey(editUser.role?.toString());
      setFirstName(editUser.firstName?.toString());
      setLastName(editUser.lastName?.toString());
    }
  }, [editUser]);

  const onDidClickSubmit = () => {
    didClickSubmit({
      username,
      firstName,
      lastName,
      role: roleKey,
      muteNotifications: !sendNotification,
      enabled,
    });
  };

  const roles = rolesQuery?.listRoles || [];
  const defaultRole = roles.find((r) => r.isDefault);

  const rolesOptions: Selectable[] = roles.map((p) => {
    return {
      label: p.name,
      value: p.key,
    };
  });

  const defaultRoleOption = rolesOptions.find(
    (r) => r.value === defaultRole?.key,
  );
  const initRoleOption = editUser
    ? rolesOptions.find((r) => r.value === roleKey)
    : defaultRoleOption;

  const formValid = username;

  return (
    <div>
      <div>
        <form
          onSubmit={() => onDidClickSubmit()}
          className="space-y-6 max-w-sm w-full"
        >
          <InputComponent
            type="email"
            label={t('Email*')}
            placeholder="E.g. john@doe.com"
            name="email"
            onChange={(event) => setUsername(event.target.value)}
            value={username}
            disabled={!!editUser}
          />
          <div className="flex space-x-2">
            <InputComponent
              type="text"
              label={t('First name')}
              placeholder={editUser ? '' : t('E.g. John')}
              name="firstName"
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
              disabled={!!editUser}
            />
            <InputComponent
              type="text"
              label={t('Last name')}
              placeholder={editUser ? '' : t('E.g. Doe')}
              name="lastName"
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
              disabled={!!editUser}
            />
          </div>
          <DividerComponent text={t('Role')} />
          {loading && <SkeletonLoader className="h-24 w-full rounded-md" />}
          {roles.length > 0 && (
            <>
              <OptionsComponent
                data={rolesOptions}
                init={initRoleOption}
                didSelect={(args) => setRoleKey(args.value)}
                disabled={singleOwner}
              />
              {singleOwner && (
                <TextComponent size={'base'} colorName="text-gray-500">
                  {t('At least one user needs to be the workspace Owner')}
                </TextComponent>
              )}
            </>
          )}
          {editUser && (
            <>
              <DividerComponent text={t('Status')} />
              <Switch.Group>
                <div className="flex items-center">
                  <Switch.Label className="mr-4">{t('Enabled')}</Switch.Label>
                  <TogglerComponent
                    name="enabled"
                    enabled={enabled}
                    setEnabled={(value) => setEnabled(value)}
                  />
                </div>
              </Switch.Group>
            </>
          )}
          {!editUser && (
            <>
              <Switch.Group>
                <div className="flex items-center">
                  <Switch.Label className="mr-4">
                    {t('Send notification')}
                  </Switch.Label>
                  <TogglerComponent
                    name="sendNotification"
                    enabled={sendNotification}
                    setEnabled={(value) => setSendNotification(value)}
                  />
                </div>
              </Switch.Group>
            </>
          )}
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {t('Cancel')}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSubmit()}
          disabled={!formValid}
        >
          {editUser ? t('Save') : t('Create')}
        </NblocksButton>
      </div>
    </div>
  );
};

export { UserModalComponent };
