import React from 'react';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';
import {
  OptionsComponent,
  Selectable,
} from 'src/components/shared/options/options.component';
import { useListCurrentPlans } from 'src/shared/hooks/use-list-plans';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  didSelectPlanKey: (key: string) => void;
}

const PlanKeySelectComponent = ({ didSelectPlanKey }: ComponentProps) => {
  const { t } = useTranslation();
  const { data: plansQuery, loading: plansLoading } = useListCurrentPlans();

  const plansOptions: Selectable[] = plansQuery?.getAppPlans
    ? plansQuery.getAppPlans.map((p) => {
        return {
          label: p.name,
          value: p.key,
        };
      })
    : [];

  return (
    <>
      {plansLoading && <SkeletonLoader className="h-12 w-full rounded-md" />}
      {!plansLoading && (
        <OptionsComponent
          noSelectionLabel={t('No plan')}
          data={plansOptions}
          didSelect={(args) => didSelectPlanKey(args.value)}
        />
      )}
    </>
  );
};

export { PlanKeySelectComponent };
