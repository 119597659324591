/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import React, { FunctionComponent, useState } from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export type TabIconType = FunctionComponent<{ fillClassName: string }>;

export interface TabItem {
  id: string;
  name: string;
  icon?: TabIconType;
}

const TabIcon = ({
  icon,
  isSelected,
}: {
  icon: TabIconType;
  isSelected: boolean;
}) => {
  const Icon = icon;
  return (
    <Icon fillClassName={isSelected ? 'fill-purple-500' : 'fill-gray-500'} />
  );
};

export interface TabsProps {
  tabs: TabItem[];
  initTab?: TabItem;
  didClickTab?: (tab: TabItem) => void;
}

const Tabs = ({ tabs, didClickTab, initTab }: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState<TabItem>(
    initTab ? initTab : tabs[0],
  );

  const isSelected = (tab: any) => {
    return tab.name === selectedTab.name;
  };

  const clickTab = (tab: TabItem) => {
    setSelectedTab(tab);
    if (didClickTab) didClickTab(tab);
  };

  return (
    <div className="border-b border-gray-200 pb-5 sm:pb-0">
      <div className="mt-3 sm:mt-4">
        <div className="">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href="#"
                onClick={() => clickTab(tab)}
                className={classNames(
                  isSelected(tab)
                    ? 'border-purple-500 text-purple-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium flex items-center',
                )}
                aria-current={isSelected(tab) ? 'page' : undefined}
              >
                {tab.icon && (
                  <div className="h-5 w-5 mr-2">
                    <TabIcon icon={tab.icon} isSelected={isSelected(tab)} />
                  </div>
                )}
                <TextComponent
                  size="base"
                  colorName={isSelected(tab) ? 'text-purple-500' : ''}
                >
                  {tab.name}
                </TextComponent>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export { Tabs };
