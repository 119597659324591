import React, { useState } from 'react';
import {
  GetAppOnboardingDocument,
  UpdateAppOnboardingDocument,
} from 'src/gql/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { CreateAppModalComponent } from './create-app.modal.component';

const CreateAppPromptComponent = () => {
  const { appId } = useCurrentApp();

  const [onboardingModalOpen, setOnboardingModalOpen] = useState(true);

  const { data: onboardingDataQuery } = useQuery(GetAppOnboardingDocument, {
    variables: { appId: appId! },
    skip: !appId,
  });

  const [updateOnboardingMutation] = useMutation(UpdateAppOnboardingDocument);

  const finishOnboarding = async () => {
    if (appId) {
      await updateOnboardingMutation({
        variables: {
          appId: appId,
          onboarding: {
            createApp: {
              done: true,
            },
          },
        },
        refetchQueries: ['GetAppOnboarding'],
      });
      setOnboardingModalOpen(false);
    }
  };

  return onboardingDataQuery &&
    !onboardingDataQuery.getOnboarding?.createApp?.done ? (
    <>
      <ModalComponent
        isOpen={onboardingModalOpen}
        setIsOpen={setOnboardingModalOpen}
        width="2xl"
      >
        <CreateAppModalComponent didFinishOnboarding={finishOnboarding} />
      </ModalComponent>
    </>
  ) : (
    <></>
  );
};

export { CreateAppPromptComponent };
