import { useCurrentTenant } from 'src/shared/hooks/use-current-tenant';
import { SafeTenantName } from '../safe-tenant-name/safe-tenant-name.component';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { useCurrentTenantUser } from 'src/shared/hooks/use-current-tenant-user';
import { SafeUserName } from '../user/safe-user-name/safe-user-name.component';
import {
  BreadcrumpsComponent,
  Crump,
} from 'src/components/shared/breadcrumps/breadcrumps-component';
import { UserReadinessPromptComponent } from 'src/components/onboarding/user-readiness/user-readiness-prompt.component';

const TenantBreadcrumpsComponent = () => {
  const { appId } = useCurrentApp();
  const { tenant, tenantId } = useCurrentTenant();
  const { tenantUser, tenantUserId } = useCurrentTenantUser();

  const crumps: Crump[] = [
    {
      name: 'Workspaces',
      href: `/app/${appId}/tenants`,
      active: !tenantId && !tenantUserId,
    },
  ];

  if (tenantId) {
    crumps.push({
      name: SafeTenantName(tenant?.name),
      href: `/app/${appId}/tenants/${tenantId}`,
      active: !tenantUserId,
    });
  }

  if (tenantUserId) {
    crumps.push({
      name: SafeUserName(tenantUser?.fullName),
      href: `/app/${appId}/tenants/${tenantId}/users/${tenantUserId}`,
      active: true,
    });
  }
  return (
    <div className="relative">
      <BreadcrumpsComponent crumps={crumps} />
      <UserReadinessPromptComponent />
    </div>
  );
};

export { TenantBreadcrumpsComponent };
