import { useCurrentApp } from './use-current-app';
import { ListTenantsDocument } from 'src/gql/graphql';
import { useQuery } from '@apollo/client';

const useListTenants = () => {
  const { appId } = useCurrentApp();

  const { data, loading, error } = useQuery(ListTenantsDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
    fetchPolicy: 'cache-and-network', //  This policy first tries to read data from the cache. Regardless of whether the data is found in the cache or not, it always makes a network request to fetch the latest data and updates the cache with the new data. This is useful when you want to show cached data immediately and then update it with fresh data.
  });

  return { data, loading, error };
};

export { useListTenants };
