import React, { useEffect, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import {
  ListFederationConnectionsQuery,
  PriceOfferInput,
  Tenant,
  TenantOwnerInput,
} from 'src/gql/graphql';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { DividerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/DividerComponent';
import { useTranslation } from 'react-i18next';
import {
  OptionsComponent,
  Selectable,
} from '../../shared/options/options.component';
import { TogglerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TogglerComponent';
import { Switch } from '@headlessui/react';
import { FeatureFlagComponent } from 'src/components/shared/feature-flag/feature-flag.component';
import { FlagKeys } from 'src/shared/flag-keys';
import { PlanKeySelectComponent } from './plan-key-select.component';

export interface ComponentProps {
  didClickSubmit: (args: SaveArgs) => void;
  didClickCancel: () => void;
  editTenant?: Tenant;
  mfaEnabled?: boolean | null | undefined;
  connectionQuery?: ListFederationConnectionsQuery | undefined;
}

export interface SaveArgs {
  logo?: string;
  name?: string;
  owner?: TenantOwnerInput;
  plan?: string;
  priceOffer?: PriceOfferInput;
  mfa?: boolean;
  federationConnection?: string;
}

const TenantModalComponent = ({
  didClickSubmit,
  didClickCancel,
  editTenant,
  mfaEnabled,
  connectionQuery,
}: ComponentProps) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string | undefined>();

  const [planKey, setPlanKey] = useState<string | undefined>();

  const [connection, setConnection] = useState<string | undefined>();
  const [mfa, setMfa] = useState(false);

  const [ownerEmail, setOwnerEmail] = useState<string>('');
  const [ownerFirstName, setOwnerFirstName] = useState<string | undefined>();
  const [ownerLastName, setOwnerLastName] = useState<string | undefined>();

  useEffect(() => {
    if (editTenant) {
      setName(editTenant.name || '');
      setMfa(!!editTenant.mfa);
      setConnection(editTenant.federationConnection ?? '');
    }
  }, [editTenant]);

  const onDidClickSubmit = () => {
    didClickSubmit({
      name,
      owner: {
        email: ownerEmail,
        firstName: ownerFirstName,
        lastName: ownerLastName,
      },
      mfa,
      federationConnection: connection,
      plan: planKey,
    });
  };

  const emptyConnection: Selectable = { value: '', label: 'No connection' };

  const connectionOptions: Selectable[] =
    connectionQuery?.listFederationConnections
      ? [
          ...connectionQuery.listFederationConnections.map((c) => {
            return {
              label: c.name,
              value: c.id,
            };
          }),
          emptyConnection,
        ]
      : [];

  const formValid = editTenant ? name : name && ownerEmail;

  return (
    <div>
      <div>
        <form
          onSubmit={() => onDidClickSubmit()}
          className="space-y-6 max-w-sm w-full"
        >
          <InputComponent
            type="text"
            label="Workspace name*"
            placeholder="E.g. John's org"
            name="workspaceName"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
          {editTenant && !!mfaEnabled && (
            <FeatureFlagComponent flagKey={FlagKeys.MFA_BTN}>
              <Switch.Group>
                <div className="flex items-center">
                  <Switch.Label className="mr-4">{t('MFA')}</Switch.Label>
                  <TogglerComponent
                    name="mfaEnabled"
                    enabled={mfa}
                    setEnabled={(value) => setMfa(value)}
                  />
                </div>
              </Switch.Group>
            </FeatureFlagComponent>
          )}
          {editTenant && connectionOptions.length > 0 && (
            <FeatureFlagComponent flagKey={FlagKeys.ENTERPRISE_CONNECTIONS}>
              <OptionsComponent
                label="Enterprise connection"
                init={
                  connectionOptions.find((c) => c.value === connection) ??
                  emptyConnection
                }
                data={connectionOptions}
                didSelect={(args) => setConnection(args.value)}
              />
            </FeatureFlagComponent>
          )}
          {!editTenant && (
            <>
              <DividerComponent text={t('Owner')} />
              <InputComponent
                type="email"
                label="Email*"
                placeholder="E.g. john@doe.com"
                name="email"
                onChange={(event) => setOwnerEmail(event.target.value)}
                value={ownerEmail}
              />
              <div className="flex space-x-2">
                <InputComponent
                  type="text"
                  label="First name"
                  placeholder="E.g. John"
                  name="firstName"
                  onChange={(event) => setOwnerFirstName(event.target.value)}
                  value={ownerFirstName}
                />
                <InputComponent
                  type="text"
                  label="Last name"
                  placeholder="E.g. Doe"
                  name="lastName"
                  onChange={(event) => setOwnerLastName(event.target.value)}
                  value={ownerLastName}
                />
              </div>
              <DividerComponent text={t('Plan')} />
              <PlanKeySelectComponent didSelectPlanKey={setPlanKey} />
            </>
          )}
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {'Cancel'}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSubmit()}
          disabled={!formValid}
        >
          {editTenant ? t('Save') : t('Create')}
        </NblocksButton>
      </div>
    </div>
  );
};

export { TenantModalComponent };
