import React, { FunctionComponent, useState } from 'react';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { SafeTenantNameComponent } from 'src/components/tenant/safe-tenant-name/safe-tenant-name.component';
import { UserListComponent } from 'src/components/tenant/user/user-list-component/user-list.component';
import { useCurrentTenant } from 'src/shared/hooks/use-current-tenant';
import { TenantBreadcrumpsComponent } from 'src/components/tenant/breadcrumps/tenant-breadcrumps-component';
import { useTranslation } from 'react-i18next';
import { ActiveChipComponent } from 'src/components/shared/active-chip/active-chip.component';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import {
  HorizontalEllipsisMenu,
  Option,
} from '@nebulr-group/nblocks-react/lib/components/shared/HorizontalEllipsisMenu';
import {
  TenantModalComponent,
  SaveArgs,
} from 'src/components/tenant/tenant-list-component/tenant.modal.component';
import {
  DeleteTenantDocument,
  GetAppAuthProviderStateDocument,
  GetTenantPaymentDetailsDocument,
  ListTenantsDocument,
  SetTenantPlanDetailsDocument,
  SetTenantPlanDetailsInput,
  UpdateTenantDocument,
} from 'src/gql/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { useListCurrentFederationConnections } from 'src/shared/hooks/use-list-federation-connections';
import { ChipComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ChipComponent';
import { useNavigate } from 'react-router-dom';
import { DangerVerifyModalComponent } from 'src/components/shared/danger-verify-modal/danger-verify-modal.component';
import { useFeedback } from 'src/shared/hooks/use-feedback';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';
import { TenantSubscriptionModalComponent } from 'src/components/tenant/tenant-list-component/tenant-subscription.modal.component';
import { FeatureFlagComponent } from 'src/components/shared/feature-flag/feature-flag.component';
import { FlagKeys } from 'src/shared/flag-keys';
import { priceOneLiner } from 'src/components/payments/plans.component';

const AppTenantScreen = () => {
  const { t } = useTranslation();
  const { setSuccessMessage } = useFeedback();
  const {
    tenant,
    loading: tenantLoading,
    tenantId,
    paymentDetails,
  } = useCurrentTenant();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const { appId } = useCurrentApp();
  const [updateTenantMutation, updateTenantMutationData] =
    useMutation(UpdateTenantDocument);
  const [setTenantPlanDetailsMutation, setTenantPlanDetailsMutationData] =
    useMutation(SetTenantPlanDetailsDocument);
  const [deleteTenantMutation, deleteTenantMutationData] =
    useMutation(DeleteTenantDocument);
  const navigate = useNavigate();

  const { data: authStateData } = useQuery(GetAppAuthProviderStateDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: appId! },
    skip: !appId,
  });

  const mfaEnabled = !!authStateData?.getApp.mfaEnabled;

  const { data: federationConnectionQuery } =
    useListCurrentFederationConnections();

  const didClickModalSubmit = async (args: SaveArgs) => {
    if (appId && tenantId) {
      await updateTenantMutation({
        variables: {
          appId,
          tenantId,
          tenant: {
            name: args.name,
            mfa: args.mfa,
            federationConnection: args.federationConnection,
          },
        },
      });

      setSuccessMessage(t('The workspace was updated!'));
    }

    setEditModalOpen(false);
  };

  const didClickSubscriptionModalSubmit = async (
    details: SetTenantPlanDetailsInput,
  ) => {
    if (appId && tenantId) {
      await setTenantPlanDetailsMutation({
        variables: {
          tenantId,
          appId,
          details,
        },
        refetchQueries: [GetTenantPaymentDetailsDocument],
      });

      setSuccessMessage(t('The workspace subscription was updated!'));
    }

    setSubscriptionModalOpen(false);
  };

  const didClickDeleteTenant = async () => {
    if (appId && tenantId) {
      await deleteTenantMutation({
        variables: { appId, tenantId },
        refetchQueries: [ListTenantsDocument],
      });
      setdeleteModalOpen(false);
      setSuccessMessage(t('The workspace was deleted!'));
      // Back to tenants list
      navigate(`/app/${appId}/tenants`);
    }
  };

  const EditComponent: FunctionComponent = () => {
    const options: Option[] = [
      {
        label: t('Edit workspace'),
        onClick: () => {
          setEditModalOpen(true);
        },
        labelPosition: 'start',
      },
      {
        label: t('Change subscription'),
        onClick: () => {
          setSubscriptionModalOpen(true);
        },
        labelPosition: 'start',
      },
      {
        label: t('Delete workspace'),
        type: 'danger',
        onClick: () => {
          setdeleteModalOpen(true);
        },
        labelPosition: 'start',
      },
    ];

    return (
      <div className="flex items-center justify-end p-1 border rounded-md border-gray-200 hover:bg-gray-50 hover:border-gray-300">
        <HorizontalEllipsisMenu options={options} position={'left'} />
      </div>
    );
  };

  const { plan, price } = paymentDetails?.details || {};

  return (
    <div className="space-y-6">
      <TenantBreadcrumpsComponent />
      <div className="relative">
        {tenantLoading && (
          <div className="w-2/6">
            <SkeletonLoader className="h-8 w-full rounded-md" />
          </div>
        )}
        {tenant && (
          <HeadingComponent type={'h1'} size={'2xl'}>
            <SafeTenantNameComponent name={tenant?.name} />
          </HeadingComponent>
        )}
        <div className="absolute top-0 right-0 flex space-x-4">
          <EditComponent />
        </div>
      </div>
      <div className="space-y-4">
        {tenantLoading && (
          <div className="w-2/6">
            <SkeletonLoader className="h-8 w-full rounded-md" />
          </div>
        )}
        {tenant && !!mfaEnabled && (
          <FeatureFlagComponent flagKey={FlagKeys.MFA_BTN}>
            <div className="flex items-center space-x-2">
              <TextComponent size="sm">{t('Workspace has MFA:')}</TextComponent>
              <ActiveChipComponent active={!!tenant.mfa} />
            </div>
          </FeatureFlagComponent>
        )}
        {tenant && plan && price && (
          <div className="flex items-center space-x-2">
            <TextComponent size="sm">{t('Subscribing to:')}</TextComponent>
            <ChipComponent type="tertiary">
              {plan.name} ({priceOneLiner(price)})
            </ChipComponent>
          </div>
        )}
        {tenant && tenant.federationConnection && federationConnectionQuery && (
          <div className="flex items-center space-x-2">
            <TextComponent size="sm">Enterprise connection</TextComponent>
            <ChipComponent type={'tertiary'}>
              {
                federationConnectionQuery.listFederationConnections.find(
                  (c) => {
                    return c.id === tenant.federationConnection;
                  },
                )?.name
              }
            </ChipComponent>
          </div>
        )}
      </div>
      <UserListComponent />
      <ModalComponent
        isOpen={editModalOpen}
        setIsOpen={setEditModalOpen}
        heading={'Edit workspace'}
      >
        <TenantModalComponent
          didClickSubmit={(tenant) => didClickModalSubmit(tenant)}
          didClickCancel={() => setEditModalOpen(false)}
          editTenant={tenant}
          mfaEnabled={mfaEnabled}
          connectionQuery={federationConnectionQuery}
        />
      </ModalComponent>
      <ModalComponent
        isOpen={subscriptionModalOpen}
        setIsOpen={setSubscriptionModalOpen}
        heading={t('Change subscription')}
      >
        <TenantSubscriptionModalComponent
          paymentDetails={paymentDetails}
          didClickSubmit={(details) => didClickSubscriptionModalSubmit(details)}
          didClickCancel={() => setSubscriptionModalOpen(false)}
        />
      </ModalComponent>
      <DangerVerifyModalComponent
        title={t('Are you sure?')}
        message={t(
          'Do you want to delete this tenant? All its users and data will also be deleted',
        )}
        submitBtnText={t('Delete')}
        cancelBtnText={t('Cancel')}
        isOpen={deleteModalOpen}
        setIsOpen={setdeleteModalOpen}
        onSubmit={() => didClickDeleteTenant()}
        loading={deleteTenantMutationData.loading}
      />
    </div>
  );
};

export { AppTenantScreen };
