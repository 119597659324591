import React from 'react';

const InlineCode = ({ children }: { children: string }) => {
  return (
    <span className="font-mono bg-gray-100 px-2 py-0.5 text-black rounded">
      {children}
    </span>
  );
};

export { InlineCode };
