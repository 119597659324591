import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from 'react-i18next';

dayjs.extend(LocalizedFormat);

const DateTimeComponent = ({ value }: { value?: string | null }) => {
  const { t } = useTranslation();
  return <>{value ? dayjs(value).format('lll') : t('Never')}</>;
};

export { DateTimeComponent };
