import React from 'react';
import {
  OptionsComponent,
  Selectable,
} from 'src/components/shared/options/options.component';

// 'day' | 'month' | 'week' | 'year'
const supportedIntervals: Selectable[] = [
  {
    label: 'Monthly',
    value: 'month',
  },
  {
    label: 'Yearly',
    value: 'year',
  },
  {
    label: 'Daily',
    value: 'day',
  },
  {
    label: 'Weekly',
    value: 'week',
  },
];

export interface ComponentProps {
  didSelect: (args: Selectable) => void;
  init?: Selectable;
}

const RecurIntervalsInputComponent = ({ didSelect, init }: ComponentProps) => {
  return (
    <OptionsComponent
      data={supportedIntervals}
      init={init}
      label="Billing period"
      didSelect={didSelect}
    />
  );
};

export { RecurIntervalsInputComponent, supportedIntervals };
