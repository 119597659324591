import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandingInput, BrandingOutput, BrandingProps } from 'src/gql/graphql';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { PasskeysLoginButtonComponent } from '@nebulr-group/nblocks-react/lib/components/auth/shared/PasskeysLoginButtonComponent';
import { AzureAdSsoButtonComponent } from '@nebulr-group/nblocks-react/lib/components/auth/shared/AzureAdSsoButtonComponent';
import { LinkedinSsoButtonComponent } from '@nebulr-group/nblocks-react/lib/components/auth/shared/LinkedinSsoButtonComponent';
import { GoogleSsoButtonComponent } from '@nebulr-group/nblocks-react/lib/components/auth/shared/GoogleSsoButtonComponent';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';
import { ImageComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ImageComponent';
import { DividerComponent } from '@nebulr-group/nblocks-react/lib/components/shared/DividerComponent';

const generateCss = (
  branding: BrandingProps,
  defaultValues: BrandingOutput['defaultValues'],
): string => {
  // Helper function to return CSS properties only where the value is different from the default
  const cssIfChanged = (
    property: keyof BrandingProps,
    cssVarName: string,
  ): string => {
    if (branding[property] !== defaultValues[property]) {
      return `--${cssVarName}: ${branding[property]};`;
    }
    return '';
  };

  return `
    .preview-container {
      ${cssIfChanged('bgColor', 'bgColor')}
      ${cssIfChanged('fontFamily', 'fontFamily')}
      ${cssIfChanged('primaryButtonBgColor', 'primaryButtonBgColor')}
      ${cssIfChanged('primaryButtonTextColor', 'primaryButtonTextColor')}
      ${cssIfChanged('tertiaryButtonBgColor', 'tertiaryButtonBgColor')}
      ${cssIfChanged('tertiaryButtonTextColor', 'tertiaryButtonTextColor')}
      ${cssIfChanged('textColor', 'textColor')}
      ${cssIfChanged('linkColor', 'linkColor')}
      ${cssIfChanged('borderRadius', 'borderRadius')}
      ${cssIfChanged('boxShadow', 'boxShadow')}
    }

    .preview-container {
      ${
        branding.bgColor !== defaultValues.bgColor
          ? `background-color: var(--bgColor);`
          : ''
      }
      ${
        branding.fontFamily !== defaultValues.fontFamily
          ? `font-family: var(--fontFamily);`
          : ''
      }
    }

    .preview-container button.customizable,
    .preview-container input.customizable, 
    .preview-container button.customizable:not([disabled]):hover {
      ${
        branding.borderRadius !== defaultValues.borderRadius
          ? `border-radius: var(--borderRadius);`
          : ''
      }
      ${
        branding.boxShadow !== defaultValues.boxShadow
          ? `box-shadow: var(--boxShadow);`
          : ''
      }
    }

    .preview-container button.customizable.primary,
    .preview-container button.customizable.primary:not([disabled]):hover {
      ${
        branding.primaryButtonBgColor !== defaultValues.primaryButtonBgColor
          ? `background-color: var(--primaryButtonBgColor);`
          : ''
      }
      ${
        branding.primaryButtonTextColor !== defaultValues.primaryButtonTextColor
          ? `color: var(--primaryButtonTextColor);`
          : ''
      }
    }
    .preview-container button.customizable.primary:not([disabled]):hover {
      ${
        branding.primaryButtonBgColor !== defaultValues.primaryButtonBgColor
          ? `transform: scale(1.03);`
          : ''
      }
    }

    .preview-container button.customizable.tertiary,
    .preview-container button.customizable.tertiary:not([disabled]):hover {
      ${
        branding.tertiaryButtonBgColor !== defaultValues.tertiaryButtonBgColor
          ? `background-color: var(--tertiaryButtonBgColor);`
          : ''
      }
      ${
        branding.tertiaryButtonTextColor !==
        defaultValues.tertiaryButtonTextColor
          ? `color: var(--tertiaryButtonTextColor);`
          : ''
      }
    }
    .preview-container button.customizable.tertiary:not([disabled]):hover {
      ${
        branding.tertiaryButtonBgColor !== defaultValues.tertiaryButtonBgColor
          ? `transform: scale(1.03);`
          : ''
      }
    }

    .preview-container p.customizable,
    .preview-container label.customizable,
    .preview-container h1.customizable,
    .preview-container div.divider div.customizable,
    .preview-container div.divider span.customizable {
      ${
        branding.textColor !== defaultValues.textColor
          ? `color: var(--textColor);`
          : ''
      }
      ${
        branding.fontFamily !== defaultValues.fontFamily
          ? `font-family: var(--fontFamily);`
          : ''
      }
    }

    .preview-container a.customizable,
    .preview-container a.customizable:hover {
      ${
        branding.linkColor !== defaultValues.linkColor
          ? `color: var(--linkColor);`
          : ''
      }
    }
    .preview-container a.customizable:hover {
      ${
        branding.linkColor !== defaultValues.linkColor
          ? `text-decoration: underline;`
          : ''
      }
    }

    .preview-container div.divider div.customizable {
      ${
        branding.textColor !== defaultValues.textColor
          ? `border-color: var(--textColor);`
          : ''
      }
    }

    .preview-container div.divider span.customizable {
      ${
        branding.bgColor !== defaultValues.bgColor
          ? `background-color: var(--bgColor);`
          : ''
      }
    }
  `
    .trim()
    .replace(/^\s*[\r\n]/gm, '');
};

interface BrandingPreviewComponentProps {
  branding: BrandingInput;
  defaultValues: BrandingOutput['defaultValues'];
  logoURL: string;
  privacyPolicyUrl: string;
}

const BrandingPreviewComponent = ({
  branding,
  logoURL,
  defaultValues,
  privacyPolicyUrl,
}: BrandingPreviewComponentProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.textContent = generateCss(branding, defaultValues);
    document.head.appendChild(styleSheet);

    return () => {
      // Remove the style element on cleanup to prevent memory leaks
      document.head.removeChild(styleSheet);
    };
  }, [branding]);

  return (
    <div className="w-1/2 max-h-screen sticky top-4">
      <div className="rounded-lg border border-gray-300 bg-white shadow preview-container">
        <div className="flex flex-col pt-12">
          <div className="h-12 flex justify-center">
            <ImageComponent src={logoURL} />
          </div>
          <div className={'mt-6 md:mt-8 text-center'}>
            <HeadingComponent size="4xl" type={'h1'}>
              Log in to your account
            </HeadingComponent>
            <SubHeadingComponent
              type={'secondary'}
              size={'lg'}
              className="font-normal mt-3"
            >
              Welcome back! Please enter your details.
            </SubHeadingComponent>
          </div>
          <div className="mt-6 flex items-center flex-col">
            <form className="space-y-6 max-w-sm w-full">
              <InputComponent
                type="email"
                label={t('Email address')}
                placeholder={t('Enter your email')}
                name="username"
                autoComplete="username webauthn"
                autoFocus={true}
              />
              <div>
                <NblocksButton size="md" type="primary" fullWidth={true}>
                  Continue
                </NblocksButton>
              </div>
              <div className="py-2">
                <DividerComponent text={t('Or')} />
              </div>
              <div className="space-y-2">
                <PasskeysLoginButtonComponent
                  mode="login"
                  onClick={() => {
                    return;
                  }}
                ></PasskeysLoginButtonComponent>
                <AzureAdSsoButtonComponent
                  label="login"
                  onClick={() => {
                    return;
                  }}
                ></AzureAdSsoButtonComponent>
                <GoogleSsoButtonComponent
                  label="login"
                  onClick={() => {
                    return;
                  }}
                ></GoogleSsoButtonComponent>
                <LinkedinSsoButtonComponent
                  label="login"
                  onClick={() => {
                    return;
                  }}
                ></LinkedinSsoButtonComponent>
              </div>
            </form>
            <div className="mt-8">
              <TextComponent size="sm">
                {t("Don't have an account?")}&nbsp;
                <LinkComponent
                  to={'#'}
                  type="primary"
                  size="sm"
                  className="font-semibold"
                >
                  {t('Sign up')}
                </LinkComponent>
              </TextComponent>
            </div>
          </div>
          <div className="mt-auto mx-auto text-center mb-6">
            <div>
              <LinkComponent
                type="primary"
                to={privacyPolicyUrl}
                nativeBehavior={true}
                target="_blank"
                size="sm"
                className="font-semibold"
              >
                {t('Privacy policy')}
              </LinkComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BrandingPreviewComponent };
