import React, { FunctionComponent, useState } from 'react';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import {
  GetAppOnboardingDocument,
  OnboardingOutput,
  UpdateAppOnboardingDocument,
} from 'src/gql/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { useTranslation } from 'react-i18next';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { FeatureFlagsModalComponent } from './feature-flags.modal.component';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { XCircleIcon } from '@heroicons/react/24/outline';

const FeatureFlagsPromptComponent = () => {
  const { appId } = useCurrentApp();
  const { t } = useTranslation();

  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  const { data: onboardingDataQuery } = useQuery(GetAppOnboardingDocument, {
    variables: { appId: appId! },
    skip: !appId,
  });

  const [updateOnboardingMutation] = useMutation(UpdateAppOnboardingDocument);

  const finishOnboarding = async () => {
    if (appId) {
      await updateOnboardingMutation({
        variables: {
          appId: appId,
          onboarding: {
            featureFlags: {
              done: true,
            },
          },
        },
        refetchQueries: ['GetAppOnboarding'],
      });
      setOnboardingModalOpen(false);
    }
  };

  const PromptContent: FunctionComponent<{
    onboardingData: OnboardingOutput | undefined | null;
  }> = ({ onboardingData }) => {
    if (onboardingData?.featureFlags?.test) {
      return (
        <>
          <TextComponent colorName="text-green-900" size="sm">
            {t('Test your feature flags!')}
          </TextComponent>
          <button
            className="underline flex items-center space-x-2"
            onClick={() => setOnboardingModalOpen(true)}
          >
            <TextComponent colorName="text-green-900" size="sm">
              {t('Test instructions')}
            </TextComponent>
            <ArrowRightIcon className="h-4 w-4 text-green-900" />
          </button>
        </>
      );
    } else {
      return (
        <>
          <div className="flex items-center">
            <TextComponent colorName="text-green-900" size="sm">
              {t('Start implementing feature flags')}
            </TextComponent>
          </div>
          <button
            className="underline flex items-center space-x-2"
            onClick={() => setOnboardingModalOpen(true)}
          >
            <TextComponent colorName="text-green-900" size="sm">
              {t('Instructions')}
            </TextComponent>
            <ArrowRightIcon className="h-4 w-4 text-green-900" />
          </button>
        </>
      );
    }
  };

  return onboardingDataQuery ? (
    <>
      {!onboardingDataQuery?.getOnboarding?.featureFlags?.done ? (
        <div className="rounded-lg border border-green-700 bg-green-100 shadow p-6 flex flex-col space-y-2 mt-4 relative">
          <PromptContent onboardingData={onboardingDataQuery?.getOnboarding} />
          <button
            className="h-6 w-6 text-green-700 absolute top-0 right-0 mt-2 mr-2"
            onClick={() => finishOnboarding()}
          >
            <XCircleIcon />
          </button>
        </div>
      ) : (
        <button
          className="h-6 w-6 text-gray-400 absolute top-0 right-0"
          onClick={() => setOnboardingModalOpen(true)}
        >
          <QuestionMarkCircleIcon />
        </button>
      )}
      <ModalComponent
        isOpen={onboardingModalOpen}
        setIsOpen={setOnboardingModalOpen}
        heading={t('Add feature control')}
        width="6xl"
      >
        <FeatureFlagsModalComponent didFinishOnboarding={finishOnboarding} />
      </ModalComponent>
    </>
  ) : (
    <></>
  );
};

export { FeatureFlagsPromptComponent };
