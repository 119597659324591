import React, { FunctionComponent, useEffect, useState } from 'react';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import {
  GetAppOnboardingDocument,
  ListTenantsDocument,
  OnboardingOutput,
  Tenant,
  UpdateAppOnboardingDocument,
} from 'src/gql/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from 'src/components/shared/spinner/loading-spinner.component';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { UserReadinessModalComponent } from './user-readiness.modal.component';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { XCircleIcon } from '@heroicons/react/24/outline';
import Confetti from 'react-confetti';

const UserReadinessPromptComponent = () => {
  const { appId } = useCurrentApp();
  const { t } = useTranslation();

  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isTestPending, setIsTestPending] = useState(true);

  const { data: tenantListDataQuery, stopPolling } = useQuery(
    ListTenantsDocument,
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { appId: appId! },
      skip: !appId,
      pollInterval: 5000,
    },
  );

  const { data: onboardingDataQuery } = useQuery(GetAppOnboardingDocument, {
    variables: { appId: appId! },
    skip: !appId,
  });

  const [updateOnboardingMutation] = useMutation(UpdateAppOnboardingDocument);

  useEffect(() => {
    if (
      !!onboardingDataQuery?.getOnboarding?.userReadiness?.test &&
      tenantListDataQuery
    ) {
      setIsTestPending(tenantListDataQuery.listTenants.length === 0);
    }
  }, [tenantListDataQuery, onboardingDataQuery]);

  const finishOnboarding = async (
    onboardingData: OnboardingOutput | undefined | null,
  ) => {
    const allowConfetti =
      !!onboardingData?.userReadiness?.test &&
      !onboardingData?.userReadiness?.done;
    if (appId) {
      await updateOnboardingMutation({
        variables: {
          appId: appId,
          onboarding: {
            userReadiness: {
              done: true,
            },
          },
        },
        refetchQueries: ['GetAppOnboarding'],
      }).then(() => {
        setShowConfetti(allowConfetti);
      });
    }
    setOnboardingModalOpen(false);
  };

  const PromptContent: FunctionComponent<{
    tenantData: Tenant[];
    onboardingData: OnboardingOutput | undefined | null;
  }> = ({ tenantData, onboardingData }) => {
    if (tenantData.length && tenantData.length > 0) {
      stopPolling();
      return (
        <>
          <TextComponent colorName="text-green-900" size="sm">
            {t('Your app is now integrated!')}
          </TextComponent>
        </>
      );
    }
    if (onboardingData?.userReadiness?.test) {
      return (
        <>
          <div className="flex items-center">
            <LoadingSpinner className="w-4 h-4 mr-2 text-green-100 fill-green-900" />
            <TextComponent colorName="text-green-900" size="sm">
              {t('Waiting for your first workspaces')}
            </TextComponent>
          </div>
          <button
            className="underline flex items-center space-x-2"
            onClick={() => setOnboardingModalOpen(true)}
          >
            <TextComponent colorName="text-green-900" size="sm">
              {t('Instructions')}
            </TextComponent>
            <ArrowRightIcon className="h-4 w-4 text-green-900" />
          </button>
        </>
      );
    } else {
      return (
        <>
          <div className="flex items-center">
            <TextComponent colorName="text-green-900" size="sm">
              {t('Start integrating your app')}
            </TextComponent>
          </div>
          <button
            className="underline flex items-center space-x-2"
            onClick={() => setOnboardingModalOpen(true)}
          >
            <TextComponent colorName="text-green-900" size="sm">
              {t('Instructions')}
            </TextComponent>
            <ArrowRightIcon className="h-4 w-4 text-green-900" />
          </button>
        </>
      );
    }
  };

  const ClosedPromptIcon = () => {
    stopPolling();
    return (
      <button
        className="h-6 w-6 text-gray-400 absolute top-0 right-0"
        onClick={() => setOnboardingModalOpen(true)}
      >
        <QuestionMarkCircleIcon />
      </button>
    );
  };

  return tenantListDataQuery && onboardingDataQuery ? (
    <>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={300}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
      {!onboardingDataQuery.getOnboarding?.userReadiness?.done ? (
        <div className="rounded-lg border border-green-700 bg-green-100 shadow p-6 flex flex-col space-y-2 mt-4 relative">
          <PromptContent
            tenantData={tenantListDataQuery.listTenants}
            onboardingData={onboardingDataQuery.getOnboarding}
          />
          <button
            className="h-6 w-6 text-green-700 absolute top-0 right-0 mt-2 mr-2"
            onClick={() => finishOnboarding(onboardingDataQuery.getOnboarding)}
          >
            <XCircleIcon />
          </button>
        </div>
      ) : (
        <ClosedPromptIcon />
      )}
      <ModalComponent
        isOpen={onboardingModalOpen}
        setIsOpen={setOnboardingModalOpen}
        heading={'Get your app user ready'}
        width="6xl"
      >
        <UserReadinessModalComponent
          didFinishOnboarding={() =>
            finishOnboarding(onboardingDataQuery?.getOnboarding)
          }
          pendingProps={{
            isTestPending,
            pendingLabel: 'Waiting for workspaces',
          }}
        />
      </ModalComponent>
    </>
  ) : (
    <></>
  );
};

export { UserReadinessPromptComponent };
