import React, { FunctionComponent } from 'react';
import { CodeBlock } from 'src/components/shared/code/code-block';
import { OnboardingStepType } from '../onboarding.modal.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { InlineCode } from 'src/components/shared/code/inline-code';
import { Config } from 'src/shared/Config';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { TestStep } from './feature-flags-test.component';

const FeatureFlagStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const code = `import { useEffect, useState } from 'react';

// The component takes the prop flag.
export default function FeatureFlag({ flag, children }) {

  const APP_ID = '${appId}';

  // This will be our variable telling if the feature is enabled or and we should render the component children
  // Initially this variable is false
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    // Evaluate the flag
    const accessToken = window.localStorage.getItem('access_token');
    if (accessToken) {
      evaluate(accessToken);
    }
  }, []);

  const evaluate = async (accessToken) => {
    const result = await fetch(
      \`${Config.getBackendlessUrl()}/flags/evaluate/\${APP_ID}/\${flag}\`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessToken,
        }),
      }
    ).then((res) => res.json());
    setEnabled(result.enabled);
  };

  // Only if enabled should we render the component children
  if (enabled) return children;
  else return '';
}
`;

  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        Create a FeatureFlag component
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingLogin"
          defaults="Create a new component that we call <0>FeatureFlag</0>"
          components={[<InlineCode>FeatureFlag</InlineCode>]}
        />
      </TextComponent>
      <CodeBlock code={code} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const ComponentsStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();
  const { t } = useTranslation();

  const code = `<FeatureFlag flag="premium-features">
    <span>Premium content</span>
</FeatureFlag>
<FeatureFlag flag="admin-features">
    <a href="/beta">Button to admin features</a>
</FeatureFlag>
<FeatureFlag flag="release-announcement">
    <h1>We're currently doing a release and will be back soon</h1>
</FeatureFlag>
`;

  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        Use the component
      </HeadingComponent>
      <TextComponent>
        {t(
          'Now you can use the FeatureFlag component anywhere in your app to conditionally show content like pages or buttons in your React app. Here are some examples',
        )}
        :
      </TextComponent>
      <CodeBlock code={code} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const FeatureFlagsReactVanillaSteps: OnboardingStepType[] = [
  {
    label: 'Create flag component',
    component: FeatureFlagStep,
    nextButtonLabel: 'Next',
  },
  {
    label: 'Use the component',
    component: ComponentsStep,
    nextButtonLabel: 'Done',
    // nextButtonLabel: 'Test',
  },
  {
    label: 'Done',
    // label: 'Test',
    component: TestStep,
    nextButtonLabel: 'Finish',
    onboardingMutationVariables: { featureFlags: { test: true } },
  },
];

export { FeatureFlagsReactVanillaSteps };
