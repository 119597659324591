import React, { useEffect, useState } from 'react';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import {
  FileDropAreaComponent,
  UploadedFileEvent,
} from 'src/components/shared/file-drop-area/file-drop-area.component';
import { useMe } from 'src/shared/hooks/use-me';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  ImportTenantsDocument,
  ValidateImportTenantResponseGraphql,
  ValidateImportTenantsDocument,
} from 'src/gql/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AlertComponent } from '@nebulr-group/nblocks-react/lib/components/shared/AlertComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';

export interface ComponentProps {
  didClickImport: () => void;
  didClickCancel: () => void;
}

const ImportTenantModalComponent = ({
  didClickImport,
  didClickCancel,
}: ComponentProps) => {
  const { appId } = useCurrentApp();
  const { profile } = useMe();
  const [uploadedSheetKey, setUploadedSheetKey] = useState<string>();
  const [convertedJsonUrl, setConvertedJsonUrl] = useState<string>();

  const [validateInputResult, setValidateInputResult] =
    useState<ValidateImportTenantResponseGraphql>();

  const [validateImportQuery, validateImportResponse] = useLazyQuery(
    ValidateImportTenantsDocument,
  );

  const [importTenantMutation, importTenantResponse] = useMutation(
    ImportTenantsDocument,
  );

  useEffect(() => {
    console.log(validateImportResponse.data?.validateImportTenants);
    if (validateImportResponse.data?.validateImportTenants) {
      const { validationResponse, convertedJsonFileSignedUrl } =
        validateImportResponse.data.validateImportTenants;
      if (validationResponse && convertedJsonFileSignedUrl) {
        setValidateInputResult(validationResponse);
        setConvertedJsonUrl(convertedJsonFileSignedUrl);
      }
    }
  }, [validateImportResponse]);

  const onDidUploadCsv = (event: UploadedFileEvent) => {
    setUploadedSheetKey(event.uploadedKey);

    validateImportQuery({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { appId: appId!, uploadedKey: event.uploadedKey },
    });
  };

  const onDidClickImport = async () => {
    if (convertedJsonUrl) {
      await importTenantMutation({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { appId: appId!, signedJsonUrl: convertedJsonUrl },
      });
      didClickImport();
    }
  };

  const formValid =
    uploadedSheetKey && validateInputResult && validateInputResult.approved;

  const loading =
    validateImportResponse.loading || importTenantResponse.loading;

  const renderBody = () => {
    if (validateInputResult) {
      const { approved, errors, warnings, passwords, tenants, users } =
        validateInputResult;
      return (
        <div>
          {approved && (
            <AlertComponent
              type="success"
              title=""
              messages={[
                'File was checked with no errors, everything seems fine!',
                `Import file contains ${tenants} Workspaces and ${users} Users in total.`,
                `User passwords will ${
                  passwords ? 'also' : 'not'
                } be imported.`,
                `A notification email will be sent to ${profile?.email} when the import is completed.`,
              ]}
            ></AlertComponent>
          )}
          {errors && errors.length > 0 && (
            <AlertComponent
              type="danger"
              title="File was checked with errors that needs to be resolved before importing"
              messages={errors}
            ></AlertComponent>
          )}
          {warnings && warnings.length > 0 && (
            <AlertComponent
              type="warning"
              title="File was checked with warnings. These are not blocking the import"
              messages={warnings}
            ></AlertComponent>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <TextComponent>
            Upload a file containing users and workspaces. The file will be
            parsed and you'll get a summary before the import starts. And
            example file can be found&nbsp;
            <LinkComponent
              to="https://github.com/nebulr-group/nblocks-api-docs/blob/main/source/files/import-tenants.csv"
              type={'primary'}
              nativeBehavior={true}
              target="_blank"
            >
              here.
            </LinkComponent>
          </TextComponent>
          <FileDropAreaComponent
            didUploadFile={onDidUploadCsv}
            subHeader="CSV or XLSX (MAX. 10MB)"
            acceptFileTypes={['.csv', '.xlsx']}
          />
        </div>
      );
    }
  };

  return (
    <div className="modal-body">
      <div>
        <form onSubmit={() => onDidClickImport()} className="space-y-6 w-full">
          {renderBody()}
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {'Cancel'}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          isLoading={loading}
          onClick={() => onDidClickImport()}
          disabled={!formValid}
        >
          {'Import'}
        </NblocksButton>
      </div>
    </div>
  );
};

export { ImportTenantModalComponent };
