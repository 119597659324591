import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import {
  ConfigCardsComponentProps,
  ToggleCardComponent,
} from '../../shared/toggle-card/toggle-card.component';
import { PaymentProviderCardProps } from '../providers.component';
import { StripeLogo } from './logos/stripe.logo';
import {
  UpdateAppEnabledBillingProvidersDocument,
  UpdateCredentialsDocument,
} from 'src/gql/graphql';
import { useMutation } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { LinkComponent } from '@nebulr-group/nblocks-react/lib/components/shared/LinkComponent';
import { useFeedback } from 'src/shared/hooks/use-feedback';

const StripeToggleCard = ({ data }: PaymentProviderCardProps) => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();
  const { setSuccessMessage } = useFeedback();

  type StripeCredentialsType = {
    stripePublicKey: string;
    stripeSecretKey: string;
  };
  const emptyState = {
    stripePublicKey: '',
    stripeSecretKey: '',
  };
  const [credentials, setCredentials] = useState<StripeCredentialsType>({
    stripePublicKey: '',
    stripeSecretKey: '',
  });

  const [updateCredentialsMutation, updateCredentialsData] = useMutation(
    UpdateCredentialsDocument,
  );

  const [
    updateEnabledBillingProvidersMutation,
    updateEnabledBillingProvidersData,
  ] = useMutation(UpdateAppEnabledBillingProvidersDocument);

  const { data: updatedEnabledData, loading: updateEnabledDataLoading } =
    updateEnabledBillingProvidersData;
  const { data: credentialsUpdateData, loading: updateCredentialsLoading } =
    updateCredentialsData;

  const isLoading = updateCredentialsLoading || updateEnabledDataLoading;

  const onEnabledChange = async (enabled: boolean) => {
    await updateEnabledBillingProvidersMutation({
      variables: {
        id: appId!,
        app: {
          stripeEnabled: enabled,
        },
      },
    });
  };

  const onDelete = async () => {
    await updateCredentialsMutation({
      variables: {
        id: appId!,
        credentials: emptyState,
      },
    });
    await updateEnabledBillingProvidersMutation({
      variables: {
        id: appId!,
        app: {
          stripeEnabled: false,
        },
      },
    });
  };

  const didClickModalSave = async () => {
    await updateCredentialsMutation({
      variables: {
        id: appId!,
        credentials: {
          ...credentials,
        },
      },
    });
    setCredentials(emptyState);
    setSuccessMessage('Stripe credentials added!');
  };

  const handleInputChange = (field: string, value: string) => {
    setCredentials((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const isEnabled = updatedEnabledData
    ? updatedEnabledData.updateApp.stripeEnabled
    : data.getApp.stripeEnabled;

  const credentialsAdded = credentialsUpdateData
    ? credentialsUpdateData.updateCredentials.stripeCredentialsAdded
    : data.getAppCredentialsState.stripeCredentialsAdded;

  const cardProps: ConfigCardsComponentProps = {
    logo: <StripeLogo />,
    label: 'Stripe',
    description: t('Automatically sync payment plans with Stripe'),
    isEnabled: isEnabled,
    onEnabledChange,
    isLoading,
    modalProps: {
      enableModalHeading: t('Connect to Stripe'),
      editModalHeading: t('Update Stripe credentials'),
      modalDescription: (
        <TextComponent>
          {t('You can find your Stripe credentials')}{' '}
          <LinkComponent
            target="_blank"
            to="https://dashboard.stripe.com/test/apikeys"
            type="primary"
            nativeBehavior
          >
            {t('here')}
          </LinkComponent>
        </TextComponent>
      ),
      deleteModalHeading: t('Do you want to delete your Stripe credentials?'),
      deleteModalPrompt: t(
        'Deleting your credentials will disable Stripe from your app until you reactivate it again with new credentials.',
      ),
      credentialsAdded,
      didClickModalSave: didClickModalSave,
      onModalChange: handleInputChange,
      onDelete,
      modalInputFields: [
        {
          type: 'text',
          label: t('Publishable key*'),
          placeholder: 'pk_test_XXXXX',
          caption: t('Find your publishable key'),
          field: 'stripePublicKey',
          value: credentials.stripePublicKey,
          errorLabel: {
            position: 'right',
            alt: t('Key should include pk'),
            node: t('Key should include pk'),
          },
          inputError:
            credentials.stripePublicKey.length > 0 &&
            !credentials.stripePublicKey.includes('pk'),
        },
        {
          type: 'password',
          label: t('Secret key*'),
          placeholder: 'sk_test_XXXXX',
          caption: t('Find your secret key'),
          field: 'stripeSecretKey',
          value: credentials.stripeSecretKey,
          errorLabel: {
            position: 'right',
            alt: t('Key should include sk'),
            node: t('Key should include sk'),
          },
          inputError:
            credentials.stripeSecretKey.length > 0 &&
            !credentials.stripeSecretKey.includes('sk'),
        },
      ],
      modalValid:
        credentials.stripePublicKey.length > 0 &&
        credentials.stripeSecretKey.length > 0 &&
        credentials.stripePublicKey.includes('pk') &&
        credentials.stripeSecretKey.includes('sk'),
    },
  };

  return <ToggleCardComponent {...cardProps} />;
};

export { StripeToggleCard };
