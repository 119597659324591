import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { useTranslation } from 'react-i18next';
import { TenantSubscriptionComponent } from './tenant-subscription.component';
import { SetTenantPlanDetailsInput } from '@nebulr-group/nblocks-react/lib/gql/graphql';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import {
  GetAppBillingProvidersDocument,
  TenantPaymentDetailsGraphql,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';

export interface ComponentProps {
  paymentDetails?: TenantPaymentDetailsGraphql;
  didClickSubmit: (details: SetTenantPlanDetailsInput) => void;
  didClickCancel: () => void;
}

const TenantSubscriptionModalComponent = ({
  paymentDetails,
  didClickSubmit,
  didClickCancel,
}: ComponentProps) => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();
  const [details, setDetails] = useState<SetTenantPlanDetailsInput>();
  const { data: billingProviderData, loading } = useQuery(
    GetAppBillingProvidersDocument,
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { id: appId! },
      skip: !appId,
    },
  );

  const onDidClickSubmit = () => {
    if (details) didClickSubmit(details);
  };

  const formValid = details;

  const onDidSetPlanDetails = (details: SetTenantPlanDetailsInput) => {
    setDetails(details);
  };

  /** Payment method is set up. We can bill this tenant */
  const tenantPaymentsEnabled = !!paymentDetails?.status.paymentsEnabled;

  /** App is ready for payments via provider */
  const appBillingEnabled =
    !!billingProviderData?.getApp.azureMarketplaceEnabled ||
    !!billingProviderData?.getApp.stripeEnabled;

  const renderHelpText = () => {
    // Tenant has setup payments with a provider
    // This means, also the app of course have setup a payment provider like Stripe
    if (tenantPaymentsEnabled) {
      return t(
        `Submitting this change will change this customers billing in ${paymentDetails.status.provider}.`,
      );
    } else {

      // Tenant has not setup payments (maybe do to subscribing to free or something with trial) but the app has
      if (appBillingEnabled) {
        return t(
          'Submitting this change might require customer to set up payment details during next login.',
        );
      } else {
        // Not the app nor the tenant is using billing provider
        return ""; // t('Submitting this change will not affect automatic billing.');
      }
    }
  };

  return (
    <div>
      <div>
        <form
          onSubmit={() => onDidClickSubmit()}
          className="space-y-6 max-w-sm w-full"
        >
          <TenantSubscriptionComponent
            didSetPlanDetails={onDidSetPlanDetails}
            paymentDetails={paymentDetails}
          />
          {details && (
            <TextComponent size="sm">{renderHelpText()}</TextComponent>
          )}
        </form>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
        <NblocksButton
          size="md"
          className="w-full"
          type="tertiary"
          onClick={() => didClickCancel()}
        >
          {'Cancel'}
        </NblocksButton>
        <NblocksButton
          size="md"
          className="w-full"
          type="primary"
          onClick={() => onDidClickSubmit()}
          disabled={!formValid}
        >
          {t('Save')}
        </NblocksButton>
      </div>
    </div>
  );
};

export { TenantSubscriptionModalComponent };
