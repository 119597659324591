import React, { FunctionComponent } from 'react';
import { CodeBlock } from 'src/components/shared/code/code-block';
import { OnboardingStepType } from '../onboarding.modal.component';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { Trans } from 'react-i18next';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { InlineCode } from 'src/components/shared/code/inline-code';
import { TestStep } from './user-readiness-test.component';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';

const InstallStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const installCode = `
npx @nebulr-group/nblocks-cli setup-react
  `;

  const providerCode = `import { NblocksProvider } from '@nebulr-group/nblocks-react';

export default function App() {
  return (
    <NblocksProvider config={{ appId: '${appId}' }}>
      ... App components ...
    </NblocksProvider>
  );
}
`;

  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        1. Install the Nblocks react plugin
      </HeadingComponent>
      <CodeBlock code={installCode} language="typescript" />
      <HeadingComponent type="h2" size="xl">
        2. Add the NblocksProvider
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingLogin"
          defaults="Add the <0>NblocksProvider</0> to your top most component, e.g. the <1>App</1> component and wrap the rest of your app as children."
          components={[<InlineCode>App</InlineCode>]}
        />
      </TextComponent>
      <CodeBlock code={providerCode} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const RoutesStep: FunctionComponent = () => {
  const { appId } = useCurrentApp();

  const reactRouterCode = `import { 
  LoginComponent, 
  LogoutComponent, 
  CallbackComponent,
  ProtectedRouteComponent
} from '@nebulr-group/nblocks-react';
import { Route, Routes } from "react-router-dom";

export default function AppRoutes() {
  return (
    <div>
      <Routes>
        <!-- Create new routes that renders the login, logout and callback components -->
        <Route path="/login" element={ <LoginComponent /> }/>
        <Route path="/logout" element={ <LogoutComponent /> }/>
        <Route path="/auth/oauth-callback" element={ <CallbackComponent /> }/>

        <!-- Wrap all the routes which you want to protect inside ProtectedRouteComponent  -->
        <Route path="*" element={
          <ProtectedRouteComponent>
            <Routes>
              ... These routes are protected from unauthenticated users...
            </Routes>
          </ProtectedRouteComponent>
          }
        />
      </Routes>
    </div>
  );
}
`;

  return appId ? (
    <>
      <HeadingComponent type="h2" size="xl">
        Create three new routes and secure the rest of the app
      </HeadingComponent>
      <TextComponent>
        <Trans
          i18nKey="userReadinessReactOnboardingLogin"
          defaults="Add the new routes <0>/login</0>, <1>/logout</1> and <2>/auth/oauth-callback</2> and import the components from the plugin that should render for each one of them. Add all other existing routes you wish to restrict and protect to logged in users as childs to <3>ProtectedRouteComponent</3>.<br/><br/>
          Here's an example setting up routes in react with <4>react-router</4>."
          components={[
            <InlineCode>/login</InlineCode>,
            <InlineCode>/logout</InlineCode>,
            <InlineCode>/auth/oauth-callback</InlineCode>,
            <InlineCode>ProtectedRouteComponent</InlineCode>,
            <InlineCode>react-router</InlineCode>,
          ]}
        />
      </TextComponent>
      <CodeBlock code={reactRouterCode} language="typescript" />
    </>
  ) : (
    <></>
  );
};

const UserReadinessReactPluginSteps: OnboardingStepType[] = [
  {
    label: 'Install plugin',
    component: InstallStep,
    nextButtonLabel: 'Next',
  },
  {
    label: 'Add routes and secure app',
    component: RoutesStep,
    nextButtonLabel: 'Test',
  },
  {
    label: 'Test',
    component: TestStep,
    nextButtonLabel: 'See your new user!',
    onboardingMutationVariables: { userReadiness: { test: true } },
  },
];

export { UserReadinessReactPluginSteps };
