import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Item } from 'src/screens/app/app.screen';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function AppSidebarComponent({
  menuItems,
  children,
}: {
  menuItems: Item[];
  children: React.ReactElement;
}) {
  const location = useLocation();
  const paths = location.pathname.split('/');

  const isActiveItem = (item: Item) => {
    return item.active ? paths.includes(item.active) : false;
  };

  return (
    <>
      <div className="flex">
        {/* Static sidebar for desktop */}
        <div className="max-sm:hidden lg:inset-y-0 lg:flex lg:w-72 lg:flex-col py-5">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6">
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {menuItems.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            isActiveItem(item)
                              ? 'bg-gray-50 text-gray-900'
                              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          )}
                        >
                          <div
                            className={'text-gray-400 h-6 w-6 shrink-0'}
                            aria-hidden="true"
                          >
                            {item.icon}
                          </div>
                          <div className="flex items-center">{item.name}</div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <main className="py-5 lg:pl-8 lg:pr-16 w-full">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </>
  );
}
