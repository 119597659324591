import React, { useEffect, useRef } from 'react';

import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import typescript from 'highlight.js/lib/languages/typescript';
import xml from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('java', java);
hljs.registerLanguage('typescript', typescript);
hljs.registerLanguage('xml', xml);

import 'highlight.js/styles/monokai-sublime.css';

import { CopyChipComponent } from 'src/components/access-role/copy.button.component';

const CodeBlock = ({
  code,
  language,
  copyBtn = true
}: {
  code: string;
  language: 'typescript' | 'java' | 'xml';
  copyBtn?: boolean
}) => {
  const codeRef = useRef(null);

  useEffect(() => {
    if (codeRef.current) {
      hljs.highlightElement(codeRef.current);
    }
  }, [code]);

  return (
    <div className="relative">
      <pre>
        <code
          ref={codeRef}
          className={`text-sm rounded-lg language-${language}`}
        >
          {code}
        </code>
      </pre>
      {copyBtn && (
        <div className="absolute top-4 right-4">
          <CopyChipComponent label={'Copy'} value={code} />
        </div>
      )}
    </div>
  );
};

export { CodeBlock };
