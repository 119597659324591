import React from 'react';
import {
  OnboardingModalComponent,
  OnboardingTabs,
} from '../onboarding.modal.component';
import { UserReadinessReactVanillaSteps } from './user-readiness-react-vanilla.component';
import { UserReadinessJavaSteps } from './user-readiness-java.component';
import { UserReadinessNextSteps } from './user-readiness-next.component';
import { UserReadinessAngularSteps } from './user-readiness-angular.component';
import { UserReadinessReactPluginSteps } from './user-readiness-react-plugin.component';
import { ReactIcon } from 'src/components/shared/tabs/icons/react-icon';
import { NextJsIcon } from 'src/components/shared/tabs/icons/next-icon';
import { AngularIcon } from 'src/components/shared/tabs/icons/angular-icon';
import { JavaIcon } from 'src/components/shared/tabs/icons/java-icon';

interface ComponentProps {
  didFinishOnboarding: () => void;
  pendingProps: {
    isTestPending: boolean;
    pendingLabel: string;
  };
}

const UserReadinessModalComponent = ({
  didFinishOnboarding,
  pendingProps,
}: ComponentProps) => {
  const tabs: OnboardingTabs = {
    react: {
      title: 'React',
      steps: UserReadinessReactPluginSteps,
      icon: ReactIcon,
    },
    reactVanilla: {
      title: 'React (vanilla)',
      steps: UserReadinessReactVanillaSteps,
      icon: ReactIcon,
    },
    next: {
      title: 'NextJS',
      steps: UserReadinessNextSteps,
      icon: NextJsIcon,
    },
    angular: {
      title: 'Angular',
      steps: UserReadinessAngularSteps,
      icon: AngularIcon,
    },
    java: {
      title: 'Java',
      steps: UserReadinessJavaSteps,
      icon: JavaIcon
    },
  };

  return (
    <OnboardingModalComponent
      didFinishOnboarding={didFinishOnboarding}
      tabs={tabs}
      pendingProps={pendingProps}
    />
  );
};

export { UserReadinessModalComponent };
