import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { NBAuthGuard } from '@nebulr-group/nblocks-react';
import { HomeScreen } from './screens/home.screen';
import { AppScreen } from './screens/app/app.screen';
import { AppKeysScreen } from './screens/app/app-keys.screen';
import { AppFeatureFlagsScreen } from './screens/app/app-feature-flags.screen';
import { AuthRoutes } from './AuthRoutes';
import { CreateAppScreen } from './screens/app/create-app.screen';
import { AppTenantsScreen } from './screens/app/app-tenants.screen';
import { TeamComponent } from './components/team/team.component';
import { AppTenantScreen } from './screens/app/app-tenant.screen';
import { AppTenantUserScreen } from './screens/app/app-tenant-user.screen';
import { AppPaymentsScreen } from './screens/app/app-payments.screen';
import { AppAccessRoleScreen } from './screens/app/app-access-role.screen';
import { RolesComponent } from './components/access-role/roles.component';
import { PrivilegesComponent } from './components/access-role/privileges.component';
import { AppAuthenticationScreen } from './screens/app/app-authentication.screen';
import { LoginSettingsComponent } from './components/authentication/login.component';
import { SecurityComponent } from './components/authentication/security.component';
import { SignupSettingsComponent } from './components/authentication/signup.component';
import { AppCustomizationScreen } from './screens/app/app-customization.screen';
import { BrandingCustomizationComponent } from './components/customization/branding.component';
import { PropertiesCustomizationComponent } from './components/customization/properties.component';
import { EmailCustomizationComponent } from './components/customization/email.component';
import { EnterpriseConnectionsComponent } from './components/authentication/enterprise.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { AppAdvancedScreen } from './screens/app/app-advanced.screen';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route
        path="*"
        element={
          <NBAuthGuard>
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/create-app" element={<CreateAppScreen />} />
              <Route path="/team" element={<TeamComponent />} />
              <Route path="/subscription" element={<SubscriptionComponent />} />
              <Route path="/app/:appId/*" element={<AppScreen />}>
                <Route path="tenants" element={<AppTenantsScreen />}></Route>
                <Route
                  path="tenants/:tenantId"
                  element={<AppTenantScreen />}
                ></Route>
                <Route
                  path="tenants/:tenantId/users/:tenantUserId"
                  element={<AppTenantUserScreen />}
                ></Route>
                <Route path="flags" element={<AppFeatureFlagsScreen />}></Route>
                <Route path="payments" element={<AppPaymentsScreen />}></Route>
                <Route path="access" element={<AppAccessRoleScreen />}>
                  <Route path="roles" element={<RolesComponent />} />
                  <Route path="privileges" element={<PrivilegesComponent />} />
                  <Route path="*" element={<Navigate to="roles" replace />} />
                </Route>
                <Route
                  path="authentication"
                  element={<AppAuthenticationScreen />}
                >
                  <Route path="login" element={<LoginSettingsComponent />} />
                  <Route path="signup" element={<SignupSettingsComponent />} />
                  <Route
                    path="enterprise"
                    element={<EnterpriseConnectionsComponent />}
                  />
                  <Route path="security" element={<SecurityComponent />} />
                  <Route path="*" element={<Navigate to="login" replace />} />
                </Route>
                <Route
                  path="customization"
                  element={<AppCustomizationScreen />}
                >
                  <Route
                    path="properties"
                    element={<PropertiesCustomizationComponent />}
                  />
                  <Route
                    path="branding"
                    element={<BrandingCustomizationComponent />}
                  />
                  <Route
                    path="email"
                    element={<EmailCustomizationComponent />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to="properties" replace />}
                  />
                </Route>
                <Route path="keys" element={<AppKeysScreen />}></Route>
                <Route path="advanced" element={<AppAdvancedScreen />}></Route>
                <Route
                  path="*"
                  element={<Navigate to="tenants" replace={true} />}
                />
              </Route>
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          </NBAuthGuard>
        }
      />
    </Routes>
  );
}

export { AppRoutes };
