import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { useTranslation } from 'react-i18next';
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';

import {
  BrandingInput,
  DeleteBrandingDocument,
  GetAppPropertiesDocument,
  GetBrandingDocument,
  UpdateBrandingDocument,
} from 'src/gql/graphql';
import { useCurrentApp } from 'src/shared/hooks/use-current-app';
import { SubHeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/SubHeadingComponent';
import { BrandingFieldsComponent } from './branding/branding-fields.component';
import { BrandingPreviewComponent } from './branding/branding-preview.component';
import { NblocksButton } from '@nebulr-group/nblocks-react/lib/components/shared/NblocksButton';
import { BrandingCustomComponent } from './branding/branding-custom.component';
import { ModalComponent } from '@nebulr-group/nblocks-react/lib/components/shared/ModalComponent';
import { ApolloUtils } from 'src/shared/apollo-utils';
import { ViewLinksComponent } from './branding/view-links.component';
import { useButtonSuccess } from 'src/shared/hooks/use-button-success';
import { SkeletonLoader } from '@nebulr-group/nblocks-react/lib/components/shared/SkeletonLoader';

const BrandingCustomizationComponent = () => {
  const { t } = useTranslation();
  const { appId } = useCurrentApp();

  const [branding, setBranding] = useState<BrandingInput | undefined>();
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const { buttonSuccess, triggerButtonSuccess } = useButtonSuccess();

  const { data: queryBrandingData, loading: queryBrandingDataLoading } =
    useQuery(GetBrandingDocument, {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { appId: appId! },
      skip: !appId,
    });

  const {
    data: queryAppPropertiesData,
    loading: queryAppPropertiesDataLoading,
  } = useQuery(GetAppPropertiesDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { appId: appId! },
    skip: !appId,
  });

  const [updateBranding, updateBrandingData] = useMutation(
    UpdateBrandingDocument,
  );
  const [deleteBranding] = useMutation(DeleteBrandingDocument, {
    refetchQueries: [
      {
        query: GetBrandingDocument,
        variables: { appId },
      },
    ],
    onCompleted: (data) => {
      const brandingData = ApolloUtils.removeTypeName<BrandingInput>(
        data.deleteBranding,
      );
      setBranding({ ...brandingData });
    },
  });

  const didClickBrandingFieldsSave = async () => {
    if (appId && branding) {
      updateBranding({
        variables: {
          appId,
          branding,
        },
      });
      triggerButtonSuccess();
    }
  };

  useEffect(() => {
    if (queryBrandingData) {
      // "Deleting" typename and defaultValues from the object
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, defaultValues, ...brandingData } =
        queryBrandingData.getBranding;
      setBranding({ ...brandingData });
    }
  }, [queryBrandingData]);

  const activateCustomMode = (appId: string, branding: BrandingInput) => {
    updateBranding({
      variables: {
        appId,
        branding: {
          ...branding,
          customCss: true,
        },
      },
      refetchQueries: [
        {
          query: GetBrandingDocument,
          variables: { appId },
        },
      ],
    });
  };

  const loading = queryAppPropertiesDataLoading || queryBrandingDataLoading;

  return (
    <>
      {loading && (
        <div>
          <div className="my-6 flex justify-between space-x-6">
            <div className="flex-1">
              <SkeletonLoader className="h-96 w-full rounded-md" />
            </div>
            <div className="flex-1">
              <SkeletonLoader className="h-96 w-full rounded-md" />
            </div>
          </div>
        </div>
      )}
      {appId && branding && (
        <div>
          <div className="my-6 flex justify-between">
            <div className="flex flex-col">
              <SubHeadingComponent type={'primary'} size={'2xl'}>
                {!branding.customCss ? t('Brand designer') : t('Custom design')}
              </SubHeadingComponent>
              <TextComponent size={'base'} colorName="text-gray-500">
                {!branding.customCss
                  ? t('Design the user-facing views using the fields below')
                  : t('Add your custom css code in the field below')}
              </TextComponent>
              <ViewLinksComponent appId={appId} />
            </div>
            <div className="h-12 flex flex-row space-x-4">
              {!branding.customCss && (
                <NblocksButton
                  type="tertiary"
                  size="sm"
                  onClick={() => setIsCustomModalOpen(true)}
                >
                  {t('Custom mode')}
                </NblocksButton>
              )}
              <NblocksButton
                onClick={() => setIsResetModalOpen(true)}
                type="danger"
                size="sm"
              >
                {t('Reset to default')}
              </NblocksButton>
            </div>
          </div>
          <div className="space-y-6">
            <div className="rounded-lg border border-gray-300 bg-white shadow p-6 flex flex-row">
              {branding.customCss ? (
                <BrandingCustomComponent appId={appId} />
              ) : (
                <>
                  <BrandingFieldsComponent
                    branding={branding}
                    setBranding={setBranding}
                    saveLoading={updateBrandingData.loading}
                    saveSuccess={buttonSuccess}
                    onSave={() => {
                      didClickBrandingFieldsSave();
                    }}
                  />
                  {queryBrandingData && queryAppPropertiesData && (
                    <BrandingPreviewComponent
                      branding={branding}
                      defaultValues={
                        queryBrandingData.getBranding.defaultValues
                      }
                      logoURL={queryAppPropertiesData.getApp.logo ?? ''}
                      privacyPolicyUrl={
                        queryAppPropertiesData.getApp.privacyPolicyUrl ?? ''
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <ModalComponent
            isOpen={isCustomModalOpen}
            setIsOpen={setIsCustomModalOpen}
            heading={t('Are you sure?')}
            description={t(
              'Are you sure you want to upload a custom CSS file? This will override the current branding settings and cannot be undone.',
            )}
            icon={<ExclamationTriangleIcon />}
            iconType="warning"
          >
            <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
              <NblocksButton
                size="md"
                className="w-full"
                type="tertiary"
                onClick={() => setIsCustomModalOpen(false)}
              >
                {t('Cancel')}
              </NblocksButton>
              <NblocksButton
                size="md"
                className="w-full"
                type="primary"
                onClick={() => {
                  activateCustomMode(appId, branding);
                  setIsCustomModalOpen(false);
                }}
              >
                {t('Confirm')}
              </NblocksButton>
            </div>
          </ModalComponent>
          <ModalComponent
            isOpen={isResetModalOpen}
            setIsOpen={setIsResetModalOpen}
            heading={t('Are you sure?')}
            description={t(
              'Are you sure you want to reset the branding to the default settings? This action will permanently erase your branding settings and cannot be reversed.',
            )}
            icon={<ExclamationCircleIcon />}
            iconType="danger"
          >
            <div className="flex flex-col-reverse md:flex-row md:justify-between mt-5 gap-3">
              <NblocksButton
                size="md"
                className="w-full"
                type="tertiary"
                onClick={() => setIsResetModalOpen(false)}
              >
                {t('Cancel')}
              </NblocksButton>
              <NblocksButton
                size="md"
                className="w-full"
                type="primary"
                onClick={() => {
                  deleteBranding({ variables: { appId } });
                  setIsResetModalOpen(false);
                }}
              >
                {t('Confirm')}
              </NblocksButton>
            </div>
          </ModalComponent>
        </div>
      )}
    </>
  );
};

export { BrandingCustomizationComponent };
