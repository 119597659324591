import React from 'react';
import { HeadingComponent } from '@nebulr-group/nblocks-react/lib/components/shared/HeadingComponent';
import { TextComponent } from '@nebulr-group/nblocks-react/lib/components/shared/TextComponent';
import { DateTimeComponent } from 'src/components/shared/date/date-time-component';
import { TenantBreadcrumpsComponent } from 'src/components/tenant/breadcrumps/tenant-breadcrumps-component';
import { useCurrentTenantUser } from 'src/shared/hooks/use-current-tenant-user';
import { SafeUserNameComponent } from 'src/components/tenant/user/safe-user-name/safe-user-name.component';

const AppTenantUserScreen = () => {
  const { tenantUser } = useCurrentTenantUser();
  return (
    <div className="space-y-6">
      <TenantBreadcrumpsComponent />
      <HeadingComponent type={'h1'} size={'2xl'} className={'mt-5'}>
        <SafeUserNameComponent name={tenantUser?.fullName} />
      </HeadingComponent>
      <div>
        <TextComponent size="sm">ID: {tenantUser?.id}</TextComponent>
        <TextComponent size="sm">
          Created: <DateTimeComponent value={tenantUser?.createdAt} />
        </TextComponent>
        <TextComponent size="sm">
          Status: {tenantUser?.enabled ? 'Active' : 'Disabled'}
        </TextComponent>
        <TextComponent size="sm">email: {tenantUser?.email}</TextComponent>
        <TextComponent size="sm">Role: {tenantUser?.role}</TextComponent>
      </div>
    </div>
  );
};

export { AppTenantUserScreen };
