import React, { FunctionComponent } from 'react';
import { NblocksPublicClient } from '@nebulr-group/nblocks-ts-client/dist/';
import { Stage } from '@nebulr-group/nblocks-ts-client/dist/platform/nblocks-client';
import { useConfig } from '@nebulr-group/nblocks-react/lib/hooks/config-context';
import { useLog } from '@nebulr-group/nblocks-react/lib/hooks/use-log';
import { Config } from '../Config';

const Context = React.createContext<
  | {
      nblocksClient: NblocksPublicClient;
    }
  | undefined
>(undefined);

const NblocksClientContextProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { appId, debug } = useConfig();
  const { log } = useLog();

  const nblocksClient = new NblocksPublicClient({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    appId: appId!,
    stage: Config.getEnvironment(
      process.env.REACT_APP_ENV,
    ).toUpperCase() as Stage,
    debug,
  });
  log('New NblocksPublicClient instanciated!');

  // log(`3. Rendering NblocksClientContextProvider`);

  return (
    <Context.Provider value={{ nblocksClient }}>{children}</Context.Provider>
  );
};

export { NblocksClientContextProvider, Context };
