import React, { useEffect, useState } from 'react';
import { InputComponent } from '@nebulr-group/nblocks-react/lib/components/shared/InputComponent';

export interface ComponentProps {
  onQueryChange: (query: string) => void;
}

const SearchComponent = ({ onQueryChange }: ComponentProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    onQueryChange(searchQuery.toLowerCase());
  }, [searchQuery]);

  return (
    <div>
      <InputComponent
        type="text"
        placeholder="Quick search"
        name="workspaceName"
        onChange={(event) => setSearchQuery(event.target.value)}
        className="text-sm"
      />
    </div>
  );
};

export { SearchComponent };
